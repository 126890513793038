import DisponibilitaCalendar from "../DisponibilitaCalendar";
import FeatherIcon from "../FeatherIcon";
import SearchAndSelect from "../SearchAndSelect";
import axios from "axios";
import { useState, useEffect } from "react";
import VediDisponibilitaProfessionista from "../VediDisponibilitaProfessionista";
import Legenda from "../Legenda";
import { useContext } from 'react';
import { ContextGestionale } from "../../App";
import SovrapposizioniDisponibilitaModal from "../modals/SovrapposizioniDisponibilitaModal";
import APICallButton from "../APICallButton";
import Heading from "../Heading";

function DisponibilitaProfessionista(props) {
	const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso } = useContext(ContextGestionale);
	const legendaColors = [
		{ bg: process.env.REACT_APP_SEDUTE_PROGRAMMATE_BGCOLOR, text: "Seduta settimanale programmata", txtCol: "white" },
		{ bg: process.env.REACT_APP_SEDUTA_PRENOTATA_PAGINA_DISPONIBILITA_BGCOLOR, text: "Seduta prenotata", txtCol: "white" },
		{ bg: process.env.REACT_APP_DISPONIBILITA_PROFESSIONISTA_BGCOLOR, text: "Disponibilità professionista", txtCol: "black" }
	];
	const [selectedProfessionisti, setSelectedProfessionisti] = useState([]);
	const [professionistaIsSelected, setProfessionistaIsSelected] = useState(false);
	const [listaDisponibilita, setListaDisponibilita] = useState([]);
	const [overlaps, setOverlaps] = useState([]);
	const [disponibilitaOverlap, setDisponibilitaOverlap] = useState(false);
	const [sovrapposizioniDisponibilita, setSovrapposizioniDisponibilita] = useState([]);
	const [isShowSovrapposizioniDisponibilitaModal, setShowSovrapposizioniDisponibilitaModal] = useState(false);
	//è true se tutti gli orari di inizio sono "minori" degli orari di fine
	const [orariDispTuttiValidi, setOrariDispTuttiValidi] = useState(true);
	//giorni mostrati tra le disponibilità
	const giorni = ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];
	//corrispondenza tra giorno nel db e nome del giorno della settimana
	const nomeGiorno = [null, "Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];

	const rooms = process.env.REACT_APP_ROOMS.split(";");

	function showSovrapposizioniDisponibilitaModal() {
		setShowSovrapposizioniDisponibilitaModal(true);
	}
	function closeSovrapposizioniDisponibilitaModal() {
		setShowSovrapposizioniDisponibilitaModal(false);
	}

	//quando viene selezionato un professionista, carica tutte le sue disponibilità

	//all'apertura della pagina delle disponibilita, carica la lista dei professionisti
	useEffect(() => {
		if (pageViewed === pages.DisponibilitaProfessionista) {
			if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
				console.log("useEffect: pagina disponibilità professionista aperta");
			}
			//carica la lista dei professionisti
			if (loggedUser.tipoUtente === "segretario") {
				setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/professionistapaz/tutti?ordineAlfabetico=true&tipoUtente=professionista&soloAttivi=true")
					.then(response => {
						let professionisti = response.data.dbdata;
						for (let i = 0; i < professionisti.length; i++) {
							professionisti[i].viewed = true;
							professionisti[i].id = professionisti[i].idUtente;
						}
						let tutti = {
							id: 0,
							viewed: true,
						}
						professionisti.unshift(tutti);
						setListaProfessionisti(professionisti);
						setSelectedProfessionisti([]);
					})
					.catch(error => {
						handleAPIError(error, "visualizzare i professionisti");
					})
					.finally(() => {
						setLoading(false);
					});
			} else if (loggedUser.tipoUtente === "professionista") {
				setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/professionista?idProfessionista=" + loggedUser.user)
					.then(response => {
						let professionisti = response.data.dbdata;
						for (let i = 0; i < professionisti.length; i++) {
							professionisti[i].viewed = true;
							professionisti[i].id = professionisti[i].idUtente;
						}
						setListaProfessionisti(professionisti);
						setSelectedProfessionisti([loggedUser.user]);
					})
					.catch(error => {
						handleAPIError(error, "visualizzare i professionisti");
					})
					.finally(() => {
						setLoading(false);
					});
			}
		}
	}, [pageViewed]);

	//come mostrare i professionisti nella select
	function getProfessionistaText(element) {
		if (element.id !== 0) {
			return element.cognome + " " + element.nome;
		} else {
			return "Tutti i professionisti";
		}
	}

	//quando viene caricata o ricaricata la lista delle disponiblità, rifletti le modifiche sulla tabella
	useEffect(() => {
		if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
			console.log("useEffect: mostro disponibilità professionista nella tabella");
		}
		let listaDispPerTabellaTemp = [];
		for (let i = 0; i < listaDisponibilita.length; i++) {
			listaDispPerTabellaTemp[i] = {
				id: i,
				giorno: listaDisponibilita[i].giorno,
				oraInizio: listaDisponibilita[i].oraInizio.substring(0, 5),
				oraFine: listaDisponibilita[i].oraFine.substring(0, 5),
				overlap: false,
				idArea: listaDisponibilita[i].idArea !== null ? listaDisponibilita[i].idArea : 0
			}
		}
		//ordinaGiorni(listaDispPerTabellaTemp);
		props.setListaDispPerTabella(listaDispPerTabellaTemp);
		props.setListaDispPerTabellaBeforeEdits(JSON.parse(JSON.stringify(listaDispPerTabellaTemp)));
		setDisponibilitaOverlap(false);
	}, [listaDisponibilita]);

	//quando vengono modificate le disponibilità nella tabella, controlla se ci sono sovrapposizioni e indica quali
	useEffect(() => {
		if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
			console.log("useEffect: controllo sovrapposizioni nella tabella disponibilità");
		}
		let overlapsTemp = new Array(props.listaDispPerTabella.length).fill(false);
		//props.listaDispPerTabella: id, giorno, oraInizio, oraFine, overlap, idArea
		setDisponibilitaOverlap(false);
		for (let i = 0; i < props.listaDispPerTabella.length; i++) {
			for (let j = 0; j < props.listaDispPerTabella.length; j++) {
				if (i !== j) {
					if (props.listaDispPerTabella[i].giorno === props.listaDispPerTabella[j].giorno && !(props.listaDispPerTabella[i].oraInizio >= props.listaDispPerTabella[j].oraFine || props.listaDispPerTabella[i].oraFine <= props.listaDispPerTabella[j].oraInizio)) {
						overlapsTemp[i] = true;
						overlapsTemp[j] = true;
						setDisponibilitaOverlap(true);
					}
				}
			}
		}
		setOverlaps(overlapsTemp);
		setOrariDispTuttiValidi(true);
		for (let i = 0; i < props.listaDispPerTabella.length; i++) {
			if (props.listaDispPerTabella[i].oraInizio >= props.listaDispPerTabella[i].oraFine) {
				setOrariDispTuttiValidi(false);
			}
		}
	}, [props.listaDispPerTabella]);

	//gestisci la selezione del giorno sulla select
	const handleGiornoChange = (event) => {
		const { name, value } = event.target;
		let dispId = parseInt(name.substring(0, name.length - 2));
		let listaDispPerTabellaTemp = props.listaDispPerTabella.slice();
		for (let i = 0; i < listaDispPerTabellaTemp.length; i++) {
			if (listaDispPerTabellaTemp[i].id === dispId) {
				listaDispPerTabellaTemp[i].giorno = parseInt(value);
				break;
			}
		}
		//ordinaGiorni(listaDispPerTabellaTemp);
		props.setListaDispPerTabella(listaDispPerTabellaTemp);
	}
	//prende la lista disp per tabella e ordina i giorni dal primo all'ultimo
	function ordinaGiorni(listaDispPerTabellaTemp) {
		for (let i = 0; i < listaDispPerTabellaTemp.length - 1; i++) {
			for (let j = 0; j < listaDispPerTabellaTemp.length - 1 - i; j++) {
				let giornoA = giornoShift(listaDispPerTabellaTemp[j].giorno);
				let giornoB = giornoShift(listaDispPerTabellaTemp[j + 1].giorno);
				if (giornoB < giornoA) {
					//Scambia le due disponibilità
					let dispA = JSON.parse(JSON.stringify(listaDispPerTabellaTemp[j]));
					let dispB = JSON.parse(JSON.stringify(listaDispPerTabellaTemp[j + 1]));
					listaDispPerTabellaTemp[j] = JSON.parse(JSON.stringify(dispB));
					listaDispPerTabellaTemp[j + 1] = JSON.parse(JSON.stringify(dispA));
				}
			}
		}
	}
	//Restituisce 1 per il lunedì, 2 per il martedì fino a 7 per la domenica (converte il giorno dal formato MySQL)
	function giornoShift(giorno) {
		if (giorno > 1) {
			return giorno - 1;
		} else if (giorno === 1) {
			return 7;
		} else {
			return -1;
		}

	}

	//gestisci il cambio dell'orario sulla select
	const handleOrarioChange = (event) => {
		const { name, value } = event.target;
		let dispId = parseInt(name.substring(0, name.length - 2));
		let inizioFine = name.substring(name.length - 2, name.length);
		let listaDispPerTabellaTemp = props.listaDispPerTabella.slice();
		if (inizioFine === "-i") {
			for (let i = 0; i < listaDispPerTabellaTemp.length; i++) {
				if (listaDispPerTabellaTemp[i].id === dispId) {
					listaDispPerTabellaTemp[i].oraInizio = value;
					break;
				}
			}
		} else if (inizioFine === "-f") {
			for (let i = 0; i < listaDispPerTabellaTemp.length; i++) {
				if (listaDispPerTabellaTemp[i].id === dispId) {
					listaDispPerTabellaTemp[i].oraFine = value;
					break;
				}
			}
		}
		props.setListaDispPerTabella(listaDispPerTabellaTemp);
	}

	//gestisci il cambio di area
	const handleAreaChange = (event) => {
		const { name, value } = event.target;
		let dispId = parseInt(name.split("-")[0]);
		let listaDispPerTabellaTemp = props.listaDispPerTabella.slice();
		for (let i = 0; i < listaDispPerTabellaTemp.length; i++) {
			if (listaDispPerTabellaTemp[i].id === dispId) {
				listaDispPerTabellaTemp[i].idArea = value;
				break;
			}
		}

		props.setListaDispPerTabella(listaDispPerTabellaTemp);
	}

	//fai la chiamata API che aggiorna le disponibilità per quello professionista
	function saveDisponibilita() {
		if (!disponibilitaOverlap && orariDispTuttiValidi) {
			const reqBody = {
				idProfessionista: selectedProfessionisti[0],
				disponibilita: props.listaDispPerTabella,
				rooms: rooms
			};
			setLoading(true); nodeReq.post(process.env.REACT_APP_API_URL + "/disponibilitaprofessionista", reqBody)
				.then(response => {
					if (response.status === 200) {
						if (response.data[0] == true) {
							genericAlert("Le disponibilità del professionista sono state aggiornate.");
						} else {
							genericAlert("Le disponibilità del professionista sono state aggiornate, ma si è verificato un errore nell'invio dell'email di notifica.");
						}
						//trucchetto per ricaricare il calendario: deselezionare il professionista e riselezionarlo
						let selectedProfessionistiTemp = selectedProfessionisti.slice();
						setSelectedProfessionisti([]);
						setSelectedProfessionisti(selectedProfessionistiTemp);
						//mostra il messaggio
						props.setListaDispPerTabellaBeforeEdits(JSON.parse(JSON.stringify(props.listaDispPerTabella)));
					} else {
						genericAlert("Impossibile aggiornare le disponibilità al momento. Probabilmente non hai il permesso necessario per farlo.");
					}
				})
				.catch(error => {
					if (error.response !== undefined && error.response.status === 400) {
						//genericAlert("Ci sono delle disponibilita in sovrapposizione. Ora ti dico quali");

						//TODO: dire quali disponibilita sono in sovrapposizione!
						console.log("Ecco le disponibilita in sovrapposizione");
						console.log(error.response.data);
						setSovrapposizioniDisponibilita(error.response.data);
						showSovrapposizioniDisponibilitaModal();
					} else {
						handleAPIError(error, "aggiornare le disponibilità");
					}
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}

	function deleteDisponibilita(id) {
		let listaDispPerTabellaTemp = [];
		let insId = 0;
		for (let i = 0; i < props.listaDispPerTabella.length; i++) {
			if (props.listaDispPerTabella[i].id !== id) {
				let disp = JSON.parse(JSON.stringify(props.listaDispPerTabella[i]));
				disp.id = insId;
				listaDispPerTabellaTemp.push(disp);
				insId++;
			}
		}

		props.setListaDispPerTabella(listaDispPerTabellaTemp);
	}

	//aggiunge una nuova disponibilita. Di default, la inserisce il lunedi dalle 8 alle 21
	function addDisponibilita() {
		let listaDispPerTabellaTemp = props.listaDispPerTabella.slice();
		let newDisp = {
			id: listaDispPerTabellaTemp.length,
			giorno: 2,
			oraInizio: "08:00",
			oraFine: "21:00",
			overlap: false,
			idArea: 0
		};
		listaDispPerTabellaTemp.push(newDisp);

		props.setListaDispPerTabella(listaDispPerTabellaTemp);
	}

	return (
		<>
			<div style={{ display: pageViewed === pages.DisponibilitaProfessionista ? 'block' : 'none' }}>
				<Heading title={loggedUser.tipoUtente === "professionista" ? "Le mie disponibilità" : "Disponibilità professionista"} iconName="user-check" />
				<div className="row">
					{loggedUser.tipoUtente !== "professionista" &&
						<div className="col-md">
							<div className="card">
								<div className="card-header">
									<h5 className="card-title mb-0">Gestione disponibilità dei professionisti</h5>
								</div>
								<div className="card-body">
									<SearchAndSelect title="Professionista" oneElementName="un professionista" selfEmptyOnElementsUpdate={!(loggedUser.tipoUtente === "professionista")} elements={listaProfessionisti} allowMultiple={false} showDeselectButton={false} required={true} validSelection={professionistaIsSelected} setValidSelection={setProfessionistaIsSelected} selectedOptions={selectedProfessionisti} setSelectedOptions={setSelectedProfessionisti} checkUnsavedBeforeSelecting={checkUnsavedBeforeDoing} getElementText={getProfessionistaText} />
								</div>
							</div>
						</div>
					}

					{selectedProfessionisti.length === 1 && selectedProfessionisti[0] !== 0 && selectedProfessionisti[0] !== "0" &&
						<>
							<div className="col-md">
								<div className="card">
									<div className="card-header">
										<h5 className="card-title">Disponibilità</h5>
									</div>
									<div className="card-body">
										<div style={{ overflowY: "scroll" }}>
											<table>
												{props.listaDispPerTabella.map((disp, index) => {
													return (
														<tr key={index} style={{ backgroundColor: (overlaps[index] ? "rgb(255, 200, 200)" : process.env.REACT_APP_DISPONIBILITA_PROFESSIONISTA_BGCOLOR) }} className="disp-border">
															<td className="p-2">
																Giorno
																<select className="form-control" value={disp.giorno} name={disp.id + "-d"} onChange={handleGiornoChange}>
																	{giorni.map((giorno, index) => (
																		<option key={index} value={nomeGiorno.indexOf(giorno)}>{giorno}</option>
																	))}
																</select>
															</td>
															<td className="p-2">
																Dalle <input type="time" className={"form-control " + (disp.oraInizio >= disp.oraFine ? "is-invalid" : "")} name={disp.id + "-i"} value={disp.oraInizio} onChange={handleOrarioChange} />
															</td>
															<td className="p-2">
																Alle <input type="time" className={"form-control " + (disp.oraInizio >= disp.oraFine ? "is-invalid" : "")} name={disp.id + "-f"} value={disp.oraFine} onChange={handleOrarioChange} />
															</td>
															<td className="p-2">
																Area
																<select className="form-control" value={disp.idArea} name={disp.id + "-area"} onChange={handleAreaChange}>
																	<option value={0}>Non specificata</option>
																	{rooms.map((room, index) => (
																		<option key={index} value={(index + 1)}>{room}</option>
																	))}
																</select>

															</td>
															<td className="p-2">
																&nbsp;
																<button className="btn btn-danger form-control" onClick={() => { deleteDisponibilita(disp.id); }}><FeatherIcon addedClassName="text-light" iconName="trash" /></button>
															</td>
														</tr>);
												})}
												{props.listaDispPerTabella.length === 0 && <p class="text-danger">Nessuna disponibilità inserita per questo professionista</p>}
											</table>
										</div>
										<p className="text-danger">{disponibilitaOverlap ? "Le disponibilità evidenziate in rosso sono in sovrapposizione" : ""}</p>
										<p className="text-danger">{!orariDispTuttiValidi ? "Inserire orari validi" : ""}</p>
										<APICallButton buttonText="Salva" buttonClassName="btn btn-primary m-1" buttonDisabled={disponibilitaOverlap || !orariDispTuttiValidi} buttonOnClick={saveDisponibilita} nomeProcedura={"l'aggiornamento delle disponibilità del professionista"}/>
										<button className="btn btn-light m-1" onClick={addDisponibilita}>Aggiungi</button>
									</div>
								</div>
							</div>
						</>
					}
				</div>
				{selectedProfessionisti.length >= 1 &&
					<div className="row mainElementHeightContainer d-flex flex-column" style={{ backgroundColor: "white", padding: "8px", borderRadius: "0.25rem", boxShadow: "0 0 .875rem 0 rgba(33, 37, 41, .05)", marginLeft: "0px", marginRight: "0px" }}>
						<Legenda colors={legendaColors} />
						{pageViewed === pages.DisponibilitaProfessionista &&
							<VediDisponibilitaProfessionista selectedProfessionisti={selectedProfessionisti} listaDisponibilita={listaDisponibilita} setListaDisponibilita={setListaDisponibilita} openedFromDisponibilitaProfessionista={true} />
						}
					</div>
				}
				<SovrapposizioniDisponibilitaModal showmodal={isShowSovrapposizioniDisponibilitaModal} closemodal={closeSovrapposizioniDisponibilitaModal} sovrapposizioni={sovrapposizioniDisponibilita} rooms={rooms} />
			</div>
		</>
	);
}

export default DisponibilitaProfessionista;