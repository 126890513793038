import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { ContextGestionale } from '../../App';
import { useContext } from 'react';
import APICallButton from "../APICallButton";

function ModalPrompt(props) {
    //props richieste: showmodal, closemodal, okButtonFunction, children, title, okButtonColor, okButtonText
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    return (
        <div>
            <Modal show={props.showmodal} size={props.modalSize == undefined ? '' : props.modalSize}>
                <Modal.Header closeButton onClick={props.closemodal}>
                    <Modal.Title><h3 className="card-title">{props.title}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
                <Modal.Footer>
                    <APICallButton buttonClassName={"btn btn-"+props.okButtonColor+" m-1"} buttonText={props.okButtonText} buttonOnClick={props.okButtonFunction} buttonDisabled={props.okButtonDisabled == true} nomeProcedura={props.nomeProcedura}/>
                    <Button variant="secondary" onClick={props.closemodal}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
export default ModalPrompt;