import LogoutButton from "./LogoutButton";
import FeatherIcon from "./FeatherIcon";
import { ContextGestionale } from '../App';
import { useContext } from 'react';
import Loader from "./Loader";

function Navbar(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    return (<nav id="navBarEsagramma" className="navbar navbar-expand navbar-light navbar-bg" style={{ display: pageViewed === pages.Login || pageViewed === pages.ImpostazionePassword ? 'none' : 'flex' }}>
        <a className="sidebar-toggle js-sidebar-toggle">
            <i className="hamburger align-self-center" ></i>
        </a>
        <div style={{ height: "100%" }} className="d-flex align-items-center justify-content-between flex-column">
            <div>
                {isProcessing && <Loader/>}
            </div>
        </div>
        <div className="navbar-collapse collapse">
            <ul className="navbar-nav navbar-align">
                <li className="nav-item dropdown">
                    {/*
                <a className="nav-icon dropdown-toggle" href="#" id="alertsDropdown" data-bs-toggle="dropdown">
                    <div className="position-relative">
                        <FeatherIcon addedClassName="align-middle" iconName="bell"/>
                        <span className="indicator">4</span>
                    </div>
                </a>
                    */}
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0" aria-labelledby="alertsDropdown">
                        <div className="dropdown-menu-header">
                            4 New Notifications
                        </div>
                        <div className="list-group">
                            <a href="#" className="list-group-item">
                                <div className="row g-0 align-items-center">
                                    <div className="col-2">
                                        <FeatherIcon addedClassName="text-danger" iconName="alert-circle" />
                                    </div>
                                    <div className="col-10">
                                        <div className="text-dark">Update completed</div>
                                        <div className="text-muted small mt-1">Restart server 12 to complete the update.</div>
                                        <div className="text-muted small mt-1">30m ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" className="list-group-item">
                                <div className="row g-0 align-items-center">
                                    <div className="col-2">
                                        <FeatherIcon addedClassName="text-warning" iconName="bell" />
                                    </div>
                                    <div className="col-10">
                                        <div className="text-dark">Lorem ipsum</div>
                                        <div className="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate hendrerit et.</div>
                                        <div className="text-muted small mt-1">2h ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" className="list-group-item">
                                <div className="row g-0 align-items-center">
                                    <div className="col-2">
                                        <FeatherIcon addedClassName="text-primary" iconName="home" />
                                    </div>
                                    <div className="col-10">
                                        <div className="text-dark">Login from 192.186.1.8</div>
                                        <div className="text-muted small mt-1">5h ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" className="list-group-item">
                                <div className="row g-0 align-items-center">
                                    <div className="col-2">
                                        <FeatherIcon addedClassName="text-success" iconName="user-plus" />
                                    </div>
                                    <div className="col-10">
                                        <div className="text-dark">New connection</div>
                                        <div className="text-muted small mt-1">Christina accepted your request.</div>
                                        <div className="text-muted small mt-1">14h ago</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="dropdown-menu-footer">
                            <a href="#" className="text-muted">Show all notifications</a>
                        </div>
                    </div>
                </li>
                <li className="nav-item dropdown">
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0" aria-labelledby="messagesDropdown">
                        <div className="dropdown-menu-header">
                            <div className="position-relative">
                                4 New Messages
                            </div>
                        </div>
                        <div className="list-group">
                            <a href="#" className="list-group-item">
                                <div className="row g-0 align-items-center">
                                    <div className="col-2">
                                        <img src="adminkit/img/avatars/avatar-5.jpg" className="avatar img-fluid rounded-circle" alt="Vanessa Tucker" />
                                    </div>
                                    <div className="col-10 ps-2">
                                        <div className="text-dark">Vanessa Tucker</div>
                                        <div className="text-muted small mt-1">Nam pretium turpis et arcu. Duis arcu tortor.</div>
                                        <div className="text-muted small mt-1">15m ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" className="list-group-item">
                                <div className="row g-0 align-items-center">
                                    <div className="col-2">
                                        <img src="adminkit/img/avatars/avatar-2.jpg" className="avatar img-fluid rounded-circle" alt="William Harris" />
                                    </div>
                                    <div className="col-10 ps-2">
                                        <div className="text-dark">William Harris</div>
                                        <div className="text-muted small mt-1">Curabitur ligula sapien euismod vitae.</div>
                                        <div className="text-muted small mt-1">2h ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" className="list-group-item">
                                <div className="row g-0 align-items-center">
                                    <div className="col-2">
                                        <img src="adminkit/img/avatars/avatar-4.jpg" className="avatar img-fluid rounded-circle" alt="Christina Mason" />
                                    </div>
                                    <div className="col-10 ps-2">
                                        <div className="text-dark">Christina Mason</div>
                                        <div className="text-muted small mt-1">Pellentesque auctor neque nec urna.</div>
                                        <div className="text-muted small mt-1">4h ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" className="list-group-item">
                                <div className="row g-0 align-items-center">
                                    <div className="col-2">
                                        <img src="adminkit/img/avatars/avatar-3.jpg" className="avatar img-fluid rounded-circle" alt="Sharon Lessman" />
                                    </div>
                                    <div className="col-10 ps-2">
                                        <div className="text-dark">Sharon Lessman</div>
                                        <div className="text-muted small mt-1">Aenean tellus metus, bibendum sed, posuere ac, mattis non.</div>
                                        <div className="text-muted small mt-1">5h ago</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="dropdown-menu-footer">
                            <a href="#" className="text-muted">Show all messages</a>
                        </div>
                    </div>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-bs-toggle="dropdown">
                        <FeatherIcon addedClassName="align-middle" iconName="settings" />
                    </a>
                    <a className="nav-link dropdown-toggle d-none d-sm-inline-block" href="#" data-bs-toggle="dropdown">
                        <img src="adminkit/img/avatars/esagramma.jpg" className="avatar img-fluid rounded me-1" alt="{loggedUser.nome} {loggedUser.cognome} ({loggedUser.tipoUtente}" /> <span className="text-dark">{loggedUser.nome} {loggedUser.cognome} ({loggedUser.tipoUtente})</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                        {/*<div className="dropdown-divider"></div>*/}
                        <a className="dropdown-item" onClick={() => { checkUnsavedBeforeDoing(() => { goToMieInfo(); }); }}><FeatherIcon addedClassName="align-middle me-1" iconName="user" /> Le mie informazioni</a>
                        <a className="dropdown-item" href="mailto:supporto@digitales.srl"><FeatherIcon addedClassName="align-middle me-1" iconName="mail" /> Supporto</a>
                        <div className="dropdown-divider"></div>
                        <LogoutButton handleLogout={() => { props.handleLogout(false, false); }} />
                    </div>
                </li>
            </ul>
        </div>
    </nav>);
}

export default Navbar;