import FeatherIcon from "./FeatherIcon";
function Heading(props) {
    return (
        <div className="d-block pt-0 pb-4">
            <div className="headingdiv">
                <div className="d-inline feather-icon-grande">
                    <FeatherIcon big={true} iconName={props.iconName} addedClassName={""} />
                </div>
                <div className="d-inline-block h-100">
                    <h1 className="text-center d-inline m-0" style={{color: "#333333", lineHeight: "1"}}>{props.title}</h1>
                </div>
            </div>
        </div>
    );
}

export default Heading;