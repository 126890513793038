import { useEffect } from "react";
import { useState } from "react";
import { ContextGestionale } from '../App';
import { useContext } from 'react';
import SearchAndSelect from "./SearchAndSelect";


function SelettoreProfessionistaPaz(props) {
    const [lista, setLista] = useState([]);
    const [selected, setSelected] = useState([]);
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    //quando dall'esterno ti settano props.updated a false, è perchè devi aggiornare la tua lista dei pazienti!
    useEffect(() => {
        if (props.updated === false) {
            if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
                console.log("useEffect: aggiornamento lista "+(props.paziente ? "pazienti" : "professionisti")+" richiesto");
              }
            props.setUpdated(true);
            setLoading(true);
            nodeReq.get(process.env.REACT_APP_API_URL + "/professionistapaz/tutti?tipoUtente=" + (props.paziente ? "paziente" : "professionista") + "&soloAttivi=true&ordineAlfabetico=true")
                .then(response => {
                    if (response.status === 200) {
                        let listaTemp = response.data.dbdata;
                        for (let i = 0; i < listaTemp.length; i++) {
                            listaTemp[i].id = listaTemp[i].idUtente;
                            listaTemp[i].viewed = true;
                        }
                        if (props.allowAll) {
                            let all = {};
                            all.id = 0;
                            all.viewed = true;
                            listaTemp.unshift(all);
                        }
                        setLista(listaTemp);
                    } else {
                        genericAlert("Non sei autorizzato a visualizzare i " + (props.paziente ? "pazienti" : "professionisti"));
                    }
                }).catch(error => {
                    handleAPIError(error, "recuperare la lista dei " + (props.paziente ? "pazienti" : "professionisti"));
                })
                .finally(() => {
                    setLoading(false);
                  });
        }
    }, [props.updated]);

    useEffect(() => {
        if(props.allowAll){
            setSelected(["0"]);
        }
    }, []);

    useEffect(() => {
        if (selected.length === 1) {
            if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
                console.log("useEffect: "+ (props.paziente ? "paziente" : "professionista") + " selezionato");
              }
            props.setSelected(parseInt(selected[0]));
            if(props.setNomeCognomeSelezionato != undefined){
                if(selected[0] != 0){
                    for(let i = 0;i < lista.length;i++){
                        if(selected[0] == lista[i].idUtente){
                            props.setNomeCognomeSelezionato(lista[i].cognome.toUpperCase() + " " + lista[i].nome.toUpperCase());
                            break;
                        }
                    }
                }else{
                    props.setNomeCognomeSelezionato("Tutti i " + (props.paziente ? "pazienti" : "professionisti"));
                }
            }
        }
    }, [selected]);


    useEffect(() => {
        if (Array.isArray(props.selected)) {
            if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
                console.log("useEffect: gestisci selezione "+(props.paziente ? "paziente" : "professionista")+" da componente esterno");
              }
            setSelected(props.selected);
        }
    }, [props.selected]);


    //come mostrare il testo nella searchAndSelect
    function getProfessionistaPazText(element) {
        if (element.id !== 0) {
            return element.cognome.toUpperCase() + " " + element.nome.toUpperCase();
        } else {
            return "Tutti i " + (props.paziente ? "pazienti" : "professionisti");
        }
    }
    return (
        <>
            {props.show && <>
                <SearchAndSelect oneElementName={(props.paziente ? "un paziente" : "un professionista")} title={(props.paziente ? "Paziente" : "Professionista")} elements={lista} allowMultiple={false} showDeselectButton={false} validSelection={true} setValidSelection={(val) => { }} required={true} selectedOptions={selected} setSelectedOptions={setSelected} getElementText={getProfessionistaPazText} disabled={props.disabled} filterFunction={props.filterFunction} notSelectableFunction={props.notSelectableFunction}/>
                {props.allowAll && <button className="btn btn-light m-1" onClick={() => { setSelected(["0"]); }}>{"Tutti i " + (props.paziente ? "pazienti" : "professionisti")}</button>}
            </>
            }
        </>
    );
}

export default SelettoreProfessionistaPaz;