import React from "react";
import { useContext } from "react";
import { ContextGestionale } from "../../App";

function BlankOrNotFound() {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    return (
        <div style={{ display: pageViewed === pages.BlankOrNotFound ? 'block' : 'none' }}>
            <h1 className="h3 mb-3">La pagina richiesta non esiste oppure non hai il permesso di visualizzarla</h1>
            <button className="btn btn-primary" onClick={() => { checkUserLoggedBeforeDoing(() => { setPageViewed(pages.Dashboard); cambiaUrl(pages.Dashboard); }) }}>Torna alla dashboard</button>
        </div>
    );
}

export default BlankOrNotFound;