import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { ContextGestionale } from "../App";
import APICallButton from "./APICallButton";

function AggiornaMiaPassword(props) {
    const [passwordAttuale, setPasswordAttuale] = useState("");
    const [nuovaPassword, setNuovaPassword] = useState("");
    const [confermaPassword, setConfermaPassword] = useState("");
    const [passwordAttualeOk, setPasswordAttualeOk] = useState(true);
    const [formatoPasswordOk, setFormatoPasswordOk] = useState(true);
    const [passwordUguali, setPasswordUguali] = useState(true);
    const [modificaPasswordAbilitata, setModificaPasswordAbilitata] = useState(false);
    const [isShowPasswords, setShowPasswords] = useState(false);

    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    //quando viene modificato uno dei 3 campi delle password, non mostrare più la scritta rossa di errore
    //se i 3 campi della password non sono tutti vuoti, vuol dire che hai modifiche non salvate
    useEffect(() => {
        if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
            console.log("useEffect: onChange password rilevato");
          }
        setPasswordAttualeOk(true); setFormatoPasswordOk(true); setPasswordUguali(true);
        if(nuovaPassword !== "" || passwordAttuale !== "" || confermaPassword !== ""){
            setPasswordUnsaved(true);
        }else{
            setPasswordUnsaved(false);
        }
    }, [nuovaPassword, passwordAttuale, confermaPassword]);

    useEffect(() => {
        if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
            console.log("useEffect: montaggio AggiornaMiaPassword.js");
          }
        setNuovaPassword(""); setConfermaPassword(""); setPasswordAttuale("");
    }, []);

    function updatePassword() {
        let passwordFormatOk = props.passwordFormatIsOk(nuovaPassword);
        if (passwordAttuale.length !== 0 && nuovaPassword.length !== 0 && confermaPassword.length !== 0 && nuovaPassword === confermaPassword && passwordFormatOk) {
            const reqbody = { password: nuovaPassword, pwdOld: passwordAttuale };
            setLoading(true);
            nodeReq.post(process.env.REACT_APP_API_URL + '/updatepassword', reqbody)
                .then(response => {
                    if (response.status === 200) {
                        genericAlert("Password aggiornata!");
                        setPasswordAttuale("");
                        setNuovaPassword("");
                        setConfermaPassword("");
                    }
                })
                .catch(error => {
                    if (error.response.status === 400 && error.response.data === "pwdOldIncorrect") {
                        setPasswordAttualeOk(false);
                    } else {
                        handleAPIError(error, "aggiornare la tua password");
                    }
                })
                .finally(() => {
                    setLoading(false);
                  });
        } else {
            if (nuovaPassword !== confermaPassword) {
                setPasswordUguali(false);
            }else if(!passwordFormatOk){
                setFormatoPasswordOk(false);
            }
        }
    }
    return (
        <div className="col-sm">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Cambia password</h5>
                </div>
                <div className="card-body">
                    {!modificaPasswordAbilitata && <button className="btn btn-light" onClick={() => {setModificaPasswordAbilitata(true);}}>Abilita modifica della password</button>}
                    <div>
                        <label htmlFor="pwdOld" className="card-subtitle text-muted">Password attuale</label>
                        <input id="pwdOld" type={isShowPasswords ? "text" : "password"} name="pwdOld" className={"form-control mb-1 " + (passwordAttualeOk ? "" : "is-invalid")} autoComplete="off" value={passwordAttuale} onChange={(event) => { setPasswordAttuale(event.target.value); }} disabled={!modificaPasswordAbilitata}/>
                        <div className="invalid-feedback">
                            Non hai inserito la password attuale
                        </div>
                    </div>
                    <div>
                        <label htmlFor="pwd" className="card-subtitle text-muted">Password</label>
                        <input id="pwd" type={isShowPasswords ? "text" : "password"} name="pwd" className={"form-control mb-1 " + (formatoPasswordOk ? "" : "is-invalid")} autoComplete="off" value={nuovaPassword} onChange={(event) => { setNuovaPassword(event.target.value); }} disabled={!modificaPasswordAbilitata}/>
                        <div className="invalid-feedback">
                            La password deve essere lunga almeno 12 caratteri e contenere almeno un numero, almeno una lettera maiuscola, almeno una lettera minuscola e almeno un carattere speciale tra questi: {props.passwordSpecialChars}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="confermaPwd" className="card-subtitle text-muted">Conferma password</label>
                        <input id="confermaPwd" type={isShowPasswords ? "text" : "password"} name="confermaPwd" className={"form-control mb-1 " + (passwordUguali ? "" : "is-invalid")} autoComplete="off" value={confermaPassword} onChange={(event) => { setConfermaPassword(event.target.value); }} disabled={!modificaPasswordAbilitata}/>
                        <div className="invalid-feedback">
                            Inserire due password uguali
                        </div>
                    </div>
                    <div>
                        <label htmlFor="showPasswords" className="card-subtitle text-muted">Mostra le password</label>
                        <input id="showPasswords" type="checkbox" className="form-check-input m-1" checked={isShowPasswords} onChange={(event) => {setShowPasswords(event.target.checked);}}/>
                    </div>
                    {/*props richieste: buttonOnClick, buttonClassName, buttonDisabled, buttonText*/}
                    <APICallButton buttonOnClick={updatePassword} buttonText="Aggiorna password" buttonClassName="btn btn-primary m-1" buttonDisabled={!modificaPasswordAbilitata} nomeProcedura={"l'aggiornamento della password"}/>
                </div>
            </div>
        </div>
    );
}

export default AggiornaMiaPassword;