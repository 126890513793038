import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ContextGestionale } from '../../App';
import { useContext } from 'react';
import SearchAndSelect from '../SearchAndSelect';

/*
Modal che viene mostrato quando si clicca su "Filtri" nella lista dei professionisti o dei pazienti (ListaProfessionistaPaz.js)
*/
function ModalDialog(props) {
  const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
  //liste di tutti i pazienti, professionisti e servizi, usate dalle select multiple. Ogni loro elemento ha l'attributo viewed che dice se mostrarlo nella select multiple.
  //liste degli id dei pazienti, servizi e professionisti selezionati all'interno delle select.
  const [selectedPazienti, setSelectedPazienti] = useState([]);
  const [selectedServizi, setSelectedServizi] = useState([]);
  const [selectedProfessionisti, setSelectedProfessionisti] = useState([]);
  //Ognuno di questi tre boolean è true solo se non si seleziona più di un paziente/professionista/servizio nelle select.
  const [pazientiSelectOk, setPazientiSelectOk] = useState(true);
  const [professionistiSelectOk, setProfessionistiSelectOk] = useState(true);
  const [serviziSelectOk, setServiziSelectOk] = useState(true);
  //unsavedFilters contiene i filtri così come sono impostati nel modal prima dell'eventuale salvataggio.
  const [unsavedFilters, setUnsavedFilters] = useState({});
  const initialFilters = {
    nome: "",
    cognome: "",
    email: "",
    cf: "",
    id: "",
    attivi: true,
    nonAttivi: false,
    pazienteAssegnato: undefined,
    professionistaAssegnato: undefined,
    servizioOfferto: undefined
  }
  //FUNZIONI DI GESTIONE DEGLI AGGIORNAMENTI DEI CAMPI SUL FORM (gli aggiornamenti finiscono tutti su unsavedFilters)
  //campi di testo e checkbox
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setUnsavedFilters(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  //Se il modal è appena stato aperto, gestisci l'apertura del form dei filtri. Carica tutti i campi e le liste dei pazienti, dei professionisti e dei servizi
  useEffect(() => {
    if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
      console.log("useEffect: modal dei filtri aperto");
    }
    setUnsavedFilters(JSON.parse(JSON.stringify(props.filters)));
    setSelectedPazienti(props.filters.pazienteAssegnato !== undefined ? [props.filters.pazienteAssegnato] : []);
    setSelectedProfessionisti(props.filters.professionistaAssegnato !== undefined ? [props.filters.professionistaAssegnato] : []);
    setSelectedServizi(props.filters.servizioOfferto !== undefined ? [props.filters.servizioOfferto] : []);
    if (props.showmodal === true) {
      if (props.pazienti === true) {
        //carica la lista dei professionisti
        setLoading(true);nodeReq.get(process.env.REACT_APP_API_URL + '/professionistapaz/tutti?ordineAlfabetico=true&tipoUtente=professionista')
          .then(response => {
            if (response.status === 200) {
              let data = response.data.dbdata;
              for (let i = 0; i < data.length; i++) {
                data[i].viewed = true;
                data[i].id = data[i].idUtente;
              }
              setListaProfessionisti(data);
            } else {
              genericAlert("Impossibile recuperare la lista dei professionisti. Riprova più tardi");
            }
          })
          .catch(error => {
            handleAPIError(error, "recuperare la lista dei professionisti");
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        //carica la lista dei pazienti
        setLoading(true);nodeReq.get(process.env.REACT_APP_API_URL + '/professionistapaz/tutti?ordineAlfabetico=true&tipoUtente=paziente')
          .then(response => {
            if (response.status === 200) {
              let data = response.data.dbdata;
              for (let i = 0; i < data.length; i++) {
                data[i].viewed = true;
                data[i].id = data[i].idUtente;
              }
              setListaPazienti(data);
            } else {
              genericAlert("Impossibile recuperare la lista dei pazienti. Riprova più tardi");
            }
          })
          .catch(error => {
            handleAPIError(error, "recuperare la lista dei pazienti");
          })
          .finally(() => {
            setLoading(false);
          });
        //carica la lista dei servizi
        setLoading(true);nodeReq.get(process.env.REACT_APP_API_URL + '/servizi?ordineAlfabetico=true')
          .then(response => {
            let data = response.data.dbdata;
            if (response.status === 200) {
              for (let i = 0; i < data.length; i++) {
                data[i].viewed = true;
                data[i].id = data[i].idServizio;
              }
              setListaServizi(data);
            } else {
              genericAlert("Impossibile recuperare la lista dei servizi. Riprova più tardi");
            }
          })
          .catch(error => {
            handleAPIError(error, "recuperare la lista dei servizi");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [props.showmodal]);
  //come mostrare il testo di un professionista o di un paziente nella select
  function getProfessionistaPazText(element){
    return element.nome + " " + element.cognome;
  }
  //come mostrare il testo di un servizio nella select
  function getServizioText(element){
    return element.nomeServizio + (element.descrizioneServizio.length > 0 ? " ("+element.descrizioneServizio+") " : "");
  }

  //FUNZIONI CHIAMATE DAI TRE PULSANTI IN BASSO
  function salva() {
    if (professionistiSelectOk && pazientiSelectOk && serviziSelectOk) {
      let unsavedFiltersTemp = JSON.parse(JSON.stringify(unsavedFilters));
      unsavedFiltersTemp.pazienteAssegnato = selectedPazienti.length === 0 ? undefined : selectedPazienti[0];
      unsavedFiltersTemp.professionistaAssegnato = selectedProfessionisti.length === 0 ? undefined : selectedProfessionisti[0];
      unsavedFiltersTemp.servizioOfferto = selectedServizi.length === 0 ? undefined : selectedServizi[0];
      setUnsavedFilters(unsavedFiltersTemp);
      props.setFilters(JSON.parse(JSON.stringify(unsavedFiltersTemp)));
      props.closemodal();
    }
  }
  function reimposta() {
    props.setFilters(JSON.parse(JSON.stringify(initialFilters)));
    setSelectedServizi([]);
    setSelectedPazienti([]);
    setSelectedProfessionisti([]);
    setPazientiSelectOk(true);
    setProfessionistiSelectOk(true);
    setServiziSelectOk(true);
    props.closemodal();
  }
  function annulla() {
    setUnsavedFilters(JSON.parse(JSON.stringify(props.filters)));
    setSelectedServizi([props.filters.servizioOfferto]);
    setSelectedPazienti([props.filters.pazienteAssegnato]);
    setSelectedProfessionisti([props.filters.professionistaAssegnato]);
    setPazientiSelectOk(true);
    setProfessionistiSelectOk(true);
    setServiziSelectOk(true);
    props.closemodal();
  }
  return (
    <div>
      <Modal show={props.showmodal}>
        <Modal.Header closeButton onClick={annulla}>
          <Modal.Title><h3 className="card-title">Filtri</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*Caselle di testo*/}
          <section>
            <label class="form-label fw-bold">Informazioni</label>
            <input type="text" name="nome" className="form-control mb-1" placeholder="Nome" value={unsavedFilters.nome} onChange={handleChange} />
            <input type="text" name="cognome" className="form-control mb-1" placeholder="Cognome" value={unsavedFilters.cognome} onChange={handleChange} />
            <input type="text" name="email" className="form-control mb-1" placeholder="Indirizzo email" value={unsavedFilters.email} onChange={handleChange} />
            <input type="text" name="cf" className="form-control mb-1" placeholder="CF" value={unsavedFilters.cf} onChange={handleChange} />
          </section>
          {/*Checkbox*/}
          <section>
            <input type="checkbox" name="attivi" checked={unsavedFilters.attivi === true} onChange={handleChange} />
            <label htmlFor="includeActiveUser">Includi utenti attivi</label>
            <input type="checkbox" name="nonAttivi" checked={unsavedFilters.nonAttivi === true} onChange={handleChange} />
            <label htmlFor="includeNotActiveUser">Includi utenti non attivi</label>
          </section>
          {/*Selezione di un paziente/professionista specifico*/}
          <section>
            {props.pazienti === true &&
              <SearchAndSelect title={"Professionisti assegnati"} elements={listaProfessionisti} allowMultiple={false} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedProfessionisti} setSelectedOptions={setSelectedProfessionisti} getElementText={getProfessionistaPazText}/>
            }
            {props.pazienti === false &&
              <SearchAndSelect title={"Pazienti assegnati"} elements={listaPazienti} allowMultiple={false} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedPazienti} setSelectedOptions={setSelectedPazienti} getElementText={getProfessionistaPazText}/>
            }
          </section>
          {/*Selezione di un servizio specifico*/}
          {props.pazienti === false &&
            <section>
              <SearchAndSelect title={"Servizi offerti"} elements={listaServizi} allowMultiple={false} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedServizi} setSelectedOptions={setSelectedServizi} getElementText={getServizioText}/>
            </section>
          }
          {/*Errori mostrati se si seleziona più di un paziente/professionista/servizio*/}
          {(props.pazienti && !(professionistiSelectOk && pazientiSelectOk && serviziSelectOk)) && <p className="text-danger">Puoi selezionare al massimo un professionista</p>}
          {(!props.pazienti && !(professionistiSelectOk && pazientiSelectOk && serviziSelectOk)) && <p className="text-danger">Puoi selezionare al massimo un paziente e un servizio</p>}
        </Modal.Body>
        {/*Tre bottoni sul footer*/}
        <Modal.Footer>
          <Button variant="primary" onClick={salva} disabled={!(professionistiSelectOk && pazientiSelectOk && serviziSelectOk)}>
            Salva
          </Button>
          <Button variant="primary" onClick={reimposta}>
            Reimposta
          </Button>
          <Button variant="primary" onClick={annulla}>
            Annulla
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default ModalDialog;