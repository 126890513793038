import React from 'react';
import { useState, useEffect } from 'react';

function Loader(props) {
    const [animationSprite, setAnimationSprite] = useState(0);
    const animationTime = 125;
    useEffect(() => {
        setTimeout(() => { setAnimationSprite((animationSprite + 1) % 8); }, animationTime);
    }, []);
    useEffect(() => {
        setTimeout(() => { setAnimationSprite((animationSprite + 1) % 8); }, animationTime);
    }, [animationSprite]);
    return (
        <>
            {props.insideButton != true &&
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={(props.small == true ? "loader-small" : "loader") + " feather feather-loader align-middle"}>
                    <line className={animationSprite == 0 ? "stroke-light" : "stroke-dark"} x1="12" y1="2" x2="12" y2="6"></line>
                    <line className={animationSprite == 4 ? "stroke-light" : "stroke-dark"} x1="12" y1="18" x2="12" y2="22"></line>
                    <line className={animationSprite == 7 ? "stroke-light" : "stroke-dark"} x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line className={animationSprite == 3 ? "stroke-light" : "stroke-dark"} x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line className={animationSprite == 6 ? "stroke-light" : "stroke-dark"} x1="2" y1="12" x2="6" y2="12"></line>
                    <line className={animationSprite == 2 ? "stroke-light" : "stroke-dark"} x1="18" y1="12" x2="22" y2="12"></line>
                    <line className={animationSprite == 5 ? "stroke-light" : "stroke-dark"} x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line className={animationSprite == 1 ? "stroke-light" : "stroke-dark"} x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                </svg>
            }
            {props.insideButton == true &&
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={"loader-inside-button feather feather-loader align-middle"}>
                    <line className={animationSprite == 0 ? "stroke-light" : "stroke-white"} x1="12" y1="2" x2="12" y2="6"></line>
                    <line className={animationSprite == 4 ? "stroke-light" : "stroke-white"} x1="12" y1="18" x2="12" y2="22"></line>
                    <line className={animationSprite == 7 ? "stroke-light" : "stroke-white"} x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line className={animationSprite == 3 ? "stroke-light" : "stroke-white"} x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line className={animationSprite == 6 ? "stroke-light" : "stroke-white"} x1="2" y1="12" x2="6" y2="12"></line>
                    <line className={animationSprite == 2 ? "stroke-light" : "stroke-white"} x1="18" y1="12" x2="22" y2="12"></line>
                    <line className={animationSprite == 5 ? "stroke-light" : "stroke-white"} x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line className={animationSprite == 1 ? "stroke-light" : "stroke-white"} x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                </svg>
            }
        </>
    );
}

export default Loader;