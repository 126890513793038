import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { ContextGestionale } from '../../App';
import { useContext } from 'react';


/*
Modal dove vengono mostrate le sovrapposizioni
*/
function ModalSovrapposizioni(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    function testoPeriodoPrenotazione(dataOraInizio, dataOraFine) {
		if (dataOraInizio.substring(0, 10) === dataOraFine.substring(0, 10)) {
			return "Il " + dataFormatoItaliano(dataOraInizio.substring(0, 10)) + ", dalle " + dataOraInizio.substring(11, dataOraInizio.length) + " alle " + dataOraFine.substring(11, dataOraFine.length);
		} else {
			return "Dalle ore " + dataOraInizio.substring(11, dataOraInizio.length) + " del " + dataFormatoItaliano(dataOraInizio.substring(0, 10)) + " alle ore " + dataOraFine.substring(11, dataOraFine.length) + " del " + dataFormatoItaliano(dataOraFine.substring(0, 10));
		}
	}
    return (
        <div>
            <Modal show={props.showmodal}>
                <Modal.Header closeButton onClick={props.closemodal}>
                    <Modal.Title><h3 className="card-title">Sovrapposizioni con altre prenotazioni</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-danger">Le seguenti prenotazioni sono in sovrapposizione</p>
                    {props.sovrapposizioni && props.sovrapposizioni.length > 0 && (
                        <ul>
                            {props.sovrapposizioni.map((item, index) => (
                                <li key={item.id}><b>Motivo prenotazione: </b>{item.motivo} . <b>Periodo: </b>{testoPeriodoPrenotazione(item.dataOraInizio, item.dataOraFine)}</li>
                            ))}
                        </ul>
                    )}
                </Modal.Body>
            </Modal>

        </div>
    )
}
export default ModalSovrapposizioni;