import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
/*
Dialog di messaggio generico
*/
function EsitoGenerazioneFattureModal(props) {
    //props richieste: closemodal, showmodal, esito
    const [numeroFattureSeduteOk, setNumeroFattureSeduteOk] = useState(0);
    const [numeroFattureCertificazioniOk, setNumeroFattureCertificazioniOk] = useState(0);

    useEffect(() => {
        if (props.esito != undefined && Array.isArray(props.esito.fattureSeduteOk) && Array.isArray(props.esito.fattureCertificazioniOk)) {
            let numeroFattureSeduteOkTemp = 0;
            for (let i = 0; i < props.esito.fattureSeduteOk.length; i++) {
                if (props.esito.fattureSeduteOk[i].esito == "Ok") {
                    numeroFattureSeduteOkTemp++;
                }
            }
            let numeroFattureCertificazioniOkTemp = 0;
            for (let i = 0; i < props.esito.fattureCertificazioniOk.length; i++) {
                if (props.esito.fattureCertificazioniOk[i].esito == "Ok") {
                    numeroFattureCertificazioniOkTemp++;
                }
            }
            setNumeroFattureSeduteOk(numeroFattureSeduteOkTemp);
            setNumeroFattureCertificazioniOk(numeroFattureCertificazioniOkTemp);
        }
    }, [props.esito]);
    return (
        <div>
            <Modal show={props.showmodal}>
                <Modal.Header closeButton onClick={props.closemodal}>
                    <Modal.Title><h3 className="card-title">Esito generazione fatture per {props.mesi[props.mese - 1]} {props.anno}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.esito != undefined && props.esito.fattureSeduteOk != undefined && props.esito.fattureCertificazioniOk != undefined) &&
                        <>
                            {props.esito.retryAfter != null &&
                                <div className="bg-warning p-2 mt-2" style={{ borderRadius: "0.25rem" }}>
                                    <p>La generazione delle fatture è stata interrotta da FattureInCloud a causa del limite massimo di richieste raggiunto. {(props.esito.retryAfter == -1 ? "Puoi riprendere la generazione delle fatture a breve." : "Puoi riprendere la generazione delle fatture tra " + parseInt(props.esito.retryAfter / 60) + ":" + (props.esito.retryAfter % 60).toString().padStart(2, '0') + " minuti.")}</p>
                                </div>
                            }
                            {props.esito.fattureSeduteOk.length > 0 &&
                                <>
                                    <p><b>Fatture per le sedute:</b></p>
                                    <p className="text-success"><b>{numeroFattureSeduteOk} fatture generate correttamente</b></p>
                                    <ul>
                                        {props.esito.fattureSeduteOk.map((fattura, index) => {
                                            if (fattura.esito != "Ok") {
                                                return <li className={fattura.esito == "Non generata. Fatturazione interrotta" ? "text-warning" : "text-danger"}><b>Paziente: </b>{fattura.paziente} <b>Professionista: </b>{fattura.professionista} <b>Esito: </b>{fattura.esito}</li>;
                                            }
                                        })}
                                    </ul>
                                </>
                            }
                            {props.esito.fattureCertificazioniOk.length > 0 &&
                                <>
                                    <p><b>Fatture per le certificazioni:</b></p>
                                    <p className="text-success"><b>{numeroFattureCertificazioniOk} fatture generate correttamente</b></p>
                                    <ul>
                                        {props.esito.fattureCertificazioniOk.map((fattura, index) => {
                                            if (fattura.esito != "Ok") {
                                                return <li className={fattura.esito == "Non generata. Fatturazione interrotta" ? "text-warning" : "text-danger"}><b>Paziente: </b>{fattura.paziente} <b>Professionista: </b>{fattura.professionista} <b>Servizio: </b>{fattura.nomeServizio} <b>Nota certificazione: </b>{fattura.notaCertificazione} <b>Esito: </b>{fattura.esito}</li>;
                                            }
                                        })}
                                    </ul>
                                </>
                            }
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.closemodal}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default EsitoGenerazioneFattureModal;