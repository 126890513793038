import Calendar from "../Calendar";
import { useState, useEffect } from "react";
import DettagliSeduta from "../DettagliSeduta";
import DettagliPrenotazione from "../DettagliPrenotazione";
import FeatherIcon from "../FeatherIcon";
import Legenda from "../Legenda";
import SelettoreProfessionistaPaz from "../SelettoreProfessionistaPaz";
import { useContext } from 'react';
import { useRef } from "react";
import { ContextGestionale } from "../../App";
import ModalEventoSelezionato from "../modals/ModalEventoSelezionato";
import Heading from "../Heading";
import ModalPrompt from "../modals/ModalPrompt";
import SearchAndSelect from "../SearchAndSelect";

function Dashboard(props) {
	const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso } = useContext(ContextGestionale);
	const [calendarSedute, setCalendarSedute] = useState([]);
	const [calendarPrenotazioni, setCalendarPrenotazioni] = useState([]);
	const [calendarDisponibilita, setCalendarDisponibilita] = useState([]);
	const [calendarAllEvents, setCalendarAllEvents] = useState([]);
	const selectedPrenotazioneId = useRef(undefined);
	const selectedSedutaId = useRef(undefined);
	const [listaPrenotazioni, setListaPrenotazioni] = useState([]);
	const [isShowDeleteSeduta, setShowDeleteSeduta] = useState(false);
	const [isShowDeletePrenotazione, setShowDeletePrenotazione] = useState(false);
	const [isShowSegnaSeduta, setShowSegnaSeduta] = useState(false);
	const [isShowModalEventoSelezionato, setShowModalEventoSelezionato] = useState(false);
	const [numSeduteMese, setNumSeduteMese] = useState(null);
	const [numSeduteSettimana, setNumSeduteSettimana] = useState(null);
	const [numPazientiAttivi, setNumPazientiAttivi] = useState(null);
	const [numProfessionistiAttivi, setNumProfessionistiAttivi] = useState(null);
	const [selectedProfessionista, setSelectedProfessionista] = useState(0);
	const [professionistiUpdated, setProfessionistiUpdated] = useState(true);
	const calendarRef = useRef(null);
	const [calendarStartDate, setCalendarStartDate] = useState(undefined);
	const [calendarEndDate, setCalendarEndDate] = useState(undefined);
	const [queryDateRange, setQueryDateRange] = useState([((new Date()).getUTCFullYear() - 1) + "-12-21", ((new Date()).getUTCFullYear()) + "-02-08"]);
	const [mostraSeduteOnline, setMostraSeduteOnline] = useState(true);
	const handleCalendarSingleClick = useRef(false);
	const openModalTimeout = useRef(undefined);
	const [nomeCognomeSelezionato, setNomeCognomeSelezionato] = useState("Tutti i professionisti");
	const [filtroProfessionistiExpanded, setFiltroProfessionistiExpanded] = useState(false);
	const [mostraDisponibilitaProfessionisti, setMostraDisponibilitaProfessionisti] = useState(true);
	const [nota, setNota] = useState("");
	const [effettuata, setEffettuata] = useState(true);
	const [currentViewType, setCurrentViewType] = useState("");
	const [pazientiCardExpanded, setPazientiCardExpanded] = useState(false);

	//Questo JSON contiene i testi mostrati nel modal di delete della seduta
	const [sedutaText, setSedutaText] = useState({});
	const [prenotazioneText, setPrenotazioneText] = useState({});
	const [prenotazioneToUpdate, setPrenotazioneToUpdate] = useState({});
	const legendaColors = [
		{ bg: process.env.REACT_APP_SEDUTA_PRENOTATA_PAGINA_DASHBOARD_BGCOLOR, text: "Seduta in presenza", txtCol: "white" },
		{ bg: process.env.REACT_APP_SEDUTA_ONLINE_BGCOLOR, text: "Seduta online", txtCol: "white" },
		{ bg: process.env.REACT_APP_SEDUTA_IBRIDA_BGCOLOR, text: "Seduta in modalità ibrida", txtCol: "white" },
		{ bg: process.env.REACT_APP_SEDUTA_EFFETTUATA_BGCOLOR, text: "Seduta effettuata", txtCol: "white" },
		{ bg: process.env.REACT_APP_SEDUTA_NON_EFFETTUATA_BGCOLOR, text: "Seduta non effettuata", txtCol: "white" },
		{ bg: process.env.REACT_APP_PRENOTAZIONE_GENERICA_BGCOLOR, text: "Prenotazione generica", txtCol: "white" },
		{ bg: process.env.REACT_APP_DISPONIBILITA_PROFESSIONISTA_BGCOLOR, text: "Disponibilità professionista", txtCol: "black" }
	];

	const fullCalendarDiv = useRef(null);

	useEffect(() => { console.log("GLI EVENTI SONO CAMBIATI!"); }, [calendarAllEvents]);

	//all'apertura della dashboard, carica tutte le sedute dal db
	useEffect(() => {
		if (pageViewed === pages.Dashboard) {
			if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
				console.log("useEffect: dashboard aperta");
			}
			loadSedute();
			loadPrenotazioni();
			loadDisponibilita();
			if (loggedUser.tipoUtente == "segretario" || loggedUser.tipoUtente == "visualizzatore") {
				loadNumSeduteMese();
				loadNumSeduteSettimana();
				loadNumPazientiProfessionistiAttivi();
			}
			if (loggedUser.tipoUtente == "professionista") {
				setPazientiCardExpanded(false);
				loadMieiPazienti();
			}
			document.getElementById("customViewScrollX").innerHTML = 0;
			document.getElementById("customViewScrollY").innerHTML = 0;
			//chiedi al selettore del professionista di caricare i professionisti
			setProfessionistiUpdated(false);
			setMostraSeduteOnline(true);
		}
	}, [pageViewed]);

	//quando cambiano le date selezionate nel FullCalendar, decidi quali sono le date di inizio e di fine da passare allla query
	useEffect(() => {
		if (calendarStartDate != undefined && calendarEndDate != undefined) {
			if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
				console.log("useEffect: aggiorno range delle date per la query se necessario (dashboard)");
			}
			//Se l'intervallo scelto attualmente, non ricopre più le nuove date selezionate, devi spostarlo avanti o indietro di un mese finche non le ricopre entrambe
			let startMonth = calendarStartDate.getUTCMonth() + 1;
			let calendarStartDateString = calendarStartDate.getUTCFullYear() + "-" + startMonth.toString().padStart(2, "0") + "-" + calendarStartDate.getUTCDate().toString().padStart(2, "0");
			let endMonth = calendarEndDate.getUTCMonth() + 1;
			let calendarEndDateString = calendarEndDate.getUTCFullYear() + "-" + endMonth.toString().padStart(2, "0") + "-" + calendarEndDate.getUTCDate().toString().padStart(2, "0");
			let queryStartDateTemp = queryDateRange[0];
			let queryEndDateTemp = queryDateRange[1];
			while (calendarStartDateString.localeCompare(queryStartDateTemp) == -1) {
				queryStartDateTemp = oneMonthBackward(queryStartDateTemp);
				queryEndDateTemp = oneMonthBackward(queryEndDateTemp);
			}
			while (calendarEndDateString.localeCompare(queryEndDateTemp) == 1) {
				queryStartDateTemp = oneMonthForward(queryStartDateTemp);
				queryEndDateTemp = oneMonthForward(queryEndDateTemp);
			}
			if (queryStartDateTemp != queryDateRange[0] || queryEndDateTemp != queryDateRange[1]) {
				setQueryDateRange([queryStartDateTemp, queryEndDateTemp]);
			}
		}
	}, [calendarStartDate, calendarEndDate]);

	//quando cambia l'intervallo di date in cui caricare le sedute e le prenotazioni, riesegui le chiamate API
	useEffect(() => {
		checkUserLoggedBeforeDoing(() => {
			if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
				console.log("useEffect: range date cambiato. ricarico sedute e prenotazioni");
			}
			loadSedute();
			loadPrenotazioni();
		});
	}, [queryDateRange]);

	function oneMonthBackward(date) {
		if (parseInt(date.substring(5, 7)) > 1) {
			//scende solo il mese
			date = date.substring(0, 5) + (parseInt(date.substring(5, 7)) - 1).toString().padStart(2, "0") + date.substring(7, 10);
		} else {
			//il mese diventa 12 e l'anno scende
			date = (parseInt(date.substring(0, 4)) - 1).toString() + "-12" + date.substring(7, 10);
		}
		return date;
	}

	function oneMonthForward(date) {
		if (parseInt(date.substring(5, 7)) < 12) {
			//scende solo il mese
			date = date.substring(0, 5) + (parseInt(date.substring(5, 7)) + 1).toString().padStart(2, "0") + date.substring(7, 10);
		} else {
			//il mese diventa 1 e l'anno sale
			date = (parseInt(date.substring(0, 4)) + 1).toString() + "-01" + date.substring(7, 10);
		}
		return date;
	}

	function showModalEventoSelezionato() {
		if (loggedUser.tipoUtente != "visualizzatore" && (selectedSedutaId.current != undefined || selectedPrenotazioneId.current != undefined) && !(selectedPrenotazioneId.current != undefined && loggedUser.tipoUtente != "segretario")) {
			setShowModalEventoSelezionato(true);
		}
	}

	function closeModalEventoSelezionato() {
		setShowModalEventoSelezionato(false);
		//selectedSedutaId.current = undefined;
		//selectedPrenotazioneId.current = undefined;
	}

	function mostraSedutaInFullCalendar(seduta) {
		return (selectedProfessionista == 0 || (selectedProfessionista == seduta.extendedProps.idProfessionista)) && (mostraSeduteOnline || (seduta.extendedProps.room != null && seduta.extendedProps.roomName != null));
	}

	function mostraDisponibilitaInFullCalendar(disponibilita) {
		return (selectedProfessionista == 0 || (selectedProfessionista == disponibilita.extendedProps.idProfessionista));
	}

	//metti tutti gli eventi in calendarAllEvents
	useEffect(() => {
		if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
			console.log("useEffect: aggiorno eventi passati a FullCalendar");
		}
		let numSeduteMostrate = 0;
		for (let i = 0; i < calendarSedute.length; i++) {
			if (mostraSedutaInFullCalendar(calendarSedute[i])) {
				numSeduteMostrate++;
			}
		}
		let numDisponibilitaMostrate = 0;
		if (mostraDisponibilitaProfessionisti) {
			for (let i = 0; i < calendarDisponibilita.length; i++) {
				if (mostraDisponibilitaInFullCalendar(calendarDisponibilita[i])) {
					numDisponibilitaMostrate++;
				}
			}
		}
		let calendarAllEventsLength = numSeduteMostrate + calendarPrenotazioni.length + numDisponibilitaMostrate;
		let eventIndex = 0;
		let calendarAllEventsTemp = Array.from({ length: calendarAllEventsLength }, () => null);
		for (let i = 0; i < calendarSedute.length; i++) {
			if (mostraSedutaInFullCalendar(calendarSedute[i])) {
				calendarAllEventsTemp[eventIndex] = calendarSedute[i];
				eventIndex++;
			}
		}
		for (let j = 0; j < calendarPrenotazioni.length; j++) {
			calendarAllEventsTemp[eventIndex] = calendarPrenotazioni[j];
			eventIndex++;
		}
		if (mostraDisponibilitaProfessionisti) {
			for (let i = 0; i < calendarDisponibilita.length; i++) {
				if (mostraDisponibilitaInFullCalendar(calendarDisponibilita[i])) {
					calendarAllEventsTemp[eventIndex] = calendarDisponibilita[i];
					eventIndex++;
				}
			}
		}
		setCalendarAllEvents(calendarAllEventsTemp);
	}, [calendarSedute, calendarPrenotazioni, calendarDisponibilita, selectedProfessionista, mostraSeduteOnline, mostraDisponibilitaProfessionisti]);

	//carica tutte le sedute e inseriscile anche tra gli eventi del calendario
	function loadSedute() {
		setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/sedute?dataInizio=" + queryDateRange[0] + "&dataFine=" + queryDateRange[1])
			.then(response => {
				let seduteResponse = response.data.dbdata;
				let calendarSeduteTemp = [];
				for (let i = 0; i < seduteResponse.length; i++) {
					let seduta = seduteResponse[i];
					let sedutaEvent = {
						id: (seduta.idProfessionista != undefined ? "seduta-" + seduta.idSeduta : "sedutaGrigia-" + seduta.idSeduta),
						start: seduta.data + "T" + seduta.oraInizio,
						end: seduta.data + "T" + seduta.oraFine,
						title: (seduta.idPaziente != undefined ? seduta.cognomeProfessionista.toUpperCase() + " con " + soloInizialeMaiuscola(seduta.cognomePaziente) + " " + seduta.nomePaziente[0].toUpperCase() + "." : seduta.cognomeProfessionista.toUpperCase()),
						display: (seduta.idPaziente != undefined ? "auto" : "none"),
						backgroundColor: getSedutaBgColor(seduta),
						extendedProps: {
							room: (seduta.modalita != 2 ? seduta.idArea : null),
							roomName: (seduta.modalita != 2 ? seduta.nomeArea : null),
							effettuata: seduta.effettuata != null ? seduta.effettuata : "Da segnare",
							idProfessionista: seduta.idProfessionista
						},
					};
					calendarSeduteTemp.push(sedutaEvent);
				}
				setCalendarSedute(calendarSeduteTemp);
				setListaSedute(seduteResponse);
			}
			)
			.catch(error => {
				handleAPIError(error, "visualizzare le sedute");
			}
			)
			.finally(() => {
				setLoading(false);
			});
	}

	function loadMieiPazienti() {
		setLoading(true);
		nodeReq.get(process.env.REACT_APP_API_URL + "/mieiPazienti?soloAttivi=true")
			.then(response => {
				if (response.status == 200) {
					let pazienti = response.data.dbdata;
					for (let i = 0; i < pazienti.length; i++) {
						pazienti[i].id = pazienti[i].idUtente;
						pazienti[i].viewed = true;
					}
					setListaPazienti(pazienti);
				} else {
					genericAlert("Impossibile recuperare i pazienti al momento a causa di un errore. Riprova più tardi.");
				}
			})
			.catch(error => {
				handleAPIError(error, "recuperare i pazienti");
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function soloInizialeMaiuscola(str) {
		let strs = str.split(" ");
		let toReturn = "";
		for (let i = 0; i < strs.length; i++) {
			toReturn += strs[i][0].toUpperCase() + strs[i].substring(1) + " ";
		}
		return toReturn;
	}
	//restituisce il backgroundColor della seduta in base alle sue informazioni
	function getSedutaBgColor(seduta) {
		if (seduta.effettuata == true) {
			return process.env.REACT_APP_SEDUTA_EFFETTUATA_BGCOLOR;
		}
		if (seduta.effettuata == false) {
			return process.env.REACT_APP_SEDUTA_NON_EFFETTUATA_BGCOLOR;
		}
		if (seduta.idProfessionista == undefined) {
			return process.env.REACT_APP_SEDUTA_SCONOSCIUTA_BGCOLOR;
		}
		if (seduta.modalita == 2) {
			return process.env.REACT_APP_SEDUTA_ONLINE_BGCOLOR;
		}
		if (seduta.modalita == 1) {
			return process.env.REACT_APP_SEDUTA_IBRIDA_BGCOLOR;
		}
		if (seduta.effettuata == null) {
			return process.env.REACT_APP_SEDUTA_PRENOTATA_PAGINA_DASHBOARD_BGCOLOR;
		}
		return process.env.REACT_APP_SEDUTA_SCONOSCIUTA_BGCOLOR; //grigio (colore di default)
	}
	//carica in calendarPrenotazioni tutte le prenotazioni che non sono legate alle sedute
	function loadPrenotazioni() {
		setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/prenotazioni?noSedute=true&dataInizio=" + queryDateRange[0] + "&dataFine=" + queryDateRange[1])
			.then(response => {
				let prenotazioniResponse = response.data.dbdata;
				let calendarPrenotazioniTemp = [];
				let rooms = process.env.REACT_APP_ROOMS.split(";");
				for (let i = 0; i < prenotazioniResponse.length; i++) {
					let prenotazione = prenotazioniResponse[i];
					let prenotazioneEvent = {
						id: "prenotazione-" + prenotazione.idPrenotazione,
						start: prenotazione.dataOraInizio,
						end: prenotazione.dataOraFine,
						title: prenotazione.motivo,
						backgroundColor: process.env.REACT_APP_PRENOTAZIONE_GENERICA_BGCOLOR,
						extendedProps: {
							room: prenotazione.idArea,
							roomName: rooms[prenotazione.idArea - 1]
						},
					};
					calendarPrenotazioniTemp.push(prenotazioneEvent);
				}
				setCalendarPrenotazioni(calendarPrenotazioniTemp);
				setListaPrenotazioni(prenotazioniResponse);
			}
			)
			.catch(error => {
				handleAPIError(error, "visualizzare le prenotazioni");
			}
			)
			.finally(() => {
				setLoading(false);
			});
	}

	function loadDisponibilita() {
		let idProfessionistaCondition = loggedUser.tipoUtente == "professionista" ? "?idProfessionista=" + loggedUser.user : "";
		setLoading(true);
		nodeReq.get(process.env.REACT_APP_API_URL + "/disponibilitaprofessionista" + idProfessionistaCondition)
			.then(response => {
				let disponibilita = response.data.dbdata;
				let disponibilitaEventsTemp = [];
				for (let i = 0; i < disponibilita.length; i++) {
					disponibilitaEventsTemp.push({
						title: disponibilita[i].cognomeProfessionista.toUpperCase(),
						id: "disp-" + disponibilita[i].idDisponibilita,
						daysOfWeek: [disponibilita[i].giorno - 1], //conversione da dayofweek del db a dayofweek di fullcalendar
						startTime: disponibilita[i].oraInizio,
						endTime: disponibilita[i].oraFine,
						display: "none",
						extendedProps: {
							room: disponibilita[i].idArea,
							roomName: disponibilita[i].nomeArea,
							idProfessionista: disponibilita[i].idProfessionista,
							disponibilita: true
						}
					});
				}
				setCalendarDisponibilita(disponibilitaEventsTemp);
			})
			.catch(error => {
				handleAPIError(error, "visualizzare le disponibilità del professionista");
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function loadNumSeduteMese() {
		setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + '/numsedutemese')
			.then(response => {
				setNumSeduteMese(response.data.dbdata[0].num);
			})
			.catch(error => {
				handleAPIError(error, "recuperare il numero delle sedute per questo mese");
			})
			.finally(() => {
				setLoading(false);
			});
	}
	function loadNumSeduteSettimana() {
		setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + '/numsedutesettimana')
			.then(response => {
				setNumSeduteSettimana(response.data.dbdata[0].num);
			})
			.catch(error => {
				handleAPIError(error, "recuperare il numero delle sedute per questa settimana");
			})
			.finally(() => {
				setLoading(false);
			});
	}
	function loadNumPazientiProfessionistiAttivi() {
		setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + '/contaUtentiAttivi')
			.then(response => {
				let counts = response.data.dbdata;
				for (let i = 0; i < counts.length; i++) {
					if (counts[i].tipoUtente == "paziente") {
						setNumPazientiAttivi(counts[i].num);
					}
					if (counts[i].tipoUtente == "professionista") {
						setNumProfessionistiAttivi(counts[i].num);
					}
				}
			})
			.catch(error => {
				handleAPIError(error, "recuperare il numero delle sedute per questa settimana")
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function closeDeleteSeduta() {
		if (loggedUser.tipoUtente != "visualizzatore") {
			setShowDeleteSeduta(false);
		}
	}

	function closeSegnaSeduta() {
		setShowSegnaSeduta(false);
	}

	//inserisci tutte le informazioni della seduta selezionata in sedutaText
	useEffect(() => {
		if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
			console.log("useEffect: carico tutte le info della seduta selezionata");
		}
		updateSedutaText();
	}, [selectedSedutaId.current, listaSedute]);

	function updateSedutaText() {
		if (selectedSedutaId.current != undefined) {
			//Recupera tutte le informazioni della seduta
			let seduta = undefined;
			for (let i = 0; i < listaSedute.length; i++) {
				if (listaSedute[i].idSeduta == selectedSedutaId.current) {
					seduta = listaSedute[i];
					break;
				}
			}
			//mettile qui dentro
			if (seduta != undefined) {
				setSedutaText({
					nomeProfessionista: seduta.nomeProfessionista,
					cognomeProfessionista: seduta.cognomeProfessionista,
					nomePaziente: seduta.nomePaziente,
					cognomePaziente: seduta.cognomePaziente,
					nomeServizio: seduta.nomeServizio,
					descrizioneServizio: seduta.descrizioneServizio,
					certificazione: (seduta.certificazione != null && seduta.certificazione != undefined) ? seduta.certificazione : null,
					dove: seduta.nomeArea,
					modalita: seduta.modalita,
					data: seduta.data,
					orario: "Dalle " + seduta.oraInizio + " alle " + seduta.oraFine,
					effettuata: seduta.effettuata,
					nota: seduta.nota
				});
			}
		} else {
			setSedutaText({});
		}
	}

	useEffect(() => {
		if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
			console.log("useEffect: carico tutte le info della prenotazione selezionata");
		}
		if (selectedPrenotazioneId.current != undefined) {
			let prenotazione = undefined;
			//recupera tutte le informazioni della prenotazione
			for (let i = 0; i < listaPrenotazioni.length; i++) {
				if (listaPrenotazioni[i].idPrenotazione == selectedPrenotazioneId.current) {
					prenotazione = listaPrenotazioni[i];
					break;
				}
			}
			if (prenotazione == undefined) {
				console.log("PRENOTAZIONE è RIMASTO UNDEFINED!! selectedPrenotazioneId.current = " + selectedPrenotazioneId.current);
			}
			setPrenotazioneToUpdate({
				idPrenotazioneToUpdate: prenotazione.idPrenotazione,
				motivo: prenotazione.motivo,
				dataOraInizio: prenotazione.dataOraInizio,
				dataOraFine: prenotazione.dataOraFine,
				idArea: prenotazione.idArea
			});
			//mettile qui dentro
			setPrenotazioneText({
				motivo: prenotazione.motivo,
				dove: process.env.REACT_APP_ROOMS.split(";")[prenotazione.idArea - 1],
				periodo: testoPeriodoPrenotazione(prenotazione.dataOraInizio, prenotazione.dataOraFine)
			});
		} else {
			setPrenotazioneText({});
			setPrenotazioneToUpdate({});
		}
	}, [selectedPrenotazioneId.current, listaPrenotazioni]);

	function testoPeriodoPrenotazione(dataOraInizio, dataOraFine) {
		if (dataOraInizio.substring(0, 10) == dataOraFine.substring(0, 10)) {
			return "Il " + dataFormatoItaliano(dataOraInizio.substring(0, 10)) + ", dalle " + dataOraInizio.substring(11, dataOraInizio.length) + " alle " + dataOraFine.substring(11, dataOraFine.length);
		} else {
			return "Dalle ore " + dataOraInizio.substring(11, dataOraInizio.length) + " del " + dataFormatoItaliano(dataOraInizio.substring(0, 10)) + " alle ore " + dataOraFine.substring(11, dataOraFine.length) + " del " + dataFormatoItaliano(dataOraFine.substring(0, 10));
		}
	}

	function showDeleteSeduta() {
		if (loggedUser.tipoUtente != "visualizzatore") {
			setShowDeleteSeduta(true);
		}
	}

	function showSegnaSeduta() {
		if (loggedUser.tipoUtente != "visualizzatore") {
			setShowSegnaSeduta(true);
			setNota("");
			setEffettuata(true);
		}
	}

	function segnaSeduta() {
		//Recupera tutte le informazioni della seduta
		let seduta = undefined;
		for (let i = 0; i < listaSedute.length; i++) {
			if (listaSedute[i].idSeduta == selectedSedutaId.current) {
				seduta = listaSedute[i];
				break;
			}
		}
		if (seduta != undefined) {
			let reqBody = {
				idSeduta: selectedSedutaId.current,
				effettuata: effettuata,
				nota: nota,
				nomePaziente: seduta.nomePaziente,
				cognomePaziente: seduta.cognomePaziente,
				nomeProfessionista: seduta.nomeProfessionista,
				cognomeProfessionista: seduta.cognomeProfessionista,
				nomeArea: seduta.nomeArea,
				nomeServizio: seduta.nomeServizio,
				descrizioneServizio: seduta.descrizioneServizio,
				nomeCertificazione: seduta.nomeCertificazione
			}
			setLoading(true); nodeReq.post(process.env.REACT_APP_API_URL + "/sedutaeffettuata", reqBody)
				.then(response => {
					if (response.status == 200) {
						if (response.data[0] != true) {
							genericAlert("La seduta è stata correttamente segnata, ma si è verificato un errore nell'invio dell'email di notifica.");
						} else {
							genericAlert("Seduta segnata correttamente");
						}
						loadSedute();
					} else {
						genericAlert("Impossibile segnare questa seduta a causa di un errore. Riprova più tardi");
					}
				})
				.catch(error => {
					if (error.response != undefined && error.response.status == 400) {
						genericAlert("Impossibile segnare una seduta programmata prima del " + dataFormatoItaliano(props.getFirstModifiableDay()) + ", una seduta futura, oppure una seduta già eliminata. Se il problema persiste, contatta il supporto.");
					} else {
						handleAPIError(error, "segnare la seduta come " + (effettuata ? "effettuata." : "non effettuata."));
					}
				})
				.finally(() => {
					setLoading(false);
					setShowSegnaSeduta(false);
				});
		}
	}
	//Visualizza i dettagli della seduta selezionata
	function loadSeduteFormValues() {
		if (selectedSedutaId.current != undefined) {
			let seduta = undefined;
			//prendi la seduta dalla lista
			for (let i = 0; i < listaSedute.length; i++) {
				if (listaSedute[i].idSeduta == selectedSedutaId.current) {
					seduta = listaSedute[i];
					break;
				}
			}
			//carica tutti i dati della seduta nel form dei dettagli seduta
			console.log("riga 555 Dashboard.js");
			let newSeduteFormValues = {
				apriNuovaModificaSeduta: true,
				openedFrom: "dashboard",
				selectedProfessionisti: [seduta.idProfessionista + ""],
				selectedPazienti: [seduta.idPaziente + ""],
				selectedServizi: [seduta.idServizio + ""],
				modalita: seduta.modalita,
				data: seduta.data,
				oraInizio: seduta.oraInizio,
				oraFine: seduta.oraFine,
				selectedCertificazioni: seduta.idCertificazione != null ? [seduta.idCertificazione + ""] : [],
				selectedAree: seduta.idArea != null ? [seduta.idArea + ""] : [],
				idSedutaToUpdate: selectedSedutaId.current,
				idPrenotazioneToUpdate: seduta.idPrenotazione
			};
			setSeduteFormValues(JSON.parse(JSON.stringify(newSeduteFormValues)));
			console.log("seduteFormValues settato (5)");
			setSeduteFormValuesBeforeEdits(JSON.parse(JSON.stringify(newSeduteFormValues)));
			//una volta modificato seduteFormValues, si aprirà la pagina "Dettagli Seduta"
		}
	}

	function apriModificaPrenotazione(idPrenotazione) {
		let prenotazione = undefined;
		//recupera tutte le informazioni della prenotazione
		for (let i = 0; i < listaPrenotazioni.length; i++) {
			if (listaPrenotazioni[i].idPrenotazione == idPrenotazione) {
				prenotazione = listaPrenotazioni[i];
				break;
			}
		}
		let prenotazioneToUpdateNew = {
			idPrenotazioneToUpdate: prenotazione.idPrenotazione,
			motivo: prenotazione.motivo,
			dataOraInizio: prenotazione.dataOraInizio,
			dataOraFine: prenotazione.dataOraFine,
			idArea: prenotazione.idArea
		};
		setPrenotazioneToUpdate(JSON.parse(JSON.stringify(prenotazioneToUpdateNew)));
		checkUserLoggedBeforeDoing(() => {
			setPrenotazioneFormValues(JSON.parse(JSON.stringify(prenotazioneToUpdateNew)));
			setPrenotazioneFormValuesBeforeEdits(JSON.parse(JSON.stringify(prenotazioneToUpdateNew)));
			setPageViewed(pages.Prenotazione);
		});
	}

	function vediSeduteMese() {
		fullCalendarDiv.current.scrollIntoView();
		calendarRef.current.getApi().changeView("dayGridMonth");
	}

	function vediSeduteSettimana() {
		fullCalendarDiv.current.scrollIntoView();
		calendarRef.current.getApi().changeView("timeGridWeek");
	}

	//fai la chiamata API che elimina la prenotazione e controlla la risposta del backend
	function deletePrenotazione() {
		//cancella la prenotazione con id idPrenotazione
		setLoading(true); nodeReq.delete(process.env.REACT_APP_API_URL + "/prenotazione?idPrenotazione=" + selectedPrenotazioneId.current)
			.then(response => {
				if (response.status == 200) {
					selectedPrenotazioneId.current = undefined;
					loadPrenotazioni();
					genericAlert("La prenotazione è stata eliminata correttamente.");
				} else {
					genericAlert("Impossibile eliminare la prenotazione a causa di un errore. Riprova più tardi.");
				}
			})
			.catch(error => {
				if (error.response != undefined && error.response.status == 400) {
					loadPrenotazioni();
					genericAlert("Impossibile eliminare questa prenotazione. Potrebbe essere stata già eliminata oppure associata a una seduta.");
				} else {
					handleAPIError(error, "eliminare la prenotazione");
				}
			})
			.finally(() => {
				setShowDeletePrenotazione(false);
				setLoading(false);
			});
	}
	//fai la chiamata API che elimina la seduta e controlla la risposta del backend
	function deleteSeduta(idSeduta) {
		//cancella la seduta con id props.idSeduta
		setLoading(true); nodeReq.delete(process.env.REACT_APP_API_URL + "/seduta?idSeduta=" + idSeduta)
			.then(response => {
				if (response.status == 200) {
					if (response.data[0] == true) {
						genericAlert("La seduta è stata eliminata correttamente.");
					} else {
						genericAlert("La seduta è stata eliminata ma non è stato possibile inviare l'email di notifica.");
					}
					loadSedute();
				}
			})
			.catch(error => {
				console.log(error);
				if (error.response != undefined && error.response.status == 400) {
					genericAlert("Impossibile eliminare questa seduta. Potrebbe essere stata eliminata, già fatturata oppure associata a una certificazione già conclusa.");
				} else {
					handleAPIError(error, "eliminare la seduta");
				}
			})
			.finally(() => {
				setLoading(false);
				setShowDeleteSeduta(false);
			});
	}

	return (
		<div style={{ display: pageViewed === pages.Dashboard ? 'flex' : 'none', height: "100%", flexDirection: "column" }}>
			<Heading iconName="calendar" title="Dashboard" />
			{loggedUser.tipoUtente == "professionista" &&
				<div className="card">
					<div className="card-header" onClick={() => { setPazientiCardExpanded(!pazientiCardExpanded); }}>
                    <div className="d-flex flex-row">
                        <div className="flex-grow-1">
                            <h5 className="card-title">I miei pazienti insolventi o bloccati</h5>
                        </div>
                        <div>
                            <FeatherIcon iconName={pazientiCardExpanded ? "chevron-up" : "chevron-down"} addedClassName="" />
                        </div>
                    </div>
                </div>
					<div className={pazientiCardExpanded == false ? "d-none" : "card-body"}>
						<SearchAndSelect elements={listaPazienti} notSelectableFunction={(paziente) => { return true; }} filterFunction={(paziente) => { return paziente.stato == "Insolvente" || paziente.stato == "Bloccato" }} allowMultiple={false} title={""} showDeselectButton={false} validSelection={true} setValidSelection={() => { }} required={false} selectedOptions={[]} setSelectedOptions={(elements) => { }} getElementText={(element) => { return element.cognome.toUpperCase() + " " + element.nome.toUpperCase() }} />
					</div>
				</div>
			}
			<div className="row">
				{(loggedUser.tipoUtente == "segretario" || loggedUser.tipoUtente == "visualizzatore") &&
					<>

						<div className="col-sm">
							<div className="card">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">Sedute prenotate questo mese</h5>
										</div>

										<div className="col-auto">
											<div className="stat text-primary">
												<FeatherIcon addedClassName="align-middle" iconName="clipboard" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3 text-primary" style={{ cursor: "pointer" }} onClick={vediSeduteMese}>{numSeduteMese != null ? numSeduteMese : "--"}</h1>
									<div className="mb-0" style={{ cursor: "pointer" }} onClick={vediSeduteSettimana}>
										<span className="text-primary"> <i className="mdi mdi-arrow-bottom-right"></i>{numSeduteSettimana != null ? numSeduteSettimana : "--"} </span>
										<span className="text-muted">questa settimana</span>
									</div>
								</div>
							</div>
							<div className="card">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">Utenti attivi al momento</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-primary">
												<FeatherIcon addedClassName="align-middle" iconName="users" />
											</div>
										</div>
									</div>
									<h3 className="mt-1 mb-3" style={{ cursor: "pointer" }} onClick={loggedUser.tipoUtente != "visualizzatore" ? () => { checkUserLoggedBeforeDoing(() => { setPageViewed(pages.Pazienti); cambiaUrl(pages.Pazienti); }); } : () => { }}><b className="text-primary">{numPazientiAttivi}</b> pazienti</h3>
									<h3 className="mt-1 mb-3" style={{ cursor: "pointer" }} onClick={loggedUser.tipoUtente != "visualizzatore" ? () => { checkUserLoggedBeforeDoing(() => { setPageViewed(pages.Professionisti); cambiaUrl(pages.Pazienti); }); } : () => { }}><b className="text-primary">{numProfessionistiAttivi}</b> professionisti</h3>
								</div>
							</div>
						</div>
					</>
				}
				{(loggedUser.tipoUtente == "segretario" || loggedUser.tipoUtente == "visualizzatore") &&
					<div className="col-sm">
						<div className="card">
							<div className="card-header" onClick={() => { setFiltroProfessionistiExpanded(!filtroProfessionistiExpanded); }}>
								<div className="d-flex flex-row">
									<div className="flex-grow-1">
										<h5 className="card-title">Filtra eventi per professionista</h5>
										{!filtroProfessionistiExpanded &&
											<>
												{nomeCognomeSelezionato != "Tutti i professionisti" && <h5 className="text-warning">Stai mostrando solo gli eventi del professionista <b>{nomeCognomeSelezionato}</b></h5>}
												{nomeCognomeSelezionato == "Tutti i professionisti" && <h5>Stai mostrando gli eventi di tutti i professionisti</h5>}
											</>
										}
									</div>
									<div>
										<FeatherIcon iconName={filtroProfessionistiExpanded ? "chevron-up" : "chevron-down"} addedClassName="" />
									</div>
								</div>
							</div>
							<div className="card-body" style={{ display: (filtroProfessionistiExpanded ? "block" : "none") }}>
								<SelettoreProfessionistaPaz paziente={false} show={true} allowAll={true} selected={selectedProfessionista} setSelected={setSelectedProfessionista} updated={professionistiUpdated} setUpdated={setProfessionistiUpdated} setNomeCognomeSelezionato={setNomeCognomeSelezionato} />
							</div>
						</div>
					</div>
				}
			</div>
			<div className="row mainElementHeightContainer d-flex flex-column" style={{ backgroundColor: "white", padding: "8px", borderRadius: "0.25rem", boxShadow: "0 0 .875rem 0 rgba(33, 37, 41, .05)", marginLeft: "0", marginRight: "0" }}>
				<Legenda colors={legendaColors} />
				<div>
					{currentViewType != "Aree" &&
						<>
							<label htmlFor="mostraSeduteOnlineCheck">Mostra le sedute online</label>
							<input type="checkbox" id="mostraSeduteOnlineCheck" className="form-check-input m-1" checked={mostraSeduteOnline} onChange={(event) => { setMostraSeduteOnline(event.target.checked); }} />
						</>
					}
					{currentViewType == "Aree" &&
						<label className="text-warning"><b>Le sedute online non sono disponibili nella vista Aree</b></label>
					}
				</div>
				<div>
					<label htmlFor="mostraDisponibilitaCheck">{loggedUser.tipoUtente == "professionista" ? "Mostra le mie disponibilità" : "Mostra le disponibilità dei professionisti"}</label>
					<input type="checkbox" id="mostraDisponibilitaCheck" className="form-check-input m-1" checked={mostraDisponibilitaProfessionisti} onChange={(event) => { setMostraDisponibilitaProfessionisti(event.target.checked); }} />
				</div>
				<div ref={fullCalendarDiv} className="flex-grow-1">
					{pageViewed === pages.Dashboard &&
						<Calendar showModalEventoSelezionato={showModalEventoSelezionato} openModalTimeout={openModalTimeout} handleCalendarSingleClick={handleCalendarSingleClick} calendarEvents={calendarAllEvents} loadSeduteFormValues={loadSeduteFormValues} calendarRef={calendarRef} calendarStartDate={calendarStartDate} setCalendarStartDate={setCalendarStartDate} calendarEndDate={calendarEndDate} setCalendarEndDate={setCalendarEndDate} apriModificaPrenotazione={apriModificaPrenotazione} selectedSedutaId={selectedSedutaId} selectedPrenotazioneId={selectedPrenotazioneId} currentViewType={currentViewType} setCurrentViewType={setCurrentViewType} />
					}
				</div>
			</div>
			<ModalPrompt showmodal={isShowSegnaSeduta} closemodal={closeSegnaSeduta} title={"Segna la seduta"} okButtonColor={"primary"} okButtonFunction={segnaSeduta} okButtonText={"Segna"} nomeProcedura={"l'aggiornamento della seduta"}>
				<label className="m-1"><b>Ricorda: </b>Puoi segnare una seduta solo se sul calendario è già iniziata.</label>
				<DettagliSeduta sedutaText={sedutaText} />
				<div className="form-group mt-3">
					<label htmlFor="notaSeduta">Nota <b>(obbligatoria per sedute non effettuate)</b> max {process.env.REACT_APP_NOTA_SEDUTA_MAX_CARATTERI} caratteri.</label>
					<input type="textarea" className="form-control" name="notaSeduta" id="notaSeduta" value={nota} onChange={(event) => { setNota(event.target.value); if (event.target.value.length == 0) { setEffettuata(true); } }} maxLength={process.env.REACT_APP_NOTA_SEDUTA_MAX_CARATTERI} />
					<div class="btn-group btn-group-lg m-1" role="group" aria-label="Large button group">
						<button type="button" className={"btn " + (effettuata ? "btn-success" : "btn-light")} onClick={() => { setEffettuata(true); }}>Effettuata</button>
						<button type="button" className={"btn " + (!effettuata ? "btn-warning" : "btn-light")} onClick={() => { setEffettuata(false); }} disabled={nota.length == 0}>Non effettuata</button>
					</div>
				</div>
			</ModalPrompt>
			<ModalEventoSelezionato showmodal={isShowModalEventoSelezionato} closemodal={closeModalEventoSelezionato} selectedSedutaId={selectedSedutaId} selectedPrenotazioneId={selectedPrenotazioneId} sedutaText={sedutaText} loadSeduteFormValues={loadSeduteFormValues} showDeleteSeduta={showDeleteSeduta} showDeletePrenotazione={loggedUser.tipoUtente != "visualizzatore" ? () => { setShowDeletePrenotazione(true); } : () => { }} showSegnaSeduta={showSegnaSeduta} prenotazioneText={prenotazioneText} apriModificaPrenotazione={apriModificaPrenotazione} />
			<ModalPrompt showmodal={isShowDeletePrenotazione} closemodal={() => { setShowDeletePrenotazione(false); }} okButtonFunction={deletePrenotazione} title={"Conferma eliminazione della prenotazione"} okButtonColor={"danger"} okButtonText={"Elimina"} nomeProcedura={"l'eliminazione della prenotazione"}>
				<p>Vuoi davvero eliminare questa prenotazione?</p>
				<DettagliPrenotazione prenotazioneText={prenotazioneText} />
			</ModalPrompt>
			<ModalPrompt showmodal={isShowDeleteSeduta} closemodal={closeDeleteSeduta} title={"Conferma eliminazione della seduta"} okButtonColor={"danger"} okButtonText={"Elimina seduta"} okButtonFunction={() => { deleteSeduta(selectedSedutaId.current); }} nomeProcedura={"l'eliminazione della seduta"}>
				<p>Vuoi davvero annullare questa seduta?</p>
				<DettagliSeduta sedutaText={sedutaText} />
			</ModalPrompt>
		</div>
	);
}

export default Dashboard;