import React from "react";
import { useRef, useState, useEffect } from 'react';
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import CustomView from "./CustomView";
import itLocale from '@fullcalendar/core/locales/it';
import { ContextGestionale } from "../App";
import { useContext } from "react";
import FeatherIcon from "./FeatherIcon";

function Calendar(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso } = useContext(ContextGestionale);
    const waitingForDoubleClick = useRef(false);
    const prova = useRef(false);

    //gestisci il click su una data libera
    const handleDateClick = (arg) => {
        if (loggedUser.tipoUtente != "visualizzatore") {
            if (!waitingForDoubleClick.current) {
                waitingForDoubleClick.current = true;
                setTimeout(() => { waitingForDoubleClick.current = false; }, parseInt(process.env.REACT_APP_DOUBLE_CLICK_TIME));
            } else {
                let dateArgs = arg.dateStr.split("T").length;
                if (dateArgs === 1) {
                    props.calendarRef.current.getApi().gotoDate(arg.dateStr);
                    props.calendarRef.current.getApi().changeView("timeGridDay");
                } else if (dateArgs > 1) {
                    console.log("Riga 30 Calendar.js");
                    let newSeduteFormValues = {
                        apriNuovaModificaSeduta: true,
                        selectedProfessionisti: [],
                        selectedPazienti: [],
                        selectedServizi: [],
                        modalita: 0,
                        data: arg.date.getFullYear() + "-" + String(arg.date.getMonth() + 1).padStart(2, '0') + "-" + String(arg.date.getDate()).padStart(2, '0'),
                        oraInizio: String(arg.date.getHours()).padStart(2, '0') + ":" + String(arg.date.getMinutes()).padStart(2, '0'),
                        oraFine: String(arg.date.getHours() + 1).padStart(2, '0') + ":" + String(arg.date.getMinutes()).padStart(2, '0'),
                        selectedCertificazioni: [],
                        selectedAree: [],
                        idSedutaToUpdate: undefined,
                        idPrenotazioneToUpdate: undefined
                    }

                    setSeduteFormValues(JSON.parse(JSON.stringify(newSeduteFormValues)));
                    setSeduteFormValuesBeforeEdits(JSON.parse(JSON.stringify(newSeduteFormValues)));
                }
                waitingForDoubleClick.current = false;
            }
        }
    }

    function handleCustomViewCellClick(event) {
        if (loggedUser.tipoUtente != "visualizzatore") {
            console.log("Singolo click custom view");
            let id = event.target.id.split("-");
            if (id[0] === "eventCell") {
                //gestisci il click sulla seduta o sulla prenotazione
                if (id[1] === "seduta") {
                    props.selectedSedutaId.current = id[2]; //SET
                    props.selectedPrenotazioneId.current = undefined;
                    props.handleCalendarSingleClick.current = true;
                    if (props.openModalTimeout.current == undefined) {
                        props.openModalTimeout.current = setTimeout(() => { props.showModalEventoSelezionato(); props.handleCalendarSingleClick.current = false; props.openModalTimeout.current = undefined; }, parseInt(process.env.REACT_APP_DOUBLE_CLICK_TIME));
                    }
                } else if (id[1] === "prenotazione" && loggedUser.tipoUtente === "segretario") {
                    //genericAlert("Gestisco il click sulla prenotazione con id " + id[2]);
                    props.selectedPrenotazioneId.current = parseInt(id[2]); //SET
                    props.selectedSedutaId.current = undefined;
                    props.handleCalendarSingleClick.current = true; //SET
                    if (props.openModalTimeout.current == undefined) {
                        props.openModalTimeout.current = setTimeout(() => { props.showModalEventoSelezionato(); props.handleCalendarSingleClick.current = false; props.openModalTimeout.current = undefined; }, parseInt(process.env.REACT_APP_DOUBLE_CLICK_TIME));
                    }
                }
            }
        }
    }

    function handleCustomViewCellDoubleClick(event) {
        if (loggedUser.tipoUtente != "visualizzatore") {
            let id = event.target.id.split("-");
            if (id[0] === "eventCell") {
                //gestisci il doppio click sulla seduta o sulla prenotazione
                if (id[1] === "seduta") {
                    props.loadSeduteFormValues();
                    props.handleCalendarSingleClick.current = false;  //SET
                    clearTimeout(props.openModalTimeout.current);
                    props.openModalTimeout.current = undefined;
                } else if (id[1] === "prenotazione" && loggedUser.tipoUtente === "segretario") {
                    props.selectedPrenotazioneId.current = parseInt(id[2]); //SET
                    props.selectedSedutaId.current = undefined;
                    props.apriModificaPrenotazione(parseInt(id[2]));
                    props.handleCalendarSingleClick.current = false; //SET
                    clearTimeout(props.openModalTimeout.current);
                    props.openModalTimeout.current = undefined;
                }
            } else if (id[0] === "clickableCell") {
                props.handleCalendarSingleClick.current = false; //SET
                clearTimeout(props.openModalTimeout.current);
                props.openModalTimeout.current = undefined;
                //gestisci il click sulla cella vuota
                let hour = (parseInt(id[1] / 2)) + 8;
                let nextHour = hour + 1;
                let minutes = (id[1] % 2) * 30;
                let roomIndex = parseInt(id[2]) + 1;
                console.log("Riga 101 Calendar.js");
                let newSeduteFormValues = {
                    apriNuovaModificaSeduta: true,
                    selectedProfessionisti: [],
                    selectedPazienti: [],
                    selectedServizi: [],
                    modalita: 0,
                    data: id[3] + "-" + id[4].padStart(2, '0') + "-" + id[5].padStart(2, '0'),
                    oraInizio: hour.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
                    oraFine: nextHour.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
                    selectedCertificazioni: [],
                    selectedAree: id[2] != "x" ? [roomIndex] : [],
                    idSedutaToUpdate: undefined,
                    idPrenotazioneToUpdate: undefined
                };
                setSeduteFormValues(JSON.parse(JSON.stringify(newSeduteFormValues))); //SET
                setSeduteFormValuesBeforeEdits(JSON.parse(JSON.stringify(newSeduteFormValues))); //SET
            }
        }
    }

    function addAllListeners() {
        if (loggedUser.tipoUtente != "visualizzatore") {
            //console.log("AddAlllisteners eseguita");
            //unico problemino possibile: gli elementi clickableCell potrebbero essere più di 0 ma non tutti. Ma, anche con timer di 1 ms questo problema non si vede
            if (document.getElementsByClassName("clickableCell").length === 0) {
                setTimeout(addAllListeners, 1);
            } else {
                let clickableCells = document.getElementsByClassName("clickableCell");
                //console.log("Chiamo addAllListeners su " + clickableCells.length + " elementi");
                for (let i = 0; i < clickableCells.length; i++) {
                    clickableCells[i].removeEventListener("click", handleCustomViewCellClick);
                    clickableCells[i].addEventListener("click", handleCustomViewCellClick);
                    clickableCells[i].removeEventListener("dblclick", handleCustomViewCellDoubleClick);
                    clickableCells[i].addEventListener("dblclick", handleCustomViewCellDoubleClick);
                }
                return;
            }
        }
    }

    function handleClick(arg) {
        if (loggedUser.tipoUtente != "visualizzatore") {
            let id = arg.event.id.split("-");
            if (!waitingForDoubleClick.current) {
                props.handleCalendarSingleClick.current = true;
                if (props.openModalTimeout.current == undefined) {
                    props.openModalTimeout.current = setTimeout(() => { props.showModalEventoSelezionato(); props.handleCalendarSingleClick.current = false; props.openModalTimeout.current = undefined; }, parseInt(process.env.REACT_APP_DOUBLE_CLICK_TIME));
                }
                if (id[0] === "seduta") {
                    props.selectedSedutaId.current = parseInt(id[1]);
                    props.selectedPrenotazioneId.current = undefined;
                } else if (id[0] === "prenotazione" && loggedUser.tipoUtente === "segretario") {
                    //genericAlert("Gestisco il click sulla prenotazione con id " + id[1]);
                    props.selectedPrenotazioneId.current = parseInt(id[1]);
                    props.selectedSedutaId.current = undefined;
                }
                waitingForDoubleClick.current = true;
                setTimeout(() => { waitingForDoubleClick.current = false; }, parseInt(process.env.REACT_APP_DOUBLE_CLICK_TIME));
            } else {
                props.handleCalendarSingleClick.current = false;
                clearTimeout(props.openModalTimeout.current);
                props.openModalTimeout.current = undefined;
                if (id[0] === "seduta") {
                    props.loadSeduteFormValues();
                } else if (id[0] === "prenotazione" && loggedUser.tipoUtente === "segretario") {
                    props.selectedPrenotazioneId.current = parseInt(id[1]);
                    props.selectedSedutaId.current = undefined;
                    props.apriModificaPrenotazione(parseInt(id[1]));
                }
                waitingForDoubleClick.current = false;
            }
        }
    }

    function handleDateChange(info) {
        if (!sameDate(props.calendarStartDate, info.start)) {
            props.setCalendarStartDate(info.start);
        }
        if (!sameDate(props.calendarEndDate, info.end)) {
            props.setCalendarEndDate(info.end);
        }
        if (info.view.type === "Aree") {
            addAllListeners();
        }
        if (info.view.type !== props.currentViewType) {
            //resetta la scrollYPosition della customview
            document.getElementById("customViewScrollX").innerHTML = "0";
            document.getElementById("customViewScrollY").innerHTML = "0";
        }
        props.setCurrentViewType(info.view.type);
    }

    function sameDate(date1, date2) {
        if (date1 === undefined) {
            return false;
        }
        return (
            date1.getUTCFullYear() === date2.getUTCFullYear() &&
            date1.getUTCMonth() === date2.getUTCMonth() &&
            date1.getUTCDate() === date2.getUTCDate());
    }

    return (
        <div className="h-100">
            <Fullcalendar
                ref={props.calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, CustomView]}
                initialView={"Aree"}
                headerToolbar={{
                    start: "today prev,next",
                    center: "title",
                    end: "dayGridMonth,timeGridWeek,timeGridDay,list,Aree",
                }}
                titleFormat={{
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                }}
                height={"100%"}
                weekends={true}
                events={props.calendarEvents}
                locales={[itLocale]}
                locale={"it"}
                allDaySlot={false}
                slotDuration={'00:30:00'}
                eventClick={
                    function (arg) {
                        handleClick(arg);
                    }
                }
                datesSet={(info) => { handleDateChange(info); }}
                dateClick={handleDateClick}
                /*eventContent={renderEventContent}*/
                businessHours={{
                    daysOfWeek: [1, 2, 3, 4, 5, 6],
                    startTime: '08:00', // a start time (10am in this example)
                    endTime: '23:00', // an end time (6pm in this example)
                }}
                hiddenDays={[0]}
                slotMinTime={"08:00"}
                slotMaxTime={"23:00"}
                expandRows={true}
                showNonCurrentDates={false}
            />
        </div>
    );
}


export default Calendar;