import React from "react";
import { useRef } from 'react';
import Fullcalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import itLocale from '@fullcalendar/core/locales/it';
import { ContextGestionale } from '../App';
import { useContext } from 'react';
import CustomView from "./CustomView";
function DisponibilitaCalendar(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    const calendarRef = useRef(null);
    function handleDateChange(info) {
        if (!sameDate(props.calendarStartDate, info.start)) {
            props.setCalendarStartDate(info.start);
        }
        if (!sameDate(props.calendarEndDate, info.end)) {
            props.setCalendarEndDate(info.end);
        }
    }

    function sameDate(date1, date2) {
        if (date1 === undefined) {
            return false;
        }
        return (
            date1.getUTCFullYear() === date2.getUTCFullYear() &&
            date1.getUTCMonth() === date2.getUTCMonth() &&
            date1.getUTCDate() === date2.getUTCDate());
    }

    return (
        <div className="flex-grow-1">
            <Fullcalendar
                ref={calendarRef}
                plugins={props.openedFromDisponibilitaProfessionista == true ? [timeGridPlugin, CustomView] : [timeGridPlugin]}
                initialView= {props.openedFromDisponibilitaProfessionista == true ? "Aree" : "timeGridWeek"}
                headerToolbar={{
                    start: "today prev,next",
                    center: "title",
                    end: props.openedFromDisponibilitaProfessionista == true ? "Aree,timeGridWeek" : "timeGridWeek",
                }}
                height={"100%"}
                weekends={true}
                events={props.calendarEvents}
                initialDate={props.initialDate}
                locales={[itLocale]}
                locale={"it"}
                allDaySlot={false}
                businessHours={{
                    daysOfWeek: [1, 2, 3, 4, 5, 6],
                    startTime: '08:00', // a start time (10am in this example)
                    endTime: '23:00', // an end time (6pm in this example)
                }}
                hiddenDays={[0]}
                slotMinTime={"08:00"}
                slotMaxTime={"23:00"}
                slotDuration={"00:30:00"}
                expandRows={true}
                datesSet={(info) => { handleDateChange(info); }}
                titleFormat={{
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                }}
            />
        </div>
    );
}

export default DisponibilitaCalendar;