function TableArrows(props) {
    return <>
        {props.type === 0 &&
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrows_empty align-middle align-middle"><polygon points="6 14 18 14 12 22 6 14"></polygon><polygon points="6 10 18 10 12 2 6 10"></polygon></svg>
        }
        {props.type === 1 &&
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrows_filled_up align-middle align-middle"><polygon points="6 14 18 14 12 22 6 14"></polygon><polygon points="6 10 18 10 12 2 6 10" fill="#999"></polygon></svg>
        }
        {props.type === 2 &&
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrows_filled_down align-middle align-middle"><polygon points="6 14 18 14 12 22 6 14" fill="#999"></polygon><polygon points="6 10 18 10 12 2 6 10"></polygon></svg>
        }
    </>;
}
export default TableArrows;