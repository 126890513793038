import { useState } from "react";
import { useEffect } from "react";
import FeatherIcon from "./FeatherIcon";
import { useContext } from "react";
import { ContextGestionale } from "../App";
import APICallButton from "./APICallButton";

function EmailComunicazioni(props) {
    //TODO: qui da qualche parte devi segnare che ci sono modifiche non salvate
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    //ESEMPIO DI FORMATO
    const emailComunicazioniTest = {
        "email1@x.it": [true, true, false, false, false, false, false, false],
        "email2@x.it": [true, true, true, false, true, false, true, false]
    };

    const selectOptionHeight = 25;
    const selectSize = 6;
    //contiene tutti gli indirizzi email per le comunicazioni inseriti per l'utente e i tipi di notifiche che vuole ricevere come array boolean
    const [emailSelezionata, setEmailSelezionata] = useState(null);
    const [nuovaEmailComunicazioni, setNuovaEmailComunicazioni] = useState("");
    const [nuovaEmailGiaInserita, setNuovaEmailGiaInserita] = useState(false);
    const [nuovaEmailOk, setNuovaEmailOk] = useState(true);
    const tipologieNotifica = ["Notifiche generiche di Esagramma", "Aggiornamenti delle mie informazioni", "Notifiche sulle mie sedute", "Notifiche sulle prenotazioni generiche", "Notifiche sulle mie certificazioni", "Notifiche sui miei pazienti/professionisti assegnati", "Aggiornamenti delle mie disponibilità", "Notifiche di fatturazione", "Newsletter"];
    const numeroNotificheObbligatorie = 2;

    const updateEmailComunicazioniCheck = (event) => {
        let id = parseInt(event.target.name.split("-")[1]);
        if (id >= numeroNotificheObbligatorie) {
            let emailComunicazioniTemp = JSON.parse(JSON.stringify(props.emailComunicazioni));
            emailComunicazioniTemp[emailSelezionata][id] = event.target.checked;
            props.setEmailComunicazioni(emailComunicazioniTemp);
        }
    }

    function reset() {
        setEmailSelezionata(null);
        setNuovaEmailComunicazioni("");
        setNuovaEmailGiaInserita(false);
        setNuovaEmailOk(true);
        props.setEmailComunicazioni({});
    }

    useEffect(() => {
        //TODO: bloccare questo useEffect se la modalità manutenzione è attiva
        if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
            console.log("useEffect: montaggio EmailComunicazioni.js");
          }
        reset();
    }, []);

    useEffect(() => {
        if (props.emailComunicazioniResetted === false) {
            if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
                console.log("useEffect: reset EmailComunicazioni.js");
              }
            reset();
            props.setEmailComunicazioniResetted(true);
        }
    }, [props.emailComunicazioniResetted]);

    //salva solo le email di comunicazioni se sono nella pagina mieInfo
    function salvaEmailComunicazioni() {
        if (Object.keys(props.emailComunicazioni).length < 1) {
            genericAlert("Inserire almeno un indirizzo email per le comunicazioni");
        } else {
            setLoading(true);
            nodeReq.post(process.env.REACT_APP_API_URL + '/abilitazioniNotifiche', { emailComunicazioni: props.emailComunicazioni })
                .then(response => {
                    if (response.status === 201) {
                        genericAlert("Gli indirizzi email di comunicazione sono stati aggiornati correttamente.");
                        props.setEmailComunicazioniBeforeEdits(JSON.parse(JSON.stringify(props.emailComunicazioni)));
                    } else {
                        genericAlert("Impossibile aggiornare gli indirizzi email di comunicazione. Riprova più tardi.");
                    }
                })
                .catch(error => {
                    handleAPIError(error, "aggiornare gli indirizzi email di comunicazione");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    function addEmail() {
        //controlla se l'email non è stata già inserita
        if (emailSelezionata === nuovaEmailComunicazioni) {
            return;
        }
        if (!props.emailFormatOk(nuovaEmailComunicazioni)) {
            setNuovaEmailOk(false);
            return;
        }
        let keys = Object.keys(props.emailComunicazioni);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] !== emailSelezionata && nuovaEmailComunicazioni === keys[i]) {
                setNuovaEmailGiaInserita(true);
                return;
            }
        }
        //da qui siamo sicuri che l'email è univoca e valida
        let emailComunicazioniTemp = JSON.parse(JSON.stringify(props.emailComunicazioni));
        //aggiungi
        if (!props.paziente) {
            emailComunicazioniTemp[nuovaEmailComunicazioni] = [true, true, true, false, false, true, true, true, true];
        } else {
            emailComunicazioniTemp[nuovaEmailComunicazioni] = [true, true, true, false, false, true, false, true, true];
        }
        props.setEmailComunicazioni(emailComunicazioniTemp);
        setEmailSelezionata(nuovaEmailComunicazioni);
        setNuovaEmailComunicazioni("");
    }

    function deleteEmailSelezionata() {
        let emailComunicazioniTemp = JSON.parse(JSON.stringify(props.emailComunicazioni));
        delete emailComunicazioniTemp[emailSelezionata];
        setEmailSelezionata(null);
        setNuovaEmailComunicazioni("nuova@email.it");
        props.setEmailComunicazioni(emailComunicazioniTemp);
    }

    return (
        <div className="col-md">
            <div className="card">
                <div className="card-header" onClick={() => { props.setCardExpanded(!props.cardExpanded); }}>
                    <div className="d-flex flex-row">
                        <div className="flex-grow-1">
                            <h5 className="card-title">Notifiche</h5>
                        </div>
                        <div>
                            <FeatherIcon iconName={props.cardExpanded ? "chevron-up" : "chevron-down"} addedClassName="" />
                        </div>
                    </div>
                </div>
                {props.cardExpanded &&
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md p-1">
                                <h6 className="card-title">Email inserite</h6>
                                <div className="d-flex flex-sm-row">
                                    <div className="flex-grow-1 p-1">
                                        <input type="text" className={"form-control " + (nuovaEmailGiaInserita || !nuovaEmailOk ? "is-invalid" : "")} value={nuovaEmailComunicazioni} onChange={(event) => { setNuovaEmailComunicazioni(event.target.value); setNuovaEmailOk(true); setNuovaEmailGiaInserita(false); }}></input>
                                        <div className="invalid-feedback">
                                            {nuovaEmailGiaInserita && "Indirizzo email già inserito"}
                                            {!nuovaEmailOk && "Indirizzo email non valido"}
                                        </div>
                                    </div>
                                    <div className="p-1">
                                        <button className="btn btn-primary" onClick={addEmail}>Aggiungi</button>
                                    </div>
                                </div>
                                <div className="selectOptionsContainer" style={{ height: (selectOptionHeight * selectSize + (selectSize - 1) + 16) + "px" }}>
                                    <div>
                                        {Object.keys(props.emailComunicazioni).map((email, index) => {
                                            if (email === emailSelezionata) {
                                                return (<div className="customSelectOption" key={index} onClick={() => { setEmailSelezionata(email); }} style={{ height: selectOptionHeight + "px", backgroundColor: process.env.REACT_APP_SEARCH_AND_SELECT_SELECTION_COLOR, color: "white" }}>{email}</div>);
                                            } else {
                                                return (<div className="customSelectOption" key={index} onClick={() => { setEmailSelezionata(email); }} style={{ height: selectOptionHeight + "px", backgroundColor: "rgba(0,0,0,0)", color: "#444444" }}>{email}</div>);
                                            }
                                        })}
                                    </div>
                                </div>
                                {emailSelezionata !== null && <button className="btn btn-danger m-1" onClick={deleteEmailSelezionata}><FeatherIcon addedClassName="align-middle" iconName="trash" /></button>}
                            </div>

                            <div className="overflow-y-scroll col-md p-1">
                                {emailSelezionata !== null && props.emailComunicazioni[emailSelezionata] !== undefined &&
                                    <>
                                        <h6 className="card-title">Notifiche attive</h6>
                                        <div className="d-flex flex-sm-row light-table-border">
                                            <div>
                                                <table>
                                                    {props.emailComunicazioni[emailSelezionata].map((tipologia, index) => {
                                                        if (index >= numeroNotificheObbligatorie && index !== 3 && index !== 4 && !(props.paziente && index === 6)) { //salta le notifiche sulle prenotazioni generiche, sulle certificazioni e le tipologie di notifica obbligatorie
                                                            return <tr key={index}><td className="p-1 light-table-border text-nowrap"><input type="checkbox" className="form-check-input m-1" checked={tipologia} disabled={index < numeroNotificheObbligatorie} onChange={updateEmailComunicazioniCheck} name={"tipologia-" + index} /></td></tr>
                                                        }
                                                    })}
                                                </table>
                                            </div>
                                            <div className="overflow-x-scroll flex-grow-1">
                                                <table className="min-width-full">
                                                    {tipologieNotifica.map((tipologia, index) => {
                                                        if (index >= numeroNotificheObbligatorie && index !== 3 && index !== 4 && !(props.paziente && index === 6)) { //salta le notifiche sulle prenotazioni generiche, sulle certificazioni e le tipologie di notifica obbligatorie
                                                            return <tr key={index}><td className="p-1 light-table-border text-nowrap">{tipologia}</td></tr>
                                                        }
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                        </div>
                        {props.paginaMieInfo &&
                            <APICallButton buttonText="Salva" buttonClassName="btn btn-primary m-1" buttonOnClick={salvaEmailComunicazioni} nomeProcedura={"l'aggiornamento degli indirizzi email per le comunicazioni"}/>
                        }
                    </div>}
            </div>
        </div>
    );
}

export default EmailComunicazioni;