import { useState, useEffect } from "react";
import { useContext } from "react";
import { ContextGestionale } from "../App";
import FeatherIcon from "./FeatherIcon";
function PazientiNoFIC(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso } = useContext(ContextGestionale);
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            {props.pazientiNoFIC == null &&
                <p>Caricamento in corso dei pazienti non associati a FattureInCloud</p>
            }
            {props.pazientiNoFIC != null && props.pazientiNoFIC.length != undefined && props.pazientiNoFIC.length == 0 &&
                <p className="text-success">Tutti i pazienti sono associati a FattureInCloud</p>
            }
            {props.pazientiNoFIC != null && props.pazientiNoFIC.length != undefined && props.pazientiNoFIC.length > 0 &&
                <div className="bg-warning p-2 mt-2" style={{ borderRadius: "0.25rem" }}>
                    <p>Ricorda che i seguenti pazienti non sono ancora stati associati a FattureInCloud:</p>
                    {expanded &&
                        <ul>
                            {props.pazientiNoFIC.map((paziente, index) => (
                                <li>{paziente.paziente}</li>
                            ))}
                        </ul>
                    }
                    <button className="btn btn-warning" onClick={() => {setExpanded(!expanded);}}><FeatherIcon iconName={expanded ? "chevron-up" : "chevron-down"}/>&nbsp;{expanded ? "Comprimi" : "Espandi"}</button>
                </div>
            }
            {props.pazientiNoFIC == undefined &&
                <p className="text-danger">Errore nel caricamento dei pazienti non associati a FattureInCloud</p>
            }
        </>
    );
}

export default PazientiNoFIC;