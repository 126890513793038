import React from 'react';
import Login from './pages/Login';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useBeforeunload } from 'react-beforeunload';
//import { drawCharts } from './functions/charts';
import Grafici from './components/sections/Grafici';
import ListaProfessionistaPaz from './components/sections/ListaProfessionistaPaz';
import ProfessionistaPazCreateUpdate from './components/sections/ProfessionistaPazCreateUpdate';
import DisponibilitaProfessionista from './components/sections/DisponibilitaProfessionista';
import Dashboard from './components/sections/Dashboard';
import Contabilita from './components/sections/Contabilita';
import SedutaCreateUpdate from './components/sections/SedutaCreateUpdate';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Sedute from './components/sections/Sedute';
import BlankOrNotFound from './components/sections/BlankOrNotFound';
import axios from 'axios';
import GenericAlertModal from './components/modals/GenericAlertModal';
import ImpostazionePassword from './pages/ImpostazionePassword';
import SessioneScadutaModal from './components/modals/SessioneScadutaModal';
import ServizioCreateUpdate from './components/sections/ServizioCreateUpdate';
import Servizi from './components/sections/Servizi';
import Certificazioni from './components/sections/Certificazioni';
import CertificazioneCreateUpdate from './components/sections/CertificazioneCreateUpdate';
import PrenotazioneCreateUpdate from './components/sections/PrenotazioneCreateUpdate';
import ModalPrompt from './components/modals/ModalPrompt';
import Statistiche from './components/sections/Statistiche';

export const ContextGestionale = React.createContext();

function App() {
  const pages = {
    NuovoPaziente: 'nuovoPaziente',
    NuovoProfessionista: 'nuovoProfessionista',
    Paziente: 'paziente', //fa parte di Pazienti. Non si puo aprire da popstate
    Professionista: 'professionista', //fa parte di Professionisti. Non si puo aprire da popstate
    Pazienti: 'pazienti',
    Professionisti: 'professionisti',
    Grafici: 'grafici',
    Dashboard: 'dashboard',
    Login: 'login', //non si puo aprire da popstate
    DisponibilitaProfessionista: 'disponibilitaProfessionista',
    Contabilita: 'contabilita',
    NuovaSeduta: 'nuovaSeduta',
    Seduta: "seduta", //fa parte o di Tabella sedute o di Dashboard. Non si puo aprire direttamente da popstate
    Sedute: "tabellaSedute",
    MieInfo: "mieInfo",
    BlankOrNotFound: "blankOrNotFound",
    ImpostazionePassword: "impostazionePassword",
    NuovoServizio: 'nuovoServizio',
    Servizio: 'servizio',
    Servizi: 'servizi',
    Certificazioni: 'certificazioni',
    NuovaCertificazione: 'nuovaCertificazione',
    Certificazione: 'certificazione',
    NuovaPrenotazione: 'nuovaPrenotazione',
    Prenotazione: 'prenotazione',
    Statistiche: 'statistiche'
  };

  //restituisce l'importo con due numeri dopo la virgola e i punti che separano le migliaia (un punto ogni 3 cifre come in chi vuol essere miliardario)
  function importoConPuntoEVirgola(importo) {
    //l'importo deve essere in formato "123456789,05" 
    if (typeof importo == "string") {
      importo = importo.replace("€", "").replace(" ", "");
      if (importo.includes(".") || importo.includes(",")) {
        //è un importo decimale
        let decimal = importo.substring(importo.length - 2, importo.length);
        let intpart = importo.substring(0, importo.length - 3);
        return "€ " + interoPuntato(intpart) + "," + decimal;
      } else {
        //è un importo intero
        return "€ " + interoPuntato(importo) + ",00";
      }
    } else if (typeof importo == "number") {
      let value = parseInt(importo * 100.0);
      let decimal = value % 100;
      let intpart = (value - decimal) / 100;
      return "€ " + interoPuntato(intpart + "") + "," + decimal.toString().padStart(2, "0");
    } else {
      return "[valore non valido]";
    }
  }

  function interoPuntato(intpart) {
    let toReturn = "";
    for (let i = intpart.length - 1; i >= 0; i--) {
      toReturn = (intpart[i] + "") + toReturn;
      if ((intpart.length - 1 - i) % 4 == 2) {
        toReturn = "." + toReturn;
      }
    }
    if (toReturn[0] == ".") {
      toReturn = toReturn.substring(1);
    }
    return toReturn;
  }

  //definisci qui un oggetto che dice quali pagine il tipo di utente loggato può "forzare" dal browser
  const pagesAllowed = {
    "segretario": ["pazienti", "nuovoPaziente", "nuovoProfessionista", "pazienti", "professionisti", "grafici", "dashboard", "login", "disponibilitaProfessionista", "contabilita", "nuovaSeduta", "tabellaSedute", "mieInfo", "blankOrNotFound", "impostazionePassword", "nuovoServizio", "servizi", "certificazioni", "nuovaCertificazione", "nuovaPrenotazione", "statistiche"],
    "professionista": ["pazienti", "professionisti", "grafici", "dashboard", "login", "contabilita", "nuovaSeduta", "tabellaSedute", "mieInfo", "blankOrNotFound", "impostazionePassword", "certificazioni"],
    "visualizzatore": ["dashboard"]
  };

  /*useEffect(() => {
    drawCharts();
  }, []);
  */
  //variabili e funzioni messe nel context
  const nodeReq = axios.create({
    withCredentials: true
  });
  const initialProfessionistaPazFormValues = {
    nome: "",
    nomeOld: undefined,
    cognome: "",
    cognomeOld: undefined,
    email: "",
    cf: "",
    idOld: undefined, //questo serve per capire quale professionista/paziente andare ad aggiornare
    attivo: true,
    modalita: 0,
    indirizzo: "",
    comune: "",
    cap: "",
    provincia: "",
    paese: "Italia",
    consensoSts: true,
    altriNumeriTelefono: [],
    genere: "M",
  }

  const initialProfessionistaPazFormOk = {
    nome: true,
    cognome: true,
    email: true,
    cf: true,
    indirizzo: true,
    comune: true,
    cap: true,
    provincia: true
  }

  const professionistaPazFormRegexs = {
    nome: /^\s*([a-zàèéìòù]+)??(?:\s+)?([a-zàèéìòù]+)??\s*(['-])?\s*([a-zàèéìòù]+)\s*$/ui,
    cognome: /^\s*([a-zàèéìòù]+)??(?:\s+)?([a-zàèéìòù]+)??\s*(['-])?\s*([a-zàèéìòù]+)\s*$/ui,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    cf: /*/^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i*/ /^.*$/,
    indirizzo: /^.*$/,
    comune: /^.*$/,
    cap: /^.*$/,
    provincia: /^.*$/
  }

  const professionistaPazFormObbligatori = {
    nome: true,
    cognome: true,
    email: true,
    cf: false,
    indirizzo: false,
    comune: false,
    cap: false,
    provincia: false
  }

  const nuovaSedutaFormValues = {
    apriNuovaModificaSeduta: true,
    selectedProfessionisti: [],
    selectedPazienti: [],
    selectedServizi: [],
    modalita: 0,
    data: oggiFormatoAmericano(),
    oraInizio: "08:00",
    oraFine: "09:00",
    selectedCertificazioni: [],
    selectedAree: [],
    idSedutaToUpdate: undefined,
    idPrenotazioneToUpdate: undefined
  }

  const initialSedutaFormValues = {
    apriNuovaModificaSeduta: false,
    selectedProfessionisti: [],
    selectedPazienti: [],
    selectedServizi: [],
    modalita: 0,
    data: oggiFormatoAmericano(),
    oraInizio: "08:00",
    oraFine: "09:00",
    selectedCertificazioni: [],
    selectedAree: [],
    idSedutaToUpdate: undefined,
    idPrenotazioneToUpdate: undefined
  }

  const nuovaCertificazioneFormValues = {
    idCertificazioneToUpdate: undefined,
    selectedServizio: [],
    selectedPaziente: [],
    selectedProfessionista: [],
    prezzo: "",
    costoProfessionista: "",
    nota: "",
    dataAcquisto: "",
    dataConclusione: "",
    professionista: undefined,
    paziente: undefined,
    servizio: undefined,
  }

  const initialServizioFormValues = {
    idServizioToUpdate: undefined,
    nomeServizio: "",
    descrizioneServizio: "",
    tipoServizio: 0,
    tariffaDefault: "",
    costoProfessionista: ""
  }

  const nuovaPrenotazioneFormValues = {
    idPrenotazioneToUpdate: undefined,
    motivo: "",
    dataOraInizio: oggiFormatoAmericano() + " --:--",
    dataOraFine: oggiFormatoAmericano() + " --:--",
    idArea: 1
  }

  const initialPageChangeTimerValue = 30;
  const passwordSpecialChars = "{[(.,!@#$%^&*_=+-)]}";
  const mainContainer = useRef(null);
  const mainElement = useRef(null);
  const [actionRequested, setActionRequested] = useState([]);
  const [sessionContinueFunction, setSessionContinueFunction] = useState([]);
  const [isShowUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [sessionAlertShown, setSessionAlertShown] = useState(false);
  const [isShowGenericAlert, setShowGenericAlert] = useState(false);
  const [genericAlertMessage, setGenericAlertMessage] = useState("");
  const [urlChangeFromBrowserHandled, setUrlChangeFromBrowserHandled] = useState(false);
  const [listaPazienti, setListaPazienti] = useState([]);
  const [listaProfessionisti, setListaProfessionisti] = useState([]);
  const [listaServizi, setListaServizi] = useState([]);
  const [pageViewed, setPageViewed] = useState(pages.Login);
  const [professionistaPazFormValues, setProfessionistaPazFormValues] = useState(initialProfessionistaPazFormValues);
  const [professionistaPazFormOk, setProfessionistaPazFormOk] = useState(initialProfessionistaPazFormOk);
  const [loggedUser, setLoggedUser] = useState({});
  const [seduteFormValues, setSeduteFormValues] = useState(initialSedutaFormValues);
  const [seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits] = useState(JSON.parse(JSON.stringify(initialSedutaFormValues)));
  const [pageChangeTimer, setPageChangeTimer] = useState(0);
  const [listaSedute, setListaSedute] = useState([]);
  const [passwordUnsaved, setPasswordUnsaved] = useState(false);
  const [listaDispPerTabella, setListaDispPerTabella] = useState([]);
  const [listaDispPerTabellaBeforeEdits, setListaDispPerTabellaBeforeEdits] = useState([]);
  const [emailComunicazioni, setEmailComunicazioni] = useState({});
  const [emailComunicazioniBeforeEdits, setEmailComunicazioniBeforeEdits] = useState({});
  const [listaPazientiBeforeEdits, setListaPazientiBeforeEdits] = useState([]);
  const [listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits] = useState([]);
  const [listaServiziBeforeEdits, setListaServiziBeforeEdits] = useState([]);
  const [professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits] = useState({});
  const [servizioFormValues, setServizioFormValues] = useState(initialServizioFormValues);
  const [servizioFormValuesBeforeEdits, setServizioFormValuesBeforeEdits] = useState({});
  const [certificazioneFormValues, setCertificazioneFormValues] = useState(nuovaCertificazioneFormValues);
  const [certificazioneFormValuesBeforeEdits, setCertificazioneFormValuesBeforeEdits] = useState({});
  const [prenotazioneFormValues, setPrenotazioneFormValues] = useState(nuovaPrenotazioneFormValues);
  const [prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [abilitaCertificazioni, setAbilitaCertificazioni] = useState(undefined);
  const [aree, setAree] = useState([]);
  const [nomeProceduraInCorso, setNomeProceduraInCorso] = useState("");

  let runningAPICallsNumber = 0;
  const resizeTimeout = useRef(undefined);

  function setLoading(newAPICall) {
    if (newAPICall == true) {
      runningAPICallsNumber += 1;
    } else {
      runningAPICallsNumber -= 1;
    }
    if (runningAPICallsNumber == 0) {
      setIsProcessing(false);
    } else {
      setIsProcessing(true);
    }
  }

  function oggiFormatoAmericano() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // I mesi vanno da 0 a 11, quindi aggiungiamo 1
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  //gestisce l'errore in una chiamata API fatta con axios
  //ERROR: l'oggetto error passato da axios,
  //STR: descrive quello che non è stato possibile fare all'infinito. ES: "creare un nuovo paziente" (NOTA: STR non deve contenere il punto)
  function handleAPIError(error, str) {
    if (error.response !== undefined && error.response.status === 401) {
      if (error.response.data === "notLoggedIn") {
        if (!sessionAlertShown) {
          setSessionAlertShown(true);

          setSessionContinueFunction([]);
          //alert("La sessione è scaduta. Eseguire di nuovo l'accesso.");
          //handleLogout(true, false);
        }
      } else if (error.response.data === "notAuthorized") {
        genericAlert("Non hai l'autorizzazione per " + str + ".");
      }
    } else {
      genericAlert("Impossibile " + str + " al momento a causa di un errore imprevisto. Ci scusiamo per il disagio.");
    }
  }

  function jsonUguali(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  //controlla che non ci siano modifiche non salvate. Restituisce false se ce ne sono
  function unsavedChangesNotDetected() {
    //Questa funzione ora è finita
    if (pageViewed === pages.MieInfo && passwordUnsaved) {
      console.log("Modifica non salvata 1");
      return false;
    }
    if ((pageViewed === pages.NuovaPrenotazione || pageViewed === pages.Prenotazione) && !jsonUguali(prenotazioneFormValues, prenotazioneFormValuesBeforeEdits)) {
      console.log("Modifica non salvata 2");
      return false;
    }
    if ((pageViewed === pages.NuovaSeduta || pageViewed === pages.Seduta) && !jsonUguali(seduteFormValues, seduteFormValuesBeforeEdits)) {
      console.log("Modifica non salvata 3");
      return false;
    }
    if ((pageViewed === pages.NuovoPaziente || pageViewed === pages.Paziente || pageViewed === pages.NuovoProfessionista || pageViewed === pages.Professionista) && (!jsonUguali(professionistaPazFormValues, professionistaPazFormValuesBeforeEdits) || !jsonUguali(emailComunicazioni, emailComunicazioniBeforeEdits))) {
      console.log("Modifica non salvata 4");
      console.log(professionistaPazFormValues);
      console.log(professionistaPazFormValuesBeforeEdits);
      return false;
    }
    if ((pageViewed === pages.NuovoPaziente || pageViewed === pages.Paziente) && !listeUguali(listaProfessionisti, listaProfessionistiBeforeEdits)) {
      console.log("Modifica non salvata 5");
      return false;
    }
    if ((pageViewed === pages.NuovoProfessionista || pageViewed === pages.Professionista) && (!listeUguali(listaPazienti, listaPazientiBeforeEdits) || !listeUguali(listaServizi, listaServiziBeforeEdits))) {
      console.log("Modifica non salvata 6");
      return false;
    }
    if (pageViewed === pages.DisponibilitaProfessionista && !listeUguali(listaDispPerTabella, listaDispPerTabellaBeforeEdits)) {
      console.log("Modifica non salvata 7");
      return false;
    }
    if ((pageViewed === pages.NuovoServizio || pageViewed === pages.Servizio) && (!jsonUguali(servizioFormValues, servizioFormValuesBeforeEdits) || !listeUguali(listaProfessionisti, listaProfessionistiBeforeEdits))) {
      console.log("Modifica non salvata 8");
      return false;
    }
    if (pageViewed === pages.NuovaCertificazione && !jsonUguali(certificazioneFormValues, certificazioneFormValuesBeforeEdits)) {
      console.log("Modifica non salvata 9");
      return false;
    }
    if (pageViewed === pages.Certificazione && certificazioneFormValues.dataConclusione != certificazioneFormValuesBeforeEdits.dataConclusione) {
      console.log("Modifica non salvata 10");
      return false;
    }
    return true;
  }
  //se non ci sono modifiche salvate, esegui immediatamente ACTION, altrimenti salva ACTION come callback da eseguire se l'utente vuole abbandonare le modifiche
  function checkUnsavedBeforeDoing(action) {
    if (unsavedChangesNotDetected()) {
      action();
    } else {
      setActionRequested([() => { action(); }]);
      showUnsavedChangesModal();
    }
  }

  //butta via le modifiche non salvate
  function discardChanges() {
  }

  function listeUguali(lista1, lista2) {
    if (lista1.length !== lista2.length) {
      return false;
    }
    for (let i = 0; i < lista1.length; i++) {
      if (JSON.stringify(lista1[i]) !== JSON.stringify(lista2[i])) {
        //almeno un elemento è cambiato
        return false;
      }
    }
    return true;
  }

  //prima di eseguire la funzione ACTION, controlla che l'utente sia loggato. Se lo è, esegui normalmente ACTION. Altrimenti, passi ACTION come callback al modal di sessione scaduta
  function checkUserLoggedBeforeDoing(action) {
    setLoading(true);
    nodeReq.get(process.env.REACT_APP_API_URL + '/isUserLoggedIn')
      .then(response => {
        if (response.status === 200) {
          const data = response.data;
          if (data.isUserLoggedIn === true) {
            //l'utente è loggato
            if (JSON.stringify(loggedUser) !== JSON.stringify(data.loggedUser)) {
              //se l'utente loggato risulta cambiato, cambia loggedUser!
              setLoggedUser(data.loggedUser);
            }
            action();
          } else {
            if (pageViewed !== pages.Login && pageViewed !== pages.ImpostazionePassword) {
              setSessionAlertShown(true);
              setSessionContinueFunction([() => { action(); }]);
            }
          }
        } else {
          if (pageViewed !== pages.Login && pageViewed !== pages.ImpostazionePassword) {
            setSessionAlertShown(true);
            setSessionContinueFunction([() => { action(); }]);
          }
        }
      })
      .catch(error => {
        if (pageViewed !== pages.Login && pageViewed !== pages.ImpostazionePassword) {
          setSessionAlertShown(true);
          setSessionContinueFunction([() => { action(); }]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  //mostra una semplice modal con questo messaggio
  function genericAlert(message) {
    setGenericAlertMessage(message);
    setShowGenericAlert(true);
  }

  //chiudi la modal di messaggio generico
  function closeGenericAlert() {
    setShowGenericAlert(false);
  }

  //converte una data dal formato "2024-12-31" al formato "31/12/2024"
  function dataFormatoItaliano(data) {
    return data.substring(8, 10) + "/" + data.substring(5, 7) + "/" + data.substring(0, 4);
  }
  //----------------------------------------------

  //quando la pagina viene cambiata, scrolla tutto verso l'alto
  useEffect(() => {
    if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
      console.log("useEffect: pagina cambiata. scroll verso l'alto");
    }
    mainContainer.current.scrollIntoView();
  }, [pageViewed]);

  function handleUnload(event) {
    event.preventDefault();
    console.log("beforeUnload! " + pageViewed);
    const message = ""; event.returnValue = ""; return message;
  }

  useBeforeunload(!unsavedChangesNotDetected() ? handleUnload : null);

  function updateFullCalendarAndWrapperHeight() {
    updateFullCalendarHeight();
    updateWrapperHeight();
  }

  useEffect(() => {
    //TODO: bloccare questo useEffect se la modalità manutenzione è attiva
    if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
      console.log("useEffect: caricamento gestionale");
    }
    //Se provi a scrollare su un input type number, viene blurrato (viene tolto il focus)
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
    //fai una prima impostazione dell'altezza delle fullcalendar
    firstFullCalendarHeightSet();
    // Aggiungi il listener per l'evento di ridimensionamento della finestra
    window.addEventListener('resize', updateFullCalendarAndWrapperHeight);
    //window.addEventListener('resize', () => { clearTimeout(resizeTimeout.current); resizeTimeout.current = setTimeout(updateFullCalendarAndWrapperHeight, 500); });
    updateWrapperHeight();
    // Rimuovi il listener quando il componente viene smontato
    return () => {
      window.removeEventListener('resize', updateFullCalendarHeight);
    };
  }, []);

  //effettua il logout.
  //se goToImpostazionePasswordPage è true, vuol dire che è stato premuto il tasto di Logout dalla pagina di impostazione della password
  function handleLogout(afterSessionExpired, goToImpostazionePasswordPage) {
    setLoading(true);
    nodeReq.delete(process.env.REACT_APP_API_URL + '/logout')
      .then(response => {
        if (response.status === 200) {
          if (!afterSessionExpired) {
            checkUnsavedBeforeDoing(() => { setPageViewed(goToImpostazionePasswordPage ? pages.ImpostazionePassword : pages.Login); window.location.reload(); });
          } else {
            window.location.reload();
          }
        } else {
          genericAlert("Impossibile effettuare il logout!");
        }
      })
      .catch(error => {
        handleAPIError(error, "effettuare il logout");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Imposta l'altezza del wrapper
  function updateWrapperHeight() {
    if (window.innerHeight !== wrapperHeight) {
      setWrapperHeight(window.innerHeight);
    }
  }

  //imposta l'altezza di tutti i fullcalendar in base all'altezza del mainElement
  function updateFullCalendarHeight() {
    /*
    let currentHeight = mainElement.current.offsetHeight;
    if (currentHeight !== fullCalendarHeightNum) {
      setFullCalendarHeightNum(currentHeight - 12 - 12 - 8 - 8);
      setFullCalendarHeight((currentHeight - 12 - 12 - 8 - 8) + "px");
    }
    */
  }

  //aspetta che la navbar sia completamente caricata prima di impostare l'altezza di tutti i fullcalendar
  function firstFullCalendarHeightSet() {
    if (document.getElementById("navBarEsagramma").offsetHeight > 0) {
      updateFullCalendarHeight();
      return;
    }
    setTimeout(firstFullCalendarHeightSet, 100);
  }

  //quando vengono impostati i parametri per una nuova seduta o per una seduta da modificare, apri la pagina "Nuova seduta" o "Dettagli seduta"
  useEffect(() => {
    if (seduteFormValues.apriNuovaModificaSeduta == true) {
      setSeduteFormValues(prevState => ({
        ...prevState,
        apriNuovaModificaSeduta: false
      }));
      setSeduteFormValuesBeforeEdits(prevState => ({
        ...prevState,
        apriNuovaModificaSeduta: false
      }));
      checkUserLoggedBeforeDoing(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
          console.log("useEffect: seduteFormValues è cambiato. apro SedutaCreateUpdate");
        }
        if (seduteFormValues.idSedutaToUpdate === undefined && seduteFormValues.idPrenotazioneToUpdate === undefined) {
          //vai su NuovaSeduta
          setPageViewed(pages.NuovaSeduta);
          console.log("Nuova seduta! (1) perchè sedute form values vale...");
          console.log(seduteFormValues);
          if (window.location.pathname.substring(1) !== pages.NuovaSeduta) {
            cambiaUrl(pages.NuovaSeduta);
          }
        } else {
          //vai su Seduta
          setPageViewed(pages.Seduta);
          console.log("Modifica seduta! (1)");
        }
      });
    } else {
      console.log("seduteFormValues è cambiato, ma non apro sedutaCreateUpdate!");
      console.log(seduteFormValues);
    }
  }, [seduteFormValues]);

  function caricaAree() {
    if (aree.length == 0) {
      setLoading(true);
      nodeReq.get(process.env.REACT_APP_API_URL + "/aree")
        .then(response => {
          if (response.status == 200) {
            setAree(response.data.dbdata);
          } else {
            genericAlert("Impossibile caricare le aree al momento. Riprova più tardi.");
            setAree([]);
          }
        })
        .catch(error => {
          handleAPIError(error, "caricare le aree");
          setAree([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function showUnsavedChangesModal() {
    setShowUnsavedChangesModal(true);
  }

  function closeUnsavedChangesModal() {
    setShowUnsavedChangesModal(false);
  }

  //all'apertura del gestionale, aggiungi al listener sull'evento popstate la funzione che fa partire (o ripartire) il timer per il cambio pagina.
  //il timer per il cambio pagina serve perchè, con la navigazione tramite frecce, si scatenano due eventi di popstate consecutivi, ma ne va gestito uno soltanto
  useEffect(() => {
    //TODO: bloccare questo useEffect se la modalità manutenzione è attiva
    if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
      console.log("useEffect: caricamento gestionale 2");
    }
    window.addEventListener('popstate', resetPageChangeTimer);
    return () => {
      window.removeEventListener('popstate', resetPageChangeTimer);
    };
  }, []);

  //cambia l'url senza scatenare l'evento popState
  const cambiaUrl = (newUrl) => {
    window.history.pushState({}, '', newUrl);
  };

  //quando cambia l'url direttamente dal browser o dalla navigazione con le frecce, resetta il timer di cambio pagina
  function resetPageChangeTimer() {
    setPageChangeTimer(initialPageChangeTimerValue);
  }

  //Se il timer per il cambio pagina è scaduto, imposta il cambio dell'url nel browser come non gestito
  useEffect(() => {
    if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
      console.log("useEffect: gestione cambio pagina dal browser 1");
    }
    if (pageChangeTimer > 0) {
      setTimeout(() => { setPageChangeTimer(pageChangeTimer - 10); }, 10);
    } else {
      setUrlChangeFromBrowserHandled(false);
    }
  }, [pageChangeTimer]);

  //quando il cambio di url nel browser risulta ancora non gestito, chiama la funzione che lo gestisce
  useEffect(() => {
    if (Object.keys(loggedUser).length > 0 && !urlChangeFromBrowserHandled) {
      if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
        console.log("useEffect: gestione cambio pagina dal browser 2");
      }
      setUrlChangeFromBrowserHandled(true);
      handleUrlChangeFromBrowser();
    }
  }, [loggedUser, urlChangeFromBrowserHandled]);

  //quando l'utente si logga con successo, carica le aree e determina se può gestire le certificazioni
  useEffect(() => {
    if (Object.keys(loggedUser).length > 0) {
      caricaAree();
      abilitaDisabilitaCertificazioni();
    }
  }, [loggedUser]);

  //determina se il professionista può o meno gestire le certificazioni. Imposta correttamente lo useState abilitaCertificazioni
  function abilitaDisabilitaCertificazioni() {
    nodeReq.get(process.env.REACT_APP_API_URL + "/certificazioniAbilitate")
      .then(response => {
        if (response.status == 200) {
          setAbilitaCertificazioni(response.data.abilitaCertificazioni);
        } else {
          genericAlert("Impossibile abilitare l'utilizzo delle certificazioni al momento a causa di un errore. Riprova più tardi.");
          setAbilitaCertificazioni(false);
        }
      })
      .catch(error => {
        handleAPIError(error, "abilitare l'utilizzo delle certificazioni");
        setAbilitaCertificazioni(false);
      });
  }

  //Questa funzione apre la pagina richiesta nell'url. Viene chiamata quando si scrive l'url nel browser o quando si naviga con le frecce
  function handleUrlChangeFromBrowser() {
    let url = window.location.pathname.substring(1);
    if (url.substring(0, 7) !== "signup-") {
      checkUserLoggedBeforeDoing(() => {
        checkUnsavedBeforeDoing(() => {
          let pageNames = Object.keys(pages);
          let found = false;
          for (let i = 0; i < pageNames.length; i++) {
            if (pages[pageNames[i]] === url && loggedUser.tipoUtente in pagesAllowed && pagesAllowed[loggedUser.tipoUtente].includes(pages[pageNames[i]])) {
              if (pages[pageNames[i]] === pages.NuovaSeduta) {
                setSeduteFormValues(nuovaSedutaFormValues);
                setSeduteFormValuesBeforeEdits(JSON.parse(JSON.stringify(nuovaSedutaFormValues)));
              } else if (pages[pageNames[i]] === pages.NuovoProfessionista) {
                setProfessionistaPazFormValues(initialProfessionistaPazFormValues);
                setPageViewed(pages.NuovoProfessionista);
              } else if (pages[pageNames[i]] === pages.NuovoPaziente) {
                setProfessionistaPazFormValues(initialProfessionistaPazFormValues);
                setPageViewed(pages.NuovoPaziente);
              } else if (pages[pageNames[i]] === pages.NuovaPrenotazione) {
                setPrenotazioneFormValues(nuovaPrenotazioneFormValues);
                setPrenotazioneFormValuesBeforeEdits(JSON.parse(JSON.stringify(nuovaPrenotazioneFormValues)));
                setPageViewed(pages.NuovaPrenotazione);
              } else if (pages[pageNames[i]] === pages.MieInfo) {
                goToMieInfo();
              } else if (pages[pageNames[i]] === pages.NuovoServizio) {
                setServizioFormValues(initialServizioFormValues);
                setServizioFormValuesBeforeEdits(JSON.parse(JSON.stringify(initialServizioFormValues)));
                setPageViewed(pages.NuovoServizio);
              } else if (pages[pageNames[i]] === pages.NuovaCertificazione) {
                setCertificazioneFormValues(nuovaCertificazioneFormValues);
                setCertificazioneFormValuesBeforeEdits(JSON.parse(JSON.stringify(nuovaCertificazioneFormValues)));
                setPageViewed(pages.NuovaCertificazione);
              } else {
                setPageViewed(pages[pageNames[i]]);
              }
              found = true;
              break;
            }
          }
          if (!found && url !== "") {
            setPageViewed(pages.BlankOrNotFound);
          } else if (url === "") {
            setPageViewed(pages.Dashboard);
          }
        });
      });
    }
  }

  //apre la pagina delle mie informazioni
  function goToMieInfo() {
    checkUserLoggedBeforeDoing(() => {
      setLoading(true);
      nodeReq.get(process.env.REACT_APP_API_URL + '/utenteLogged')
        .then(response => {
          setProfessionistaPazFormValues(prevState => ({
            ...prevState,
            nome: response.data.dbdata[0].nome,
            nomeOld: response.data.dbdata[0].nome,
            cognome: response.data.dbdata[0].cognome,
            cognomeOld: response.data.dbdata[0].cognome,
            pwd: "",
            confermaPwd: "",
            pwdOld: "",
            email: response.data.dbdata[0].email,
            cf: response.data.dbdata[0].cf,
            idOld: response.data.dbdata[0].idUtente, //questo serve per capire quale professionista/paziente andare ad aggiornare
            id: response.data.dbdata[0].idUtente,
            attivo: response.data.dbdata[0].attivo,
            modalita: response.data.dbdata[0].modalita,
            indirizzo: response.data.dbdata[0].indirizzo,
            comune: response.data.dbdata[0].comune,
            provincia: response.data.dbdata[0].provincia,
            cap: response.data.dbdata[0].cap,
            paese: response.data.dbdata[0].paese,
            altriNumeriTelefono: response.data.altriNumeriTelefono,
            consensoSts: response.data.dbdata[0].consensoSts,
            genere: response.data.dbdata[0].genere
          }));
          setPageViewed(pages.MieInfo);
          if (window.location.pathname.substring(1) !== pages.MieInfo) {
            cambiaUrl(pages.MieInfo);
          }
        })
        .catch(error => {
          handleAPIError(error, "caricare le tue informazioni");
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }

  /*
  //avvisa l'utente prima di lasciare la pagina
  window.addEventListener('beforeunload', function (event) {
    console.log("beforeUnload! " + pageViewed);
    //console.log(event);
    // Verifica se ci sono modifiche non salvate
    if (!unsavedChangesNotDetected()) {
      const message = 'Alcune modifiche in questa pagina non sono state salvate. Vuoi abbandonarle?';
      event.returnValue = message;
      return message;
    }
  });
  */

  //funzione che chiude il session alert
  function closeSessionAlert() {
    setSessionAlertShown(false);
  }

  function getFirstModifiableDay() {
    let today = new Date();
    let year = today.getUTCFullYear();
    let month = today.getUTCMonth() + 1;
    let day = today.getUTCDate();
    //prendi il primo giorno del mese precedente
    if (process.env.REACT_APP_GIORNO_SCATTO >= day) {
      month--;
      if (month === 0) {
        year--;
        month = 12;
      }
    }
    return year + "-" + month.toString().padStart(2, "0") + "-01";
  }


  //controlla se la password è abbastanza sicura
  function passwordFormatIsOk(pwd) {
    if (pwd.length < 12) {
      return false;
    }
    if (pwd.includes(loggedUser.nome) || pwd.includes(loggedUser.cognome)) {
      return false;
    }
    let specialChars = passwordSpecialChars;
    let digits = "0123456789";
    let lowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
    let upperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let specialCharFound = false;
    let lowerCaseLetterFound = false;
    let upperCaseLetterFound = false;
    let digitFound = false;
    for (let i = 0; i < pwd.length; i++) {
      if (specialChars.includes(pwd[i])) { specialCharFound = true }
      if (digits.includes(pwd[i])) { digitFound = true }
      if (lowerCaseLetters.includes(pwd[i])) { lowerCaseLetterFound = true }
      if (upperCaseLetters.includes(pwd[i])) { upperCaseLetterFound = true }
    }
    return specialCharFound && digitFound && lowerCaseLetterFound && upperCaseLetterFound;
  }

  return (
    <>
      <ContextGestionale.Provider value={{ nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso }}>
        <div className='wrapper' style={{ height: wrapperHeight + "px" }}>
          <Sidebar setServizioFormValues={setServizioFormValues} initialServizioFormValues={initialServizioFormValues} setServizioFormValuesBeforeEdits={setServizioFormValuesBeforeEdits} setCertificazioneFormValues={setCertificazioneFormValues} setCertificazioneFormValuesBeforeEdits={setCertificazioneFormValuesBeforeEdits} nuovaCertificazioneFormValues={nuovaCertificazioneFormValues} nuovaPrenotazioneFormValues={nuovaPrenotazioneFormValues} abilitaCertificazioni={abilitaCertificazioni} />
          <div className="main">
            <Navbar handleLogout={handleLogout} />
            <main ref={mainElement} id="mainElement" className='content' style={{ overflowY: "scroll" }}>
              <div id="mainContainer" ref={mainContainer} className='container-fluid top-padding-custom' >
                {process.env.REACT_APP_MOSTRA_GRAFICI === true &&
                  <Grafici />
                }
                <ListaProfessionistaPaz />
                <ProfessionistaPazCreateUpdate passwordFormatIsOk={passwordFormatIsOk} passwordSpecialChars={passwordSpecialChars} professionistaPazFormOk={professionistaPazFormOk} setProfessionistaPazFormOk={setProfessionistaPazFormOk} initialProfessionistaPazFormOk={initialProfessionistaPazFormOk} professionistaPazFormRegexs={professionistaPazFormRegexs} professionistaPazFormObbligatori={professionistaPazFormObbligatori} emailComunicazioni={emailComunicazioni} setEmailComunicazioni={setEmailComunicazioni} emailComunicazioniBeforeEdits={emailComunicazioniBeforeEdits} setEmailComunicazioniBeforeEdits={setEmailComunicazioniBeforeEdits} />
                <DisponibilitaProfessionista listaDispPerTabella={listaDispPerTabella} setListaDispPerTabella={setListaDispPerTabella} listaDispPerTabellaBeforeEdits={listaDispPerTabellaBeforeEdits} setListaDispPerTabellaBeforeEdits={setListaDispPerTabellaBeforeEdits} />
                <Dashboard getFirstModifiableDay={getFirstModifiableDay} />
                <Sedute />
                <Contabilita abilitaCertificazioni={abilitaCertificazioni} runningAPICallsNumber={runningAPICallsNumber}/>
                <SedutaCreateUpdate getFirstModifiableDay={getFirstModifiableDay} />
                <PrenotazioneCreateUpdate nuovaPrenotazioneFormValues={nuovaPrenotazioneFormValues} />
                <ServizioCreateUpdate servizioFormValues={servizioFormValues} setServizioFormValues={setServizioFormValues} servizioFormValuesBeforeEdits={servizioFormValuesBeforeEdits} setServizioFormValuesBeforeEdits={setServizioFormValuesBeforeEdits} initialServizioFormValues={initialServizioFormValues} />
                <Servizi setServizioFormValues={setServizioFormValues} setServizioFormValuesBeforeEdits={setServizioFormValuesBeforeEdits} />
                <Certificazioni setCertificazioneFormValues={setCertificazioneFormValues} setCertificazioneFormValuesBeforeEdits={setCertificazioneFormValuesBeforeEdits} abilitaCertificazioni={abilitaCertificazioni} />
                <CertificazioneCreateUpdate certificazioneFormValues={certificazioneFormValues} setCertificazioneFormValues={setCertificazioneFormValues} nuovaCertificazioneFormValues={nuovaCertificazioneFormValues} setCertificazioneFormValuesBeforeEdits={setCertificazioneFormValuesBeforeEdits} />
                <Statistiche />
                <BlankOrNotFound />
              </div>
            </main>
          </div>
          <Login cambiaUrl={cambiaUrl} />
          <ImpostazionePassword handleLogout={handleLogout} passwordFormatIsOk={passwordFormatIsOk} passwordSpecialChars={passwordSpecialChars} />
        </div>
        <p id="areePerCustomView" style={{ display: "none" }}>
          {aree.map((area) => {
            return area.nome + ";"
          })}
        </p>
        <ModalPrompt showmodal={isShowUnsavedChangesModal} closemodal={closeUnsavedChangesModal} okButtonFunction={() => { actionRequested[0](); closeUnsavedChangesModal(); discardChanges(); }} okButtonColor={"danger"} okButtonText={"Abbandona"} title={"Modifiche non salvate"} nomeProcedura={"l'abbandono delle modifiche non salvate"}>
          <p>Alcune modifiche in questa pagina non sono state salvate. Vuoi abbandonarle?</p>
        </ModalPrompt>
        <GenericAlertModal showmodal={isShowGenericAlert} closemodal={closeGenericAlert} message={genericAlertMessage} />
        <SessioneScadutaModal showmodal={sessionAlertShown} closemodal={closeSessionAlert} handleLogout={handleLogout} sessionContinueFunction={sessionContinueFunction} />
        {/*<LoadingScreen />*/}
      </ContextGestionale.Provider>
    </>
  );
}

export default App