import { ContextGestionale } from '../App';
import { useContext } from 'react';

function DettagliSeduta(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    const modalitaSeduta = ["In presenza", "Ibrida (solo il paziente online)", "Online"];
    return (
        <>
            {Object.keys(props.sedutaText).length > 0 && <>
                <div style={{border: "1px solid var(--fc-event-border-color)", borderRadius: "3px", padding: "0.5rem"}}>
                    <p className="dettagliSedutaP"><b>Professionista: </b>{props.sedutaText.cognomeProfessionista.toUpperCase()} {props.sedutaText.nomeProfessionista.toUpperCase()}</p>
                    <p className="dettagliSedutaP"><b>Paziente: </b>{props.sedutaText.cognomePaziente.toUpperCase()} {props.sedutaText.nomePaziente.toUpperCase()}</p>
                    <p className="dettagliSedutaP"><b>Servizio: </b>{props.sedutaText.nomeServizio} {props.sedutaText.descrizioneServizio.length > 0 && "(" + props.sedutaText.descrizioneServizio + ")"}</p>
                    {props.sedutaText.certificazione !== null && <p className="dettagliSedutaP"><b>Certificazione: </b>{props.sedutaText.certificazione}</p>}
                    {props.sedutaText.modalita != 2 && <p className="dettagliSedutaP"><b>Dove: </b>{props.sedutaText.dove}</p>}
                    <p className="dettagliSedutaP"><b>Modalità: </b>{modalitaSeduta[props.sedutaText.modalita]}</p>
                    <p className="dettagliSedutaP"><b>Data: </b>{dataFormatoItaliano(props.sedutaText.data)}</p>
                    <p className="dettagliSedutaP"><b>Orario: </b>{props.sedutaText.orario}</p>
                    {props.sedutaText.effettuata === null && <p className="dettagliSedutaP"><b className="text-danger">Da segnare</b></p>}
                    {props.sedutaText.effettuata !== null && <p className="dettagliSedutaP"><b>Segnata come: </b>{props.sedutaText.effettuata == true ? "Effettuata" : "NON effettuata"}</p>} {props.sedutaText.nota != undefined && props.sedutaText.nota != null && props.sedutaText.nota != "" ? "(" + props.sedutaText.nota+")" : ""}
                </div>
            </>
            }
            {Object.keys(props.sedutaText).length === 0 && <p className="dettagliSedutaP"><b>Nessuna seduta selezionata</b></p>}
        </>
    );
}

export default DettagliSeduta;