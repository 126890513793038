import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { ContextGestionale } from '../../App';
import { useContext } from 'react';


function ModalDialog(props) {
  const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
  const [tariffa, setTariffa] = useState("");
  const [costoProfessionista, setCostoProfessionista] = useState("");
  const [validTariffa, setValidTariffa] = useState(false);
  const [tipoServizio, setTipoServizio] = useState(0);
  const [nomeServizio, setNomeServizio] = useState("Non definito");
  const [nomeProfessionista, setNomeProfessionista] = useState("");
  const [cognomeProfessionista, setCognomeProfessionista] = useState("");
  const openedFromProfessionistaPazCreateUpdate = props.tariffaDefault == undefined && props.costoProfessionista == undefined && props.tipoServizio == undefined && props.nomeServizio == undefined;
  //all'apertura del modal, carica tariffa e costo professionista!
  useEffect(() => {
    if (props.showmodal) {
      if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
        console.log("useEffect: modal del prezzo del servizio aperto");
      }
      if (openedFromProfessionistaPazCreateUpdate) {
        //Se nelle props non ci sono, prendili dalla lista dei servizi
        for (let i = 0; i < props.listaServizi.length; i++) {
          if (props.listaServizi[i].idServizio == props.selectedServizi[0]) {
            setTipoServizio(props.listaServizi[i].tipoServizio);
            setNomeServizio(props.listaServizi[i].nomeServizio);
            setTariffa(props.listaServizi[i].tariffa);
            setCostoProfessionista(props.listaServizi[i].costoProfessionistaAssegnato);
            break;
          }
        }
      } else {
        setTariffa(props.tariffaDefault);
        setCostoProfessionista(props.costoProfessionista);
        for (let i = 0; i < props.listaServizi.length; i++) {
          if (props.listaServizi[i].idUtente == props.selectedServizi[0] && props.listaServizi[i].tariffa > 0 && props.listaServizi[i].costoProfessionistaAssegnato > 0) {
            setTariffa(props.listaServizi[i].tariffa);
            setCostoProfessionista(props.listaServizi[i].costoProfessionistaAssegnato);
            break;
          }
        }
        //Se nelle props ci sono, allora prendili dalle props!
        setTipoServizio(props.tipoServizio);
        setNomeServizio(props.nomeServizio);
      }
      if(!openedFromProfessionistaPazCreateUpdate){
        for (let i = 0; i < props.listaServizi.length; i++) {
          if (props.listaServizi[i].idUtente == props.selectedServizi[0]) {
            setNomeProfessionista(props.listaServizi[i].nomeProfessionista);
            setCognomeProfessionista(props.listaServizi[i].cognomeProfessionista);
            break;
          }
        }
      }
    }
  }, [props.showmodal]);

  function impostaValoriDefault() {
    if (openedFromProfessionistaPazCreateUpdate) {
      //Se nelle props non ci sono, prendili dalla lista dei servizi
      for (let i = 0; i < props.listaServizi.length; i++) {
        if (props.listaServizi[i].idServizio == props.selectedServizi[0]) {
          setCostoProfessionista(props.listaServizi[i].costoProfessionista);
          setTariffa(props.listaServizi[i].tariffaDefault);
          break;
        }
      }
    } else {
      //Se nelle props ci sono, allora prendili dalle props!
      setCostoProfessionista(props.costoProfessionista);
      setTariffa(props.tariffaDefault);
    }
  }
  //controlla che tariffa e costoProfessionista inseriti siano validi!
  useEffect(() => {
    if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
      console.log("useEffect: controllo validità modal prezzo servizio");
    }
    if (tariffa != undefined && tariffa != null && !isNaN(parseFloat(tariffa)) && parseFloat(tariffa) > 0 && costoProfessionista != null && costoProfessionista != undefined && !isNaN(parseFloat(costoProfessionista)) && parseFloat(costoProfessionista) > 0) {
      setValidTariffa(true);
    } else {
      setValidTariffa(false);
    }
  }, [tariffa, costoProfessionista]);

  function saveTariffa() {
    let idToLookFor = openedFromProfessionistaPazCreateUpdate ? "idServizio" : "idUtente";
    if (validTariffa) {
      for (let i = 0; i < props.listaServizi.length; i++) {
        if (props.listaServizi[i][idToLookFor] == props.selectedServizi[0]) {
          let listaServiziTemp = props.listaServizi.slice();
          listaServiziTemp[i].tariffa = parseFloat(tariffa);
          listaServiziTemp[i].costoProfessionistaAssegnato = parseFloat(costoProfessionista);
          props.setListaServizi(listaServiziTemp);
        }
      }
    }
  }
  return (
    <div>
      <Modal show={props.showmodal}>
        <Modal.Header closeButton onClick={props.closemodal}>
          <Modal.Title><h3 className="card-title">Imposta tariffa</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <label className="form-label fw-bold">Servizio: {nomeServizio}</label>
          </section>
          <section>
            <label className="form-label fw-bold">Professionista: {openedFromProfessionistaPazCreateUpdate ? props.nome : nomeProfessionista} {openedFromProfessionistaPazCreateUpdate ? props.cognome : cognomeProfessionista}</label>
          </section>
          <label htmlFor="inputTariffa">Tariffa</label>
          <div className="input-group" id="inputTariffa">
            <div className="input-group-prepend">
              <div className="input-group-text">{tipoServizio ? "€" : "€/ora"}</div>
            </div>
            <input type="number" min="0.00" step="0.01" name="nome" className="form-control mb-1" value={tariffa} onChange={(event) => { setTariffa(event.target.value); }} />
          </div>
          <label htmlFor="inputCostoProfessionista">Costo professionista</label>
          <div className="input-group" id="inputCostoProfessionista">
            <div className="input-group-prepend">
              <div className="input-group-text">{tipoServizio ? "€" : "€/ora"}</div>
            </div>
            <input type="number" min="0.00" step="0.01" name="nome" className="form-control mb-1" value={costoProfessionista} onChange={(event) => { setCostoProfessionista(event.target.value); }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={impostaValoriDefault}>
            Valori di default
          </Button>
          <Button variant="primary" onClick={() => { saveTariffa(); props.closemodal(); }} disabled={!validTariffa}>
            Ok
          </Button>
          <Button variant="primary" onClick={props.closemodal}>
            Annulla
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default ModalDialog;