function Legenda(props) {
    return (<>
        <div className="legenda-grande" style={{ margin: "8px" }}>
            <div style={{ display: "inline-block", padding: "4px", margin: "4px" }}><b>Legenda:</b></div>
            {props.colors.map((color, index) => {
                return <div key={index} style={{ display: "inline-block", backgroundColor: color.bg, color: color.txtCol, padding: "4px", margin: "4px", borderRadius: "3px", border: "1px solid var(--fc-event-border-color)" }}>{color.text}</div>;
            })}
        </div>
        <div className="legenda-piccola">
            <div class="tooltip">
                <div style={{ display: "inline-block", padding: "8px", margin: "4px", backgroundColor: "#aaaaaa", color: "white", borderRadius: "3px"}}><b>Mostra legenda</b></div>
                <div class="tooltiptext-legenda">
                    <div>
                        {props.colors.map((color, index) => {
                            return <div key={index} style={{ display: "inline-block", backgroundColor: color.bg, color: color.txtCol, padding: "4px", margin: "4px", borderRadius: "3px", border: "1px solid var(--fc-event-border-color)" }}>{color.text}</div>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    </>);
}
export default Legenda;