import React from 'react';
import FeatherIcon from './FeatherIcon';

function LogoutButton(props) {
  return (
    <div>
      <a className="dropdown-item" onClick={() => { props.handleLogout(false, false);}}><FeatherIcon addedClassName="align-middle me-1" iconName="log-out" /> Logout</a>
    </div>
  );
}
export default LogoutButton;