import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { ContextGestionale } from '../../App';
import { useContext } from 'react';


/*
Modal dove vengono mostrate le sovrapposizioni
*/
function SovrapposizioniDisponibilitaModal(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    const nomeGiorno = [null, "Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];
    return (
        <div>
            <Modal show={props.showmodal}>
                <Modal.Header closeButton onClick={props.closemodal}>
                    <Modal.Title><h3 className="card-title">Sovrapposizioni con altre disponibilità</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Impossibile aggiornare le disponibilità per questo professionista. Sono state individuate sovrapposizioni con alcune disponibilità inserite per altri professionisti:</p>
                    {props.sovrapposizioni && props.sovrapposizioni.length > 0 && (
                        <ul>
                            {props.sovrapposizioni.map((item, index) => (
                                <li key={index}>La disponibilità inserita per questo professionista il <b>{nomeGiorno[item.giorno]}</b> dalle <b>{item.oraInizio1}</b> alle <b>{item.oraFine1}</b> nell'area <b>{props.rooms[item.idArea-1]}</b>, è in sovrapposizione con la disponibilità inserita per <b>{item.nomeProfessionista} {item.cognomeProfessionista}</b>, nello stesso giorno e nella stessa area dalle <b>{item.oraInizio2}</b> alle <b>{item.oraFine2}</b></li>
                            ))}
                        </ul>
                    )}
                </Modal.Body>
            </Modal>

        </div>
    )
}
export default SovrapposizioniDisponibilitaModal;