import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import VediDisponibilitaProfessionista from '../VediDisponibilitaProfessionista';

import Legenda from '../Legenda';
import { ContextGestionale } from '../../App';
import { useContext, useEffect, useState } from 'react';
/*
Modal dove vengono mostrate le disponibilita dello professionista
*/
function ModalDisponibilita(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso } = useContext(ContextGestionale);
    const legendaColors = [
        { bg: process.env.REACT_APP_SEDUTE_PROGRAMMATE_BGCOLOR, text: "Seduta settimanale programmata", txtCol: "white" },
        { bg: process.env.REACT_APP_SEDUTA_PRENOTATA_PAGINA_DISPONIBILITA_BGCOLOR, text: "Seduta prenotata", txtCol: "white" },
        { bg: process.env.REACT_APP_DISPONIBILITA_PROFESSIONISTA_BGCOLOR, text: "Disponibilità professionista", txtCol: "black" },
        { bg: process.env.REACT_APP_PERIODO_SELEZIONATO_BGCOLOR, text: "Periodo selezionato", txtCol: "white" }
    ];
    const legendaColorsModificaSeduta = [
        { bg: process.env.REACT_APP_SEDUTE_PROGRAMMATE_BGCOLOR, text: "Seduta settimanale programmata", txtCol: "white" },
        { bg: process.env.REACT_APP_SEDUTA_PRENOTATA_PAGINA_DISPONIBILITA_BGCOLOR, text: "Seduta prenotata", txtCol: "white" },
        { bg: process.env.REACT_APP_SEDUTA_PRENOTATA_IN_MODIFICA_BGCOLOR, text: "Seduta prenotata che stai modificando", txtCol: "white" },
        { bg: process.env.REACT_APP_DISPONIBILITA_PROFESSIONISTA_BGCOLOR, text: "Disponibilità professionista", txtCol: "black" },
        { bg: process.env.REACT_APP_PERIODO_SELEZIONATO_BGCOLOR, text: "Periodo selezionato", txtCol: "white" }
    ];
    const [nomeCognomeProfessionista, setNomeCognomeProfessionista] = useState("");

    useEffect(() => {
        if (props.showmodal) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: modal delle disponibilità aperto");
            }
            let selected = parseInt(props.selectedProfessionisti[0]);
            for (let i = 0; i < props.listaProfessionisti.length; i++) {
                if (props.listaProfessionisti[i].idUtente === selected) {
                    setNomeCognomeProfessionista(props.listaProfessionisti[i].nome + " " + props.listaProfessionisti[i].cognome);
                    break;
                }
            }
        }
    }, [props.showmodal]);
    return (
        <div>
            <Modal show={props.showmodal} size='xl'>
                <Modal.Header closeButton onClick={props.closemodal}>
                    <Modal.Title><h3 className="card-title">Disponibilità del professionista {nomeCognomeProfessionista}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column" style={{height: "calc(100vh - 138px)"}}>
                        <Legenda colors={props.modificaSeduta == true ? legendaColorsModificaSeduta : legendaColors} />
                        <VediDisponibilitaProfessionista initialDate={props.giornoSeduta} selectedProfessionisti={props.selectedProfessionisti} listaDisponibilita={props.listaDisponibilita} setListaDisponibilita={props.setListaDisponibilita} selectedPeriodEvent={props.selectedPeriodEvent} selectedSedutaId={props.selectedSedutaId} />
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}
export default ModalDisponibilita;