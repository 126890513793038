import React from 'react'
import { Modal, Button } from 'react-bootstrap';
/*
Dialog di messaggio generico
*/
function GenericAlertModal(props) {
    return (
        <div>
            <Modal show={props.showmodal}>
                <Modal.Header closeButton onClick={props.closemodal}>
                </Modal.Header>
                <Modal.Body>
                    <p>{props.message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.closemodal}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default GenericAlertModal;