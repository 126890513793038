function ProgressBar(props) {
    //se props.percentualeCompletamento == null, non si vede nulla (operazione non ancora iniziata)
    //se props.percentualeCompletamento == undefined, errore
    //se props.percentualeCompletamento > 0, mostra la progress bar
    return <>
        {(props.percentualeCompletamento != null && props.percentualeCompletamento != undefined) &&
            <>
                <div style={{ textAlign: "center" }}>
                    <p className="mt-1 mb-1" ><b>{props.percentualeCompletamento.toFixed(2).replace('.', ',')}%</b></p>
                </div>
                <div className="bg-light" style={{ width: "100%", height: "1rem", borderRadius: "0.25rem" }}>
                    <div className="bg-primary" style={{ width: props.percentualeCompletamento + "%", height: "1rem", borderRadius: "0.25rem" }}></div>
                </div>
            </>
        }
        {(props.percentualeCompletamento == undefined && props.percentualeCompletamento != null) &&
            <p className="text-danger">Impossibile verificare lo stato di completamento dell'operazione</p>
        }
    </>;
}

export default ProgressBar;