import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { ContextGestionale } from '../../App';
import { useContext } from 'react';
import { useState, useEffect } from 'react';
import APICallButton from "../APICallButton";

/*
Dialog che ti chiede se vuoi davvero eliminare un utente
*/
function SessioneScadutaModal(props) {
    //props: showmodal, closemodal, errore, sedutaSegnata, setSedutaSegnata, segnaSeduta e sedutaText
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    const [loginError, setLoginError] = useState(null);
    const [password, setPassword] = useState("");
    const [isShowPassword, setShowPassword] = useState(false);
    useEffect(() => {
        if (props.showmodal) {
            if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
                console.log("useEffect: modal sessione scaduta aperto");
              }
            setPassword("");
            console.log(loggedUser);
        }
    }, [props.showmodal]);

    function checkPassword() {
        let loginEmail = loggedUser.email;
        let loginPassword = password;
        let reqbody = {
            email: loginEmail,
            password: loginPassword
        };
        setLoading(true);nodeReq.post(process.env.REACT_APP_API_URL + '/login', reqbody)
            .then(response => {
                let data = response.data;
                if (response.status === 200) {
                    setLoggedUser(data.loggedUser);
                    if (props.sessionContinueFunction[0] !== undefined) {
                        props.sessionContinueFunction[0]();
                    }
                    props.closemodal();
                } else {
                    setLoginError("Impossibile effettuare il login. Riprova più tardi.");
                }
            })
            .catch(error => {
                if (error.response === undefined) {
                    setLoginError("Impossibile effettuare il login. Riprova più tardi. Errore: " + error);
                } else {
                    let data = error.response.data;
                    if (data === "userOrPasswordIncorrect") {
                        setLoginError("Nome utente o password non corretti.");
                        setPassword("");
                    } else if (data === "unableToCheckPassword") {
                        setLoginError("Impossibile verificare la password. Riprova più tardi.");
                    } else {
                        setLoginError("Impossibile effettuare il login. Riprova più tardi. Errore: " + error);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
              });
    }
    return (
        <div>
            <Modal show={props.showmodal}>
                <Modal.Header>
                    <Modal.Title><h3 className="card-title">Sessione scaduta</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>La sessione è scaduta. Inserisci nuovamente la password per rimanere nel gestionale.</h5>
                    <label htmlFor="emailSessioneScaduta">Indirizzo email</label>
                    <input className="form-control" id="emailSessioneScaduta" readOnly={true} value={loggedUser.email} type="email" />
                    <label htmlFor="passwordSessioneScaduta">Password</label>
                    <input className="form-control" id="passwordSessioneScaduta" type={isShowPassword ? "text" : "password"} value={password} onChange={(event) => { setPassword(event.target.value); }} />
                    <label htmlFor="showPasswordSessioneScaduta" className="card-subtitle text-muted">Mostra password</label>
                    <input id="showPasswordSessioneScaduta" type="checkbox" className="form-check-input m-1" checked={isShowPassword} onChange={(event) => { setShowPassword(event.target.checked); }} />
                    {loginError !== null && <p className="text-danger">{loginError}</p>}
                </Modal.Body>
                <Modal.Footer>
                    <APICallButton buttonOnClick={checkPassword} buttonText="Continua" buttonClassName="btn btn-primary m-1" nomeProcedura={"il login"}/>
                    <APICallButton buttonOnClick={() => { props.handleLogout(true, false); }} buttonClassName="btn btn-danger m-1" buttonText="Esci" nomeProcedura={"il logout"}/>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
export default SessioneScadutaModal;