import React from "react";
import { useState, useEffect } from "react";
import SearchAndSelect from "../SearchAndSelect";
import ModalSovrapposizioni from "../modals/ModalSovrapposizioni";
import ModalDisponibilita from "../modals/ModalDisponibilita";
import { ContextGestionale } from "../../App";
import { useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Heading from "../Heading";
import ModalPrompt from "../modals/ModalPrompt";
import DettagliSeduta from "../DettagliSeduta";
import Flatpickr from "react-flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";

function SedutaCreateUpdate(props) {
    //Metti questo boolean a true solo per il test
    const allowPastDates = false;
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso } = useContext(ContextGestionale);
    //useState per i vari modal
    const [isShowSovrapposizioni, setShowSovrapposizioni] = useState(false);
    const [isShowConfermaSeduta, setShowConfermaSeduta] = useState(false);
    const [isShowDeleteSeduta, setShowDeleteSeduta] = useState(false);
    const [isShowModalDisponibilita, setShowModalDisponibilita] = useState(false);

    //tutte le liste prese dal db. In ogni elemento di queste liste c'è l'attributo viewed, che dice se è disponibile nella select oppure no
    const [listaAssegnazioni, setListaAssegnazioni] = useState([]);
    const [listaSpecializzazioni, setListaSpecializzazioni] = useState([]);
    const [listaAree, setListaAree] = useState([]);
    const [listaCertificazioni, setListaCertificazioni] = useState([]);
    const [idServiziUltimeSedute, setIdServiziUltimeSedute] = useState([]);
    const [listaDisponibilitaProfessionista, setListaDisponibilitaProfessionista] = useState([]);

    //lista disponibilità e lista sedute. Non vengono riempite qui ma dentro al ModalDisponibilita
    const [listaDisponibilita, setListaDisponibilita] = useState([]);
    const [idAreaConStellina, setIdAreaConStellina] = useState(null);

    //campi selezionabili dall'utente
    //qui le funzioni di aggiornamento del form delle sedute
    //8, 9, 11
    //vengono eseguite le funzioni setSelectedPazienti, setSelectedCertificazioni, setSelectedServizi
    function setSelectedProfessionisti(newarray) { setSeduteFormValues(prevState => ({ ...prevState, selectedProfessionisti: newarray })); console.log("seduteFormValues settato (7)"); }
    function setSelectedPazienti(newarray) { setSeduteFormValues(prevState => ({ ...prevState, selectedPazienti: newarray })); console.log("seduteFormValues settato (8)"); }
    function setSelectedCertificazioni(newarray) { setSeduteFormValues(prevState => ({ ...prevState, selectedCertificazioni: newarray })); console.log("seduteFormValues settato (9)"); }
    function setSelectedAree(newarray) { setSeduteFormValues(prevState => ({ ...prevState, selectedAree: newarray })); console.log("seduteFormValues settato (10)"); }
    function setSelectedServizi(newarray) { setSeduteFormValues(prevState => ({ ...prevState, selectedServizi: newarray })); console.log("seduteFormValues settato (11)"); }
    function setModalitaSeduta(newval) { setSeduteFormValues(prevState => ({ ...prevState, modalita: newval })); console.log("seduteFormValues settato (12)"); }
    function setGiornoSeduta(newval) { setSeduteFormValues(prevState => ({ ...prevState, data: newval })); console.log("seduteFormValues settato (13)"); }
    function setOraInizio(newval) { setSeduteFormValues(prevState => ({ ...prevState, oraInizio: newval })); console.log("seduteFormValues settato (14)"); }
    function setOraFine(newval) { setSeduteFormValues(prevState => ({ ...prevState, oraFine: newval })); console.log("seduteFormValues settato (15)"); }
    //Fine dei campi selezionabili dall'utente

    //altre variabili mostrate a schermo
    const [durataOre, setDurataOre] = useState(1);
    const [durataMinuti, setDurataMinuti] = useState(0);
    const [confermaSedutaText, setConfermaSedutaText] = useState({});
    const [selectedPeriodEvent, setSelectedPeriodEvent] = useState(undefined);
    const [certificazioneRichiesta, setCertificazioneRichiesta] = useState(false);
    const [servizioConLaStella, setServizioConLaStella] = useState(null);

    //variabili usate per caricare la seduta
    const [pazienteLoaded, setPazienteLoaded] = useState(false);
    const [servizioLoaded, setServizioLoaded] = useState(false);
    const [certificazioneLoaded, setCertificazioneLoaded] = useState(false);
    const [professionistaSelectionHandled, setProfessionistaSelectionHandled] = useState(false);

    //tutti i boolean di controllo, per capire se la seduta puo essere fissata oppure no
    const [professionistaValidSelection, setProfessionistaValidSelection] = useState(false);
    const [pazienteValidSelection, setPazienteValidSelection] = useState(false);
    const [certificazioneValidSelection, setCertificazioneValidSelection] = useState(false);
    const [areaValidSelection, setAreaValidSelection] = useState(false);
    const [servizioValidSelection, setServizioValidSelection] = useState(false);
    const [professionistaDisponibile, setProfessionistaDisponibile] = useState(undefined); //0 = occupato con sedute, 1 = forse non disponibile, 2 = disponibile
    const [sovrapposizioni, setSovrapposizioni] = useState([]);
    const [orariValidi, setOrariValidi] = useState(true);
    const [dataValida, setDataValida] = useState(false);
    const formIsValid = professionistaValidSelection && pazienteValidSelection && certificazioneValidSelection && areaValidSelection && servizioValidSelection && orariValidi && dataValida && sovrapposizioni != undefined && sovrapposizioni.length == 0 && (professionistaDisponibile == 1 || professionistaDisponibile == 2);

    //funzioni di handle dei cambiamenti sui campi del form

    const handleOraInizioChange = (event) => {
        setOraInizio(event.target.value);

        let ora = parseInt(event.target.value.split(":")[0]);
        ora++;
        if (ora <= 23) {
            setOraFine(ora.toString().padStart(2, '0') + ":" + event.target.value.split(":")[1]);
        } else {
            setOraFine("23:59");
        }
    }
    const handleOraFineChange = (event) => {
        setOraFine(event.target.value);
    }
    const changeGiornoSeduta = (event) => {
        if (event.target != undefined && event.target.value != undefined) {
            //funzione chiamata dall'input date
            setGiornoSeduta(event.target.value);
        } else {
            //funzione chiamata da flatpickr
            setGiornoSeduta(dataFormatoAmericano(event[0]));
        }
    }
    const handleRadioChange = (event) => { setModalitaSeduta(parseInt(event.target.value)); };

    //Quando vengono impostati l'ora di inizio e l'ora di fine della seduta, controlla se sono validi e calcola anche la durata della seduta
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: ora inizio e ora fine sono validi?");
        }
        //calcolare la durata della seduta conoscendo oraInizio e oraFine
        //se la durata della seduta è minore o uguale a zero, la seduta non puo essere fissata
        let durataOre = parseInt(seduteFormValues.oraFine.split(":")[0]) - parseInt(seduteFormValues.oraInizio.split(":")[0]);
        let durataMinuti = parseInt(seduteFormValues.oraFine.split(":")[1]) - parseInt(seduteFormValues.oraInizio.split(":")[1]);
        if (durataMinuti < 0) {
            durataOre -= 1;
            durataMinuti += 60;
        }
        if (isNaN(durataOre) || isNaN(durataMinuti) || durataOre < 0 || (durataOre == 0 && durataMinuti == 0)) {
            setOrariValidi(false);
            setSelectedPeriodEvent({});
        } else {
            setOrariValidi(true);
            //imposta l'evento del periodo selezionato, da mostrare nel ModalDisponibilita
            if (seduteFormValues.data != undefined) {
                setSelectedPeriodEvent({
                    id: "selectedPeriod",
                    start: seduteFormValues.data + "T" + seduteFormValues.oraInizio,
                    end: seduteFormValues.data + "T" + seduteFormValues.oraFine,
                    title: "Periodo selezionato",
                    backgroundColor: process.env.REACT_APP_PERIODO_SELEZIONATO_BGCOLOR
                });
            }
        }
        setDurataOre(durataOre);
        setDurataMinuti(durataMinuti);
    }, [seduteFormValues.oraInizio, seduteFormValues.oraFine]);

    //carica le disponibilita del professionista selezionato, serviranno per mettere la stellina nell'area giusta
    useEffect(() => {
        if ((pageViewed === pages.NuovaSeduta || pageViewed === pages.Seduta) && seduteFormValues.selectedProfessionisti != undefined && seduteFormValues.selectedProfessionisti.length == 1) {
            setLoading(true);
            nodeReq.get(process.env.REACT_APP_API_URL + "/disponibilitaprofessionista?idProfessionista=" + seduteFormValues.selectedProfessionisti[0])
                .then(response => {
                    if (response.status == 200) {
                        setListaDisponibilitaProfessionista(response.data.dbdata);
                    } else {
                        genericAlert("Impossibile ottenere le disponibilità dei professionisti al momento a causa di un errore. Riprova più tardi");
                    }
                })
                .catch(error => {
                    handleAPIError(error, "ottenere le disponibilità dei professionisti");
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setIdAreaConStellina(null);
        }
    }, [seduteFormValues.selectedProfessionisti, pageViewed]);

    useEffect(() => {
        if (dataIsValida(seduteFormValues.data)) {
            let giorno = new Date(seduteFormValues.data).getDay() + 1; //converti da giorno di js in giorno di mysql
            let found = false;
            for (let i = 0; i < listaDisponibilitaProfessionista.length; i++) {
                if (listaDisponibilitaProfessionista[i].giorno == giorno && listaDisponibilitaProfessionista[i].idArea != null) {
                    found = true;
                    setIdAreaConStellina(listaDisponibilitaProfessionista[i].idArea);
                    break;
                }
            }
            if (!found) {
                setIdAreaConStellina(null);
            }
        }
    }, [listaDisponibilitaProfessionista, seduteFormValues.data]);

    //Quando viene selezionata una data, controlla se è valida. Se la modalità è "nuova seduta", allora non puoi mettere una data passata
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: la data è valida? (sedutaCreateUpdate)");
        }
        let valida = dataIsValida(seduteFormValues.data);
        if (valida) {
            setSelectedPeriodEvent({
                id: "selectedPeriod",
                start: seduteFormValues.data + "T" + seduteFormValues.oraInizio,
                end: seduteFormValues.data + "T" + seduteFormValues.oraFine,
                title: "Periodo selezionato",
                backgroundColor: process.env.REACT_APP_PERIODO_SELEZIONATO_BGCOLOR
            });
        }
        setDataValida(valida);
    }, [seduteFormValues.data]);

    function dataIsValida(data) {
        if (data == undefined || data == "") {
            return false;
        }
        let dataSelezionata = new Date(data);
        let giornoSettimana = dataSelezionata.getDay();
        let annoDataSelezionata = dataSelezionata.getUTCFullYear();
        let meseDataSelezionata = dataSelezionata.getUTCMonth() + 1;
        meseDataSelezionata = meseDataSelezionata < 10 ? "0" + meseDataSelezionata : meseDataSelezionata;
        let giornoDataSelezionata = dataSelezionata.getUTCDate();
        giornoDataSelezionata = giornoDataSelezionata < 10 ? "0" + giornoDataSelezionata : giornoDataSelezionata;
        dataSelezionata = annoDataSelezionata + "-" + meseDataSelezionata + "-" + giornoDataSelezionata;
        return (dataSelezionata >= props.getFirstModifiableDay() || allowPastDates) && giornoSettimana != 0; //0 è la domenica
    }

    //Ogni volta che apri questa pagina, carica prima tutti i dati aggiornati dal db
    useEffect(() => {
        if (pageViewed === pages.NuovaSeduta || pageViewed === pages.Seduta) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: pagina crea/modifica seduta aperta. Carico i dati del db aggiornati");
            }
            setPazienteLoaded(false);
            setServizioLoaded(false);
            setCertificazioneLoaded(false);
            //carica la lista dei professionisti
            if (loggedUser.tipoUtente == "segretario") {
                //se sei segretario, vedrai tutti i professionisti
                setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/professionistapaz/tutti?ordineAlfabetico=true&tipoUtente=professionista&soloAttivi=true")
                    .then(response => {
                        let professionisti = response.data.dbdata;
                        for (let i = 0; i < professionisti.length; i++) {
                            professionisti[i].viewed = true;
                            professionisti[i].id = professionisti[i].idUtente;
                        }
                        setListaProfessionisti(professionisti);
                        //setSelectedProfessionisti(seduteFormValues.professionista == undefined ? [] : [seduteFormValues.professionista]);
                    })
                    .catch(error => { handleAPIError(error, "caricare i professionisti"); })
                    .finally(() => {
                        setLoading(false);
                    });
            } else if (loggedUser.tipoUtente == "professionista") {
                //se sei professionistateraeputa, vedrai solo te stesso
                setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/professionista?idProfessionista=" + loggedUser.user)
                    .then(response => {
                        let professionisti = response.data.dbdata;
                        for (let i = 0; i < professionisti.length; i++) {
                            professionisti[i].viewed = true;
                            professionisti[i].id = professionisti[i].idUtente;
                        }
                        setListaProfessionisti(professionisti);
                        setSelectedProfessionisti([loggedUser.user]);
                    })
                    .catch(error => { handleAPIError(error, "caricare i professionisti"); })
                    .finally(() => {
                        setLoading(false);
                    });
            }
            //carica la lista delle assegnazioni
            setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/assegnazioni")
                .then(response => {
                    setListaAssegnazioni(response.data.dbdata);
                })
                .catch(error => { handleAPIError(error, "visualizzare le assegnazioni"); })
                .finally(() => {
                    setLoading(false);
                });
            //carica la lista delle specializzazioni
            setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/specializzazioni")
                .then(response => {
                    setListaSpecializzazioni(response.data.dbdata);
                })
                .catch(error => { handleAPIError(error, "visualizzare le specializzazioni dei professionisti"); })
                .finally(() => {
                    setLoading(false);
                });
            //carica la lista delle specializzazioni
            setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/serviziUltimeSedute")
                .then(response => {
                    setIdServiziUltimeSedute(response.data.dbdata);
                })
                .catch(error => { handleAPIError(error, "ottenere i servizi delle ultime sedute"); })
                .finally(() => {
                    setLoading(false);
                });

            //carica la lista dei pazienti
            setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/professionistapaz/tutti?ordineAlfabetico=true&tipoUtente=paziente&soloAttivi=true")
                .then(response => {
                    let pazienti = response.data.dbdata;
                    for (let i = 0; i < pazienti.length; i++) {
                        pazienti[i].id = pazienti[i].idUtente;
                        pazienti[i].viewed = (listaPazienti[i] != undefined ? listaPazienti[i].viewed : false);
                    }
                    ////console.log("(Prima) Imposto viewed ai pazienti a false!");
                    //console.log("Prima) Carico la lista dei pazienti");
                    setListaPazienti(pazienti);
                })
                .catch(error => { handleAPIError(error, "visualizzare i pazienti"); })
                .finally(() => {
                    setLoading(false);
                });
            //carica la lista dei servizi
            setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/servizi?ordineAlfabetico=true")
                .then(response => {
                    let servizi = response.data.dbdata;
                    for (let i = 0; i < servizi.length; i++) {
                        servizi[i].id = servizi[i].idServizio;
                        servizi[i].viewed = (listaServizi[i] != undefined ? listaServizi[i].viewed : false);
                    }
                    setListaServizi(servizi);

                })
                .catch(error => { handleAPIError(error, "visualizzare i servizi"); })
                .finally(() => {
                    setLoading(false);
                });
            //carica la lista delle aree
            setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/aree")
                .then(response => {
                    let aree = response.data.dbdata;
                    for (let i = 0; i < aree.length; i++) {
                        aree[i].viewed = (seduteFormValues.modalita != 2);
                        aree[i].id = aree[i].idArea;
                    }
                    setListaAree(aree);
                    //setSelectedAree(seduteFormValues.area == undefined ? [] : [seduteFormValues.area]);
                })
                .catch(error => { handleAPIError(error, "visualizzare le aree"); })
                .finally(() => {
                    setLoading(false);
                });
            //carica la lista delle certificazioni
            setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/certificazioni?ordineAlfabetico=true&perPaginaSedute=true")
                .then(response => {
                    let certificazioni = response.data.dbdata;
                    for (let i = 0; i < certificazioni.length; i++) {
                        //certificazioni[i].viewed = false;
                        certificazioni[i].id = certificazioni[i].idCertificazione;
                        certificazioni[i].viewed = (listaCertificazioni[i] != undefined ? listaCertificazioni[i].viewed : false);
                    }
                    setListaCertificazioni(certificazioni);
                })
                .catch(error => { handleAPIError(error, "visualizzare le certificazioni"); })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [pageViewed]);

    //Controlla se il professionista è disponibile nel periodo selezionato. Fai la chiamata API per capirlo
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: chiamataAPI per capire se il professionista è disponibile");
        }
        controllaDisponibilitaProfessionista();
    }, [seduteFormValues.selectedProfessionisti, seduteFormValues.data, seduteFormValues.oraInizio, seduteFormValues.oraFine]);

    function controllaDisponibilitaProfessionista() {
        checkUserLoggedBeforeDoing(() => {
            setProfessionistaDisponibile(undefined);
            //esegui solo mentre il modal è chiuso o quando si chiude il modal
            let idSedutaToUpdateString = "";
            if (seduteFormValues.idSedutaToUpdate != undefined) {
                idSedutaToUpdateString = "&idSeduta=" + seduteFormValues.idSedutaToUpdate;
            }
            if (seduteFormValues.selectedProfessionisti != undefined && seduteFormValues.selectedProfessionisti.length == 1 && seduteFormValues.data != undefined && seduteFormValues.oraInizio != undefined && seduteFormValues.oraFine != undefined) {
                setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/professionistadisponibile?idProfessionista=" + seduteFormValues.selectedProfessionisti[0] + "&giornoSeduta=" + seduteFormValues.data + "&oraInizio=" + seduteFormValues.oraInizio + "&oraFine=" + seduteFormValues.oraFine + idSedutaToUpdateString)
                    .then(response => {
                        if (response.status == 200) {
                            //response.data = { disponibile: check1, occupatoConSedute: check2 }
                            if (response.data.disponibile == true && response.data.occupatoConSedute == true) {
                                setProfessionistaDisponibile(0);
                            } else if (response.data.disponibile == true && response.data.occupatoConSedute == false) {
                                setProfessionistaDisponibile(2);
                            } else if (response.data.disponibile == false && response.data.occupatoConSedute == false) {
                                setProfessionistaDisponibile(1);
                            } else {
                                setProfessionistaDisponibile(0);
                            }
                        }
                    })
                    .catch(error => {
                        setProfessionistaDisponibile(undefined);
                        handleAPIError(error, "conoscere lo stato di disponibilità del professionista");
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        });
    }

    function dataFormatoAmericano(data) {
        return data.getFullYear() + "-" + (data.getMonth() + 1).toString().padStart(2, '0') + "-" + data.getDate().toString().padStart(2, '0');
    }

    //Quando cambia l'area, la modalita seduta o la data e l'ora, controlla le sovrapposizioni delle prenotazioni
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: controllo sovrapposizioni con altre prenotazioni");
        }
        controllaSovrapposizioniPrenotazioni();
    }, [seduteFormValues.data, seduteFormValues.oraInizio, seduteFormValues.oraFine, seduteFormValues.selectedAree, seduteFormValues.modalita]);

    function controllaSovrapposizioniPrenotazioni() {
        //esegui solo mentre il modal è chiuso o dopo che la seduta è prenotata, così se si prenota ancora un altra seduta, non è in sovrapposizione
        checkUserLoggedBeforeDoing(() => {
            if (seduteFormValues.modalita == 2) {
                //seduta online. Non c'è nessuna sovrapposizione
                setSovrapposizioni([]);
            } else if (seduteFormValues.selectedAree.length == 1 && seduteFormValues.data != undefined) {
                //seduta in presenza. Trova le sovrapposizioni
                let idPrenotazioneToUpdateString = "";
                if (seduteFormValues.idPrenotazioneToUpdate != undefined) {
                    idPrenotazioneToUpdateString = "&idPrenotazioneToUpdate=" + seduteFormValues.idPrenotazioneToUpdate;
                }
                setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/sovrapposizioni?idArea=" + seduteFormValues.selectedAree[0] + "&giornoInizio=" + seduteFormValues.data + "&giornoFine=" + seduteFormValues.data + "&oraInizio=" + seduteFormValues.oraInizio + "&oraFine=" + seduteFormValues.oraFine + idPrenotazioneToUpdateString)
                    .then(response => {
                        if (response.status == 200) {
                            setSovrapposizioni(response.data.dbdata);
                        } else {
                            setSovrapposizioni(undefined);
                        }
                    })
                    .catch(error => {
                        setSovrapposizioni(undefined);
                        handleAPIError(error, "visualizzare le sovrapposizioni con altre prenotazioni");
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                setSovrapposizioni(null);
            }
        });
    }
    //Se viene selezionata la modalità online per le sedute, non mostrare nessuna area, altrimenti mostrale tutte
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: mostro o nascondo tutte le aree");
        }
        let listaAreeTemp = listaAree.slice();
        listaAreeTemp.forEach(cert => cert.viewed = (seduteFormValues.modalita != 2));
        setListaAree(listaAreeTemp);
        if (seduteFormValues.modalita == 2) {
            setSelectedAree([]);
        }
    }, [seduteFormValues.modalita]);

    //Quando cambia il professionista selezionato, mostra solo i pazienti e i servizi assegnati a lui, e nascondi tutti gli altri
    useEffect(() => {
        if (!professionistaSelectionHandled) {
            //console.log("Vado a gestire la selezione dello professionista!");
        }
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: scelgo quali pazizenti e servizi mostrare");
        }
        if (seduteFormValues.selectedProfessionisti.length == 1) {
            if (!professionistaSelectionHandled && listaPazienti.length > 0 && listaAssegnazioni.length > 0 && listaSpecializzazioni.length > 0 && listaServizi.length > 0) {
                setProfessionistaSelectionHandled(true);
                //Mostro solo i pazienti e i servizi assegnati allo professionista, e nascondo tutti gli altri
                //prendi gli id di tutti i pazienti dello professionista
                let idPazientiDiProfessionista = [];
                listaAssegnazioni.forEach(ass => { if (ass.idProfessionista == seduteFormValues.selectedProfessionisti[0]) { idPazientiDiProfessionista.push(ass.idPaziente); } });
                //prendi gli id di tutti i servizi dello professionista
                let idServiziDiProfessionista = [];
                listaSpecializzazioni.forEach(spec => { if (spec.idProfessionista == seduteFormValues.selectedProfessionisti[0]) { idServiziDiProfessionista.push(spec.idServizio); } });
                let listaPazientiTemp = listaPazienti.slice();
                //console.log("La lista dei pazienti contiene " + listaPazienti.length + " pazienti. Mentre i pazienti dello professionista sono " + idPazientiDiProfessionista.length);
                for (let i = 0; i < listaPazientiTemp.length; i++) {
                    if (idPazientiDiProfessionista.includes(listaPazientiTemp[i].id)) {
                        listaPazientiTemp[i].viewed = true;
                    } else {
                        listaPazientiTemp[i].viewed = false;
                    }
                }
                //console.log("Dopo) Hai scelto un professionista. Mostro e nascondo i pazienti e i servizi");
                setListaPazienti(listaPazientiTemp);
                if (!pazienteLoaded) {
                    //console.log("3) Paziente loaded è false! GIUSTO. Seleziono il paziente");
                    //setSelectedPazienti(seduteFormValues.paziente == undefined ? [] : [seduteFormValues.paziente]);
                    setPazienteLoaded(true);
                } else {
                    //console.log("3) Paziente loaded è true. Non seleziono nessun paziente");
                    //setSelectedPazienti([]);
                }
                let listaServiziTemp = listaServizi.slice();
                for (let i = 0; i < listaServiziTemp.length; i++) {
                    if (idServiziDiProfessionista.includes(listaServiziTemp[i].id)) {
                        listaServiziTemp[i].viewed = true;
                    } else {
                        listaServiziTemp[i].viewed = false;
                    }
                }
                setListaServizi(listaServiziTemp);
                if (!servizioLoaded) {
                    //console.log("4) Servizio loaded false! GIUSTO. Seleziono il servizio");
                    //setSelectedServizi(seduteFormValues.servizio == undefined ? [] : [seduteFormValues.servizio]);
                    setServizioLoaded(true);
                } else {
                    //console.log("4) Servizio loaded true. Non seleziono nessun servizio");
                    //setSelectedServizi([]);
                }
            }
        } else {
            if (!professionistaSelectionHandled) {
                //console.log("2) Hai scelto nessun professionista. nascondo i pazienti e i servizi");
                setProfessionistaSelectionHandled(true);
                //Nascondi tutti i pazienti e i servizi
                let listaPazientiTemp = listaPazienti.slice();
                listaPazientiTemp.forEach(paziente => paziente.viewed = false);
                setListaPazienti(listaPazientiTemp);
                setSelectedPazienti([]);
                console.log("qui è stato eseguito l'8");
                let listaServiziTemp = listaServizi.slice();
                listaServiziTemp.forEach(servizio => servizio.viewed = false);
                setListaServizi(listaServiziTemp);
                setSelectedServizi([]);
                console.log("Qui è stato eseguito l'11");
                //Questo blocco di codice causa il problema
            }
        }
    }, [professionistaSelectionHandled, listaPazienti, listaAssegnazioni, listaSpecializzazioni, listaServizi]);

    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: professionista selezionato. selezione ora da gestire");
        }
        setProfessionistaSelectionHandled(false);
        console.log("ho rilevato la selezione del professionista");
    }, [seduteFormValues.selectedProfessionisti]);

    //Quando cambia il servizio o il paziente selezionato o il professionista selezionato, mostra solo le certificazioni relative a quel paziente e quel servizio e nascondi tutte le altre
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: decido quali certificazioni mostrare");
        }
        if (seduteFormValues.selectedServizi != undefined && seduteFormValues.selectedPazienti.length == 1 && seduteFormValues.selectedServizi.length == 1 && seduteFormValues.selectedProfessionisti.length == 1) {
            //Mostra le certificazioni di quel paziente per quel servizio e professionista selezionato
            let listaCertificazioniTemp = listaCertificazioni.slice();
            for (let i = 0; i < listaCertificazioniTemp.length; i++) {
                if (listaCertificazioniTemp[i].idServizio == seduteFormValues.selectedServizi[0] && listaCertificazioniTemp[i].idPaziente == seduteFormValues.selectedPazienti[0] && listaCertificazioniTemp[i].idProfessionista == seduteFormValues.selectedProfessionisti[0]) {
                    listaCertificazioniTemp[i].viewed = true;
                } else {
                    listaCertificazioniTemp[i].viewed = false;
                }
            }
            setListaCertificazioni(listaCertificazioniTemp);
            if (!certificazioneLoaded) {
                setCertificazioneLoaded(true);
            } else {
                setSelectedCertificazioni([]);
                console.log("Qui è stato eseguito il 9 (1)");
            }
        } else {
            //Nascondi tutte le certificazioni
            let listaCertificazioniTemp = listaCertificazioni.slice();
            listaCertificazioniTemp.forEach(cert => cert.viewed = false);
            setListaCertificazioni(listaCertificazioniTemp);
            setSelectedCertificazioni([]);
            console.log("Qui è stato eseguito il 9 (2)");
            //Questo blocco di codice causa il problema
        }
    }, [seduteFormValues.selectedPazienti, seduteFormValues.selectedServizi, seduteFormValues.selectedProfessionisti]);

    //quando cambia il servizio selezionato, determina se la certificazione è obbligatoria oppure no
    useEffect(() => {
        if (seduteFormValues.selectedServizi != undefined) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: servizio cambiato. obbligatoria la certificazione?");
            }
            setCertificazioneRichiesta(false);
            for (let i = 0; i < listaServizi.length; i++) {
                //se il servizio selezionato è solo per certificazioni, la certificazione diventa obbligatoria
                if (listaServizi[i] != undefined && listaServizi[i].id != undefined && listaServizi[i].id.toString() == seduteFormValues.selectedServizi[0] && listaServizi[i].tipoServizio == 2) {
                    setCertificazioneRichiesta(true);
                }
            }
        }
    }, [seduteFormValues.selectedServizi]);

    //funzioni di show e close dei Modal
    function viewSovrapposizioni() { setShowSovrapposizioni(true); }
    function closeSovrapposizioni() { setShowSovrapposizioni(false); }
    function showConfermaSeduta() {
        const { nomePaziente, cognomePaziente, nomeProfessionista, cognomeProfessionista } = nomeCognomeProfessionistaPaz();
        const { nomeServizio, descrizioneServizio } = nomeDescServizio();
        setConfermaSedutaText({
            nomeProfessionista: nomeProfessionista,
            cognomeProfessionista: cognomeProfessionista,
            nomePaziente: nomePaziente,
            cognomePaziente: cognomePaziente,
            nomeServizio: nomeServizio,
            descrizioneServizio: descrizioneServizio,
            certificazione: descCertificazione(),
            dove: nomeArea(),
            modalita: seduteFormValues.modalita,
            data: seduteFormValues.data,
            orario: "Dalle " + seduteFormValues.oraInizio + " alle " + seduteFormValues.oraFine
        });
        setShowConfermaSeduta(true);
    }
    function closeConfermaSeduta() {
        setShowConfermaSeduta(false);
    }
    function closeDeleteSeduta() {
        setShowDeleteSeduta(false);
    }
    function showDeleteSeduta() {
        const { nomePaziente, cognomePaziente, nomeProfessionista, cognomeProfessionista } = nomeCognomeProfessionistaPaz();
        const { nomeServizio, descrizioneServizio } = nomeDescServizio();
        setConfermaSedutaText({
            nomeProfessionista: nomeProfessionista,
            cognomeProfessionista: cognomeProfessionista,
            nomePaziente: nomePaziente,
            cognomePaziente: cognomePaziente,
            nomeServizio: nomeServizio,
            descrizioneServizio: descrizioneServizio,
            certificazione: descCertificazione(),
            dove: nomeArea(),
            modalita: seduteFormValues.modalita,
            data: seduteFormValues.data,
            orario: "Dalle " + seduteFormValues.oraInizio + " alle " + seduteFormValues.oraFine
        });
        setShowDeleteSeduta(true);
    }
    function closeModalDisponibilita() {
        setShowModalDisponibilita(false);
    }
    function showModalDisponibilita() {
        setShowModalDisponibilita(true);
    }

    //come mostrare i professionisti e i pazienti nelle select
    function getProfessionistaPazText(element) {
        return element.cognome + " " + element.nome;
    }

    //come mostrare i servizi nella select
    function getServizioText(element) {
        return element.nomeServizio + (element.descrizioneServizio.length > 0 ? " (" + element.descrizioneServizio + ") " : "") + (element.tipoServizio == 2 ? " (solo per certificazioni)" : "");
    }

    //come mostrare le certificazioni nella select
    function getCertificazioneText(element) {
        return element.nomeServizio + (element.descrizioneServizio.length > 0 ? " (" + element.descrizioneServizio + ")" : "");
    }

    //come mostrare le aree nella select
    function getAreaText(element) {
        return element.nome;
    }

    //Restituisci i nomi e i cognomi del paziente e dello professionista selezionati
    function nomeCognomeProfessionistaPaz() {
        let nomePaziente = "";
        let cognomePaziente = "";
        let nomeProfessionista = "";
        let cognomeProfessionista = "";
        for (let i = 0; i < listaPazienti.length; i++) {
            if (listaPazienti[i].id == seduteFormValues.selectedPazienti[0]) {
                nomePaziente = listaPazienti[i].nome;
                cognomePaziente = listaPazienti[i].cognome;
                break;
            }
        }
        for (let i = 0; i < listaProfessionisti.length; i++) {
            if (listaProfessionisti[i].id == seduteFormValues.selectedProfessionisti[0]) {
                nomeProfessionista = listaProfessionisti[i].nome;
                cognomeProfessionista = listaProfessionisti[i].cognome;
                break;
            }
        }
        return { nomePaziente, cognomePaziente, nomeProfessionista, cognomeProfessionista };
    }

    //Restituisci il nome del servizio selezionato
    function nomeDescServizio() {
        for (let i = 0; i < listaServizi.length; i++) {
            if (seduteFormValues.selectedServizi != undefined && listaServizi[i].id == seduteFormValues.selectedServizi[0]) {
                let nomeServizio = listaServizi[i].nomeServizio;
                let descrizioneServizio = listaServizi[i].descrizioneServizio;
                return { nomeServizio, descrizioneServizio };
            }
        }
    }

    //Restituisci la descrizione della certificazione selezionata
    function descCertificazione() {
        if (seduteFormValues.selectedCertificazioni.length == 1) {
            for (let i = 0; i < listaCertificazioni.length; i++) {
                if (listaCertificazioni[i].id == seduteFormValues.selectedCertificazioni[0]) {
                    return listaCertificazioni[i].descrizione;
                }
            }
        } else {
            return null;
        }
    }

    //Restituisci il nome dell'area selezionata
    function nomeArea() {
        if (seduteFormValues.modalita == 2) {
            return "online";
        } else {
            for (let i = 0; i < listaAree.length; i++) {
                if (listaAree[i].id == seduteFormValues.selectedAree[0]) {
                    return listaAree[i].nome;
                }
            }
        }
    }

    function getSedutaTimestamp(){
        return seduteFormValues.data + " " + seduteFormValues.oraInizio;
    }

    //Restituisci il prezzo della seduta in base al servizio e al professionista selezionati
    //Se il servizio è a tariffa oraria, devi calcolare il prezzo giusto
    function getPrezzoSeduta() {
        //TODO: qui bisogna fare un po di lavori!
        if (!certificazioneRichiesta) {
            //se stai creando una seduta, oppure la stai modificando e vuoi aggiornare anche il prezzo, devi impostare il prezzo
            let tipoServizioSelezionato = 0;
            for (let i = 0; i < listaServizi.length; i++) {
                if (listaServizi[i].idServizio == seduteFormValues.selectedServizi[0]) {
                    tipoServizioSelezionato = listaServizi[i].tipoServizio;
                }
            }
            let index = -1;
            let dataOraValidita = "2024-01-01 00:00";
            //trova l'index giusto in listaSpecializzazioni. deve essere la specializzazione del professionista in questo servizio e deve avere la data di validità giusta
            for (let i = 0; i < listaSpecializzazioni.length; i++) {
                if (listaSpecializzazioni[i].idProfessionista == seduteFormValues.selectedProfessionisti[0] && listaSpecializzazioni[i].idServizio == seduteFormValues.selectedServizi[0]) {
                    //salva dataOraValidita nella variabile e index se dataOraValidita è antecedente al current_timestamp o al timestamp della seduta (e non successiva) ed è successiva a quella nella variabile
                    if(dataOraValidita.localeCompare(listaSpecializzazioni[i].dataOraValidita) == -1 && listaSpecializzazioni[i].dataOraValidita.localeCompare(getSedutaTimestamp()) == -1){
                        dataOraValidita = listaSpecializzazioni[i].dataOraValidita;
                        index = i;
                    }
                }
            }
            //ora sai di che tipo è il servizio
            let tariffa = listaSpecializzazioni[index].tariffa;
            let costoProfessionista = listaSpecializzazioni[index].costoProfessionista;
            if (tipoServizioSelezionato == 0) {
                //calcola il prezzo per la tariffa oraria
                return [(tariffa * durataOre) + (tariffa * (durataMinuti / 60.0)), (costoProfessionista * durataOre) + (costoProfessionista * (durataMinuti / 60.0))];
            } else {
                //calcola il prezzo per la tariffa a seduta oppure inserisci il prezzo della certificazione (che poi non viene contato)
                return [tariffa, costoProfessionista];
            }
        } else {
            return [undefined, undefined];
        }
    }

    //Fai la chiamata API per creare la seduta
    function saveSeduta() {
        const [prezzo, costoProfessionista] = getPrezzoSeduta();
        const { nomePaziente, cognomePaziente, nomeProfessionista, cognomeProfessionista } = nomeCognomeProfessionistaPaz();
        const { nomeServizio, descrizioneServizio } = nomeDescServizio();
        if (formIsValid) {
            let reqBody = {
                idPrenotazioneToUpdate: seduteFormValues.idPrenotazioneToUpdate,
                idSedutaToUpdate: seduteFormValues.idSedutaToUpdate,
                idProfessionista: parseInt(seduteFormValues.selectedProfessionisti[0]),
                nomePaziente: nomePaziente,
                cognomePaziente: cognomePaziente,
                nomeProfessionista: nomeProfessionista,
                cognomeProfessionista: cognomeProfessionista,
                idPaziente: parseInt(seduteFormValues.selectedPazienti[0]),
                idServizio: parseInt(seduteFormValues.selectedServizi[0]),
                nomeServizio: nomeServizio,
                descrizioneServizio: descrizioneServizio,
                idCertificazione: parseInt(seduteFormValues.selectedCertificazioni[0]),
                nomeCertificazione: descCertificazione(),
                idArea: parseInt(seduteFormValues.selectedAree[0]),
                nomeArea: nomeArea(),
                modalitaSeduta: seduteFormValues.modalita,
                prezzo: prezzo,
                costoProfessionista: costoProfessionista,
                giornoSeduta: seduteFormValues.data,
                oraInizio: seduteFormValues.oraInizio,
                oraFine: seduteFormValues.oraFine,
                certificazioneRichiesta: certificazioneRichiesta
            };
            setLoading(true); nodeReq.post(process.env.REACT_APP_API_URL + "/seduta", reqBody)
                .then(response => {
                    if (response.status == 201) {
                        if (response.data[0] != true) {
                            genericAlert("La seduta è stata correttamente " + (pageViewed === pages.NuovaSeduta ? "prenotata" : "modificata") + ", ma si è verificato un errore nell'invio dell'email di notifica.");
                        } else {
                            genericAlert("Seduta " + (pageViewed === pages.NuovaSeduta ? "prenotata" : "modificata") + " correttamente.");
                        }
                        setSeduteFormValuesBeforeEdits(JSON.parse(JSON.stringify(seduteFormValues)));
                        toast.success("Hai appena prenotato una seduta di " + cognomeProfessionista + " con " + cognomePaziente + " " + nomePaziente[0] + ". alle " + seduteFormValues.oraInizio);
                        controllaDisponibilitaProfessionista();
                        controllaSovrapposizioniPrenotazioni();
                    } else {
                        genericAlert("Impossibile prenotare la seduta a causa di un errore. Riprova più tardi.");
                    }
                })
                .catch(error => {
                    if (error.response != undefined && error.response.status == 400) {
                        if (seduteFormValues.idSedutaToUpdate != undefined) {
                            //aggiornamento seduta
                            if (error.response.data == "sedutaNotFoundOrCertificazioneConclusaOrSedutaGiaFatturata") {
                                genericAlert("La seduta è stata eliminata, è stata già fatturata oppure non è più modificabile perchè la certificazione è stata già conclusa");
                            } else {
                                genericAlert("La seduta non è più modificabile. Sono modificabili solo le sedute prenotate a partire dal " + dataFormatoItaliano(props.getFirstModifiableDay()));
                            }
                        } else {
                            genericAlert("Impossibile prenotare una seduta fuori calendario.");
                        }
                    } else {
                        handleAPIError(error, "prenotare la seduta");
                    }
                })
                .finally(() => {
                    setLoading(false);
                    setShowConfermaSeduta(false);
                });
        }
    }

    //vai alla pagina delle sedute
    function goToSedute() {
        checkUnsavedBeforeDoing(() => { checkUserLoggedBeforeDoing(() => { setPageViewed(pages.Dashboard); cambiaUrl(pages.Dashboard); }); });
    }

    function getServizioConLaStella() {
        if (seduteFormValues.selectedProfessionisti.length == 1 && seduteFormValues.selectedPazienti.length == 1) {
            console.log("determino il servizio con la stella");
            console.log("idServiziUltimeSedute.length = " + idServiziUltimeSedute.length);
            let found = false;
            for (let i = 0; i < idServiziUltimeSedute.length; i++) {
                console.log(idServiziUltimeSedute[i].idProfessionista + " == " + seduteFormValues.selectedProfessionisti[0] + " && " + idServiziUltimeSedute[i].idPaziente + " == " + seduteFormValues.selectedPazienti[0]);
                if (idServiziUltimeSedute[i].idProfessionista == seduteFormValues.selectedProfessionisti[0] && idServiziUltimeSedute[i].idPaziente == seduteFormValues.selectedPazienti[0]) {
                    setServizioConLaStella(idServiziUltimeSedute[i].idServizio);
                    found = true;
                }
            }
            if (!found) {
                setServizioConLaStella(null);
            }
        } else {
            setServizioConLaStella(null);
        }
    }
    useEffect(getServizioConLaStella, [seduteFormValues.selectedProfessionisti, seduteFormValues.selectedPazienti]);

    //fai la chiamata API che elimina la seduta e controlla la risposta del backend
    function deleteSeduta(idSeduta) {
        //cancella la seduta con id props.idSeduta
        setLoading(true); nodeReq.delete(process.env.REACT_APP_API_URL + "/seduta?idSeduta=" + idSeduta)
            .then(response => {
                if (response.status == 200) {
                    if (response.data[0] == true) {
                        genericAlert("La seduta è stata eliminata correttamente.");
                    } else {
                        genericAlert("La seduta è stata eliminata ma non è stato possibile inviare l'email di notifica.");
                    }
                    goToSedute();
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response != undefined && error.response.status == 400) {
                    genericAlert("Impossibile eliminare questa seduta. Potrebbe essere stata eliminata, già fatturata oppure associata a una certificazione già conclusa.");
                } else {
                    handleAPIError(error, "eliminare la seduta");
                }
            })
            .finally(() => {
                setLoading(false);
                setShowDeleteSeduta(false);
            });
    }

    return (
        <div style={{ display: pageViewed === pages.NuovaSeduta || pageViewed === pages.Seduta ? 'block' : 'none' }}>
            {/*Heading */}
            {seduteFormValues.openedFrom == "dashboard" && <Heading title="Dashboard" iconName="calendar" />}
            {seduteFormValues.openedFrom == "tabellaSedute" && <Heading title="Tabella sedute" iconName="clipboard" />}
            {pageViewed == pages.NuovaSeduta && <Heading title="Prenota seduta" iconName="clipboard_plus" />}
            {pageViewed == pages.Seduta && <h1 className="h3 mb-3"><b>Modifica seduta prenotata</b></h1>}


            {/*Avviso modifica sedute */}
            {pageViewed == pages.Seduta && <h5>Le sedute prenotate a partire dal {dataFormatoItaliano(props.getFirstModifiableDay())} sono ancora modificabili</h5>}
            {/*Pulsante per tornare alla lista */}
            {pageViewed == pages.Seduta &&
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <button className="btn btn-primary btn-lg me-sm-2 m-1" onClick={() => { checkUnsavedBeforeDoing(() => { checkUserLoggedBeforeDoing(() => { setPageViewed(seduteFormValues.openedFrom == "dashboard" ? pages.Dashboard : pages.Sedute); if (seduteFormValues.openedFrom != "dashboard") { console.log("Apro pagina modifica sedute (2)"); } }); }); }}>Indietro</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                {/*Select professionista*/}
                <div className="col-md" style={{ display: (loggedUser.tipoUtente == "professionista" ? "none" : "block") }}>
                    <div className="card">
                        <div className="card-body">
                            <SearchAndSelect title="Professionista" selfEmptyOnElementsUpdate={false} oneElementName="un professionista" elements={listaProfessionisti} allowMultiple={false} showDeselectButton={false} required={true} setValidSelection={setProfessionistaValidSelection} selectedOptions={seduteFormValues.selectedProfessionisti} setSelectedOptions={setSelectedProfessionisti} getElementText={getProfessionistaPazText} onManualChange={(id) => { setSelectedPazienti([]); setSelectedServizi([]); }} />
                        </div>
                    </div>
                </div>
                {/*Select paziente*/}
                <div className="col-md">
                    <div className="card">
                        <div className="card-body">
                            <SearchAndSelect title="Paziente" noViewedElementsText="Seleziona un professionista assegnato ad almeno un paziente" selfEmptyOnElementsUpdate={false} oneElementName="un paziente" elements={listaPazienti} allowMultiple={false} showDeselectButton={false} required={true} setValidSelection={setPazienteValidSelection} selectedOptions={seduteFormValues.selectedPazienti} setSelectedOptions={setSelectedPazienti} getElementText={getProfessionistaPazText} notSelectableFunction={(paziente) => {return paziente.stato == "Bloccato" || paziente.stato == "Insolvente"}}/>
                        </div>
                    </div>
                </div>
                {/*Select servizio*/}
                <div className="col-md">
                    <div className="card">
                        <div className="card-body">
                            <SearchAndSelect title="Servizio" noViewedElementsText="Seleziona un professionista specializzato in almeno un servizio" selfEmptyOnElementsUpdate={false} oneElementName="un servizio" elements={listaServizi} allowMultiple={false} showDeselectButton={false} required={true} setValidSelection={setServizioValidSelection} selectedOptions={seduteFormValues.selectedServizi} setSelectedOptions={setSelectedServizi} getElementText={getServizioText} starElement={servizioConLaStella} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {/*Select area*/}
                <div className="col-md">
                    <div className="card">
                        <div className="card-body">
                            <SearchAndSelect title="Area" selfEmptyOnElementsUpdate={false} oneElementName="un'area" elements={listaAree} allowMultiple={false} showDeselectButton={false} required={seduteFormValues.modalita != 2} setValidSelection={setAreaValidSelection} selectedOptions={seduteFormValues.selectedAree} setSelectedOptions={setSelectedAree} getElementText={getAreaText} starElement={idAreaConStellina} />
                        </div>
                    </div>
                </div>
                {/*Select certificazione*/}
                <div className="col-md">
                    <div className="card">
                        <div className="card-body">
                            <SearchAndSelect title="Certificazione" selfEmptyOnElementsUpdate={false} oneElementName="una certificazione" elements={listaCertificazioni} allowMultiple={false} showDeselectButton={true} required={certificazioneRichiesta} setValidSelection={setCertificazioneValidSelection} selectedOptions={seduteFormValues.selectedCertificazioni} setSelectedOptions={setSelectedCertificazioni} getElementText={getCertificazioneText} noViewedElementsText={"Nessuna certificazione trovata per questo servizio, paziente e professionista"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {/*Selezione di ora e giorno*/}
                <div className="col-md">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Quando</h5>
                        </div>
                        <div className="card-body">
                            {!dataValida && <p className="text-danger">Inserire una data tra lunedì e sabato {!allowPastDates && "non precedente al " + dataFormatoItaliano(props.getFirstModifiableDay())}</p>}
                            {(pageViewed === pages.NuovaSeduta || pageViewed === pages.Seduta) &&
                                <Flatpickr options={{ mode: "single", inline: true, locale: Italian, defaultDate: seduteFormValues.data }} onChange={changeGiornoSeduta} />
                            }
                            <div className="d-flex flex-row my-2 flex-wrap">
                                <div className="flex-grow-1">
                                    <label htmlFor="oraInizioSeduta">Ora inizio</label>
                                    <input type="time" id="oraInizioSeduta" className="form-control" value={seduteFormValues.oraInizio} onChange={handleOraInizioChange}></input>
                                </div>
                                <div className="flex-grow-1">
                                    <label>Ora fine</label>
                                    <input type="time" id="oraFineSeduta" className="form-control" value={seduteFormValues.oraFine} onChange={handleOraFineChange}></input>
                                </div>
                            </div>
                            {orariValidi && <p className="mb-0">Durata della seduta: {durataOre}:{durataMinuti.toString().padStart(2, '0')}</p>}
                            {!orariValidi && <p className="text-danger">Orari non validi!</p>}
                        </div>
                    </div>
                </div>
                {/*Modalità seduta con i 3 radio button*/}
                <div className="col-md">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Modalità seduta</h5>
                        </div>
                        <div className="card-body">
                            <label className="form-check">
                                <input className="form-check-input m-1" type="radio" value="0"
                                    name="radios-example" checked={seduteFormValues.modalita === 0} onChange={handleRadioChange} />
                                <span className="form-check-label">
                                    In presenza
                                </span>
                            </label>
                            <label className="form-check">
                                <input className="form-check-input m-1" type="radio" value="1"
                                    name="radios-example" checked={seduteFormValues.modalita === 1} onChange={handleRadioChange} />
                                <span className="form-check-label">
                                    Ibrida (solo il paziente online)
                                </span>
                            </label>
                            <label className="form-check">
                                <input className="form-check-input m-1" type="radio" value="2"
                                    name="radios-example" checked={seduteFormValues.modalita === 2} onChange={handleRadioChange} />
                                <span className="form-check-label">
                                    Online
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                {/*Sezione sovrapposizioni*/}
                <div className="col-md">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Sovrapposizioni con altre prenotazioni</h5>
                        </div>
                        <div className="card-body">
                            <ModalSovrapposizioni sovrapposizioni={sovrapposizioni} showmodal={isShowSovrapposizioni} closemodal={closeSovrapposizioni} />
                            {(sovrapposizioni != undefined && sovrapposizioni != null && sovrapposizioni.length > 0) &&
                                <>
                                    <p className="text-danger">Sono state individuate delle sovrapposizioni</p>
                                    <button className="btn btn-secondary m-1" onClick={viewSovrapposizioni}>Vedi sovrapposizioni</button>
                                </>
                            }
                            {(sovrapposizioni != undefined && sovrapposizioni != null && sovrapposizioni.length == 0) && <p className="text-success">Non è stata individuata nessuna sovrapposizione.</p>}
                            {(sovrapposizioni == undefined && sovrapposizioni != null) && <p className="text-danger">Impossibile controllare le sovrapposizioni nelle prenotazioni.</p>}
                            {sovrapposizioni == null && <p className="text-danger">Seleziona la data, l'ora e un'area per controllare le sovrapposizioni.</p>}
                        </div>
                    </div>
                </div>
                {/*Sezione disponibilità professionista*/}
                <div className="col-md">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Disponiblità Professionista</h5>
                        </div>
                        <div className="card-body">
                            {(seduteFormValues.selectedProfessionisti.length != 1) && <p className="text-danger">Seleziona un professionista per consultare le sue disponibilità</p>}
                            {(dataValida && orariValidi && seduteFormValues.selectedProfessionisti.length == 1 && professionistaDisponibile == 2) && <p className="text-success">{loggedUser.tipoUtente == "professionista" ? "Sei disponibile nel periodo selezionato" : "Il professionista è disponibile nel periodo selezionato"}</p>}
                            {(dataValida && orariValidi && seduteFormValues.selectedProfessionisti.length == 1 && professionistaDisponibile == 1) && <p className="text-warning">{loggedUser.tipoUtente == "professionista" ? "Potresti non essere disponibile nel periodo selezionato" : "Il professionista potrebbe non essere disponibile nel periodo selezionato"}</p>}
                            {(dataValida && orariValidi && seduteFormValues.selectedProfessionisti.length == 1 && professionistaDisponibile == 0) && <p className="text-danger">{loggedUser.tipoUtente == "professionista" ? "Non sei disponibile nel periodo selezionato" : "Il professionista non è disponibile nel periodo selezionato"}</p>}
                            {(dataValida && orariValidi && seduteFormValues.selectedProfessionisti.length == 1 && professionistaDisponibile == undefined) && <p className="text-danger">{loggedUser.tipoUtente == "professionista" ? "Impossibile verificare la tua disponibilità" : "Impossibile verificare la disponibilità del professionista"}</p>}
                            {(seduteFormValues.selectedProfessionisti.length == 1) && professionistaDisponibile != undefined && <button className="btn btn-secondary m-1" onClick={showModalDisponibilita}>Consulta le disponibilità</button>}
                        </div>
                    </div>
                </div>
            </div>
            {/*Pulsante prenota*/}
            <button className="btn btn-primary m-1" onClick={showConfermaSeduta} disabled={!formIsValid}>{pageViewed == pages.NuovaSeduta ? "Prenota seduta" : "Salva modifiche"}</button>
            {seduteFormValues.idSedutaToUpdate != undefined && <button className="btn btn-danger m-1" onClick={showDeleteSeduta}>Annulla seduta</button>}
            {/*Vari modal */}
            <ModalPrompt showmodal={isShowConfermaSeduta} closemodal={closeConfermaSeduta} okButtonFunction={saveSeduta} okButtonColor={"primary"} okButtonText={pageViewed === pages.NuovaSeduta ? "Prenota" : "Salva modifiche"} title={"Conferma prenotazione della seduta"} nomeProcedura={pageViewed === pages.NuovaSeduta ? "la prenotazione della nuova seduta" : "la modifica della seduta"}>
                <p>{pageViewed === pages.NuovaSeduta ? "Vuoi prenotare questa seduta?" : "Vuoi modificare la seduta prenotata come segue?"}</p>
                <DettagliSeduta sedutaText={confermaSedutaText} />
                {professionistaDisponibile == 1 && <div className="bg-warning p-2 mt-2" style={{ borderRadius: "0.25rem" }}><p>{loggedUser.tipoUtente == "professionista" ? "Potresti non essere disponibile nel periodo selezionato" : "Questo professionista potrebbe non essere disponibile nel periodo selezionato"}</p></div>}
            </ModalPrompt>
            <ModalDisponibilita showmodal={isShowModalDisponibilita} closemodal={closeModalDisponibilita} giornoSeduta={seduteFormValues.data} selectedProfessionisti={seduteFormValues.selectedProfessionisti} listaDisponibilita={listaDisponibilita} setListaDisponibilita={setListaDisponibilita} selectedPeriodEvent={selectedPeriodEvent} selectedSedutaId={seduteFormValues.idSedutaToUpdate} listaProfessionisti={listaProfessionisti} modificaSeduta={pageViewed === pages.Seduta} />
            <ModalPrompt showmodal={isShowDeleteSeduta} closemodal={closeDeleteSeduta} title={"Conferma eliminazione della seduta"} okButtonColor={"danger"} okButtonText={"Elimina seduta"} okButtonFunction={() => { deleteSeduta(seduteFormValues.idSedutaToUpdate); }} nomeProcedura={"l'eliminazione della seduta"}>
                <p>Vuoi davvero annullare questa seduta?</p>
                <DettagliSeduta sedutaText={confermaSedutaText} />
            </ModalPrompt>
            {/* Il toast*/}
            <ToastContainer />
        </div>
    );
}

export default SedutaCreateUpdate;