import React from "react";
import { useContext } from "react";
import { ContextGestionale } from "../../App";
import { useEffect, useState } from "react";
import BackendDataTable from "../BackendDataTable";
import Heading from "../Heading";

function Certificazioni(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso  } = useContext(ContextGestionale);
    const certificazioniReqUrl = process.env.REACT_APP_API_URL + "/certificazioni?perPaginaCertificazioni=true";
    const columnsToExcludeCertificazioni = ["idServizio", "idPaziente", "descrizione", "idProfessionista"];
    const columnsToExcludeSedute = ["idSeduta", "idPaziente", "idServizio", "idProfessionista", "idCertificazione", "idPrenotazione", "idArea", "motivo", "prezzo", "costoProfessionista", "certificazione","nomeProfessionista", "cognomeProfessionista", "nomePaziente", "cognomePaziente"];
    const functionsOnColumnsCertificazioni = [];
    functionsOnColumnsCertificazioni["dataAcquisto"] = (val) => {
        return val.substring(8, 10) + "/" + val.substring(5, 7) + "/" + val.substring(0, 4);
    };
    functionsOnColumnsCertificazioni["dataConclusione"] = (val) => {
        if (val != null) {
            return val.substring(8, 10) + "/" + val.substring(5, 7) + "/" + val.substring(0, 4);
        } else {
            return "Non ancora conclusa";
        }
    };
    functionsOnColumnsCertificazioni["prezzo"] = (val) => {
        if (val != null && val != undefined) {
            return importoConPuntoEVirgola(val);
        } else {
            return "---";
        }
    };
    functionsOnColumnsCertificazioni["costoProfessionista"] = (val) => {
        if (val != null && val != undefined) {
            return importoConPuntoEVirgola(val);
        } else {
            return "---";
        }
    };
    const functionsOnColumnsSedute = [];
    const certificazioniColumnsDataTypes = {
        "dataAcquisto": "date",
        "dataConclusione": "date",
        "prezzo" : "price",
        "costoProfessionista": "price"
    };
    const seduteColumnsDataTypes = {
        "data": "date"
    }
    const [selectedCertificazioneId, setSelectedCertificazioneId] = useState(null);
    const [seduteCertificazioneReqUrl, setSeduteCertificazioneReqUrl] = useState("");
    functionsOnColumnsSedute["modalita"] = (val) => {
        if (val == 0) {
            return "In presenza";
        } else if (val == 1) {
            return "Ibrida";
        } else if (val == 2) {
            return "Online";
        }
    };
    functionsOnColumnsSedute["effettuata"] = (val) => {
        if (val == null) {
            return "Non segnata";
        } else if (val == 1) {
            return "Sì";
        } else if (val == 0) {
            return "No";
        }
    };
    functionsOnColumnsSedute["data"] = (val) => {
        return val.substring(8, 10) + "/" + val.substring(5, 7) + "/" + val.substring(0, 4);
    };

    useEffect(() => {
        if (pageViewed === pages.Certificazioni) {
            if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
                console.log("useEffect: lista certificazioni aperta");
              }
            setSelectedCertificazioneId(null);
        }
    }, [pageViewed]);

    useEffect(() => {
        if (selectedCertificazioneId != null) {
            if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
                console.log("useEffect: mostro le sedute della cert selezionata");
              }
            setSeduteCertificazioneReqUrl(process.env.REACT_APP_API_URL + "/sedute?unisciCognomeNome=true&idCertificazione=" + selectedCertificazioneId);
        }
    }, [selectedCertificazioneId]);

    function handleCertificazioneDoubleClick(certificazione) {
        checkUserLoggedBeforeDoing(() => {
            let certificazioneToUpdateFormValues = {
                idCertificazioneToUpdate: certificazione.idCertificazione,
                selectedServizio: [certificazione.idServizio],
                selectedPaziente: [certificazione.idPaziente],
                selectedProfessionista: [certificazione.idProfessionista],
                professionista: certificazione.professionista,
                paziente: certificazione.paziente,
                servizio: certificazione.nomeServizio,
                nota: certificazione.descrizione,
                prezzo: parseFloat(certificazione.prezzo.substring(2)),
                costoProfessionista: parseFloat(certificazione.costoProfessionista.substring(2)),
                dataAcquisto: dataFormatoItaliano(convertiDataAcquisto(certificazione.dataAcquisto)),
                dataConclusione: dataFormatoItaliano(convertiDataConclusione(certificazione.dataConclusione))
            }
            props.setCertificazioneFormValues(certificazioneToUpdateFormValues);
            props.setCertificazioneFormValuesBeforeEdits(JSON.parse(JSON.stringify(certificazioneToUpdateFormValues)));
            setPageViewed(pages.Certificazione);
        });
    }

    function convertiDataAcquisto(data) {
        return data.substring(6, 10) + "-" + data.substring(3, 5) + "-" + data.substring(0, 2);
    }

    function convertiDataConclusione(data) {
        if (data != "Non ancora conclusa") {
            return data.substring(6, 10) + "-" + data.substring(3, 5) + "-" + data.substring(0, 2);
        } else {
            return "";
        }
    }

    function handleCertificazioneSingleClick(certificazione) {
        setSelectedCertificazioneId(certificazione.idCertificazione);
    }

    useEffect(() => {
        if(pageViewed === pages.Certificazioni && (props.abilitaCertificazioni == false)){
            genericAlert("Non sei stato abilitato per gestire le certificazioni");
            setPageViewed(pages.Dashboard);
            cambiaUrl(pages.Dashboard);
        }
    }, [pageViewed, props.abilitaCertificazioni]);
    return (
        <div style={{ display: pageViewed === pages.Certificazioni ? 'block' : 'none' }}>
            <Heading title="Lista certificazioni" iconName="clipboard"/>
            {/* Data table di tutte le certificazioni*/}
            <div className="row mainElementHeightContainer">
            <div className="col-md mh-100">
                <div className="backendDataTableContainer">
                        <BackendDataTable fileName={"Certificazioni"} colorizeSelectedRow={true} rowSingleClickFunction={handleCertificazioneSingleClick} rowClickFunction={handleCertificazioneDoubleClick} loadInsidePage={pages.Certificazioni} tableId={"listaCertificazioni"} reqUrl={certificazioniReqUrl} show={true} columnsToExclude={columnsToExcludeCertificazioni} functionsOnColumns={functionsOnColumnsCertificazioni} columnsDataTypes={certificazioniColumnsDataTypes} />
                    </div>
                </div>
            </div>
            <div className="d-block m-4"></div>
            {/* Data table delle sedute della certificazione selezionata*/}
            <div className="row mainElementHeightContainer">
                <div className="col-md mh-100">
                    <div className="backendDataTableContainer">
                        <BackendDataTable fileName={"Sedute della certificazione"} rowClickFunction={() => { }} loadInsidePage={pages.Certificazioni} tableId={"seduteCertificazione"} reqUrl={seduteCertificazioneReqUrl} show={selectedCertificazioneId != null} columnsToExclude={columnsToExcludeSedute} functionsOnColumns={functionsOnColumnsSedute} columnsDataTypes={seduteColumnsDataTypes} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Certificazioni;